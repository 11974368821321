import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { ReactSVG } from "react-svg";
import "./testimonials.scss";
import "./testiVideoDetails.scss";
import ArrowUp from "../../assets/images/rounded-arrowup.svg";
import GreenArrowLeft from "../../assets/images/green-arrow-left.svg";
import GreenArrowRight from "../../assets/images/green-arrow-right.svg";
import {
  testimonialsPage,
  testimonialsVideoPage,
} from "../../services/whyDrees/testimonials/testimonialsServices";
import testimonialimage3 from "../../assets/images/testimonial-image3.png";
import testimonialimage2 from "../../assets/images/testimonial-image2.png";
import IconPlayer from "../../assets/images/icon-play.svg";
import { Offcanvas } from "../components/Offcanvas";
import {
  assetTrim,
  capitalizeEachWord,
  damName,
  scale,
  youtube_videoId,
} from "../../constant";
import _ from "lodash";

export const Testimonials = ({
  changeCarousel,
  subContent,
  setTransitionTime,
}) => {
  const [index, setIndex] = useState(0);
  const [filteredKioskVideosWithLink, setFilteredKioskVideosWithLink] =
    useState([]);
  const [filteredKioskVideosWithoutLink, setFilteredKioskVideosWithoutLink] =
    useState([]);
  const [testimonialsCorporateVideos, setTestimonialsCorporateVideos] =
    useState([]);
  const [uniqKioskVideoList, setUniqKioskVideoList] = useState([]);
  const [testimonialsKioskVideoList, setTestimonialsKioskVideoList] = useState([]);
  const [uniqCorporateVideoList, setUniqCorporateVideoList] = useState([]);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    watchOurHomeownerStoriesBtn: false,
  });

  const {
    corporate,
    testimonialsListKiosk,
    testimonialsVideos,
    whyDreesPageDataKiosk,
    children,
  } = useSelector((state) => {
    return {
      loading: state.testimonialsReducer.loading,
      error: state.testimonialsReducer.error,
      testimonialsPageDataKiosk:
        state.testimonialsReducer.testimonialsPageData.testimonialsPageKiosk,
      testimonialsListKiosk:
        state.testimonialsReducer.testimonialsPageData.testimonialsListKiosk,
      corporate: state.homeReducer.site,
      testimonialsVideos:
        state.testimonialsReducer?.testimonialsVideoPage?.testimonialsVideos,
      whyDreesPageDataKiosk:
        state.whyDreesReducer.whyDreesPageData.whyDreesPageKiosk,
      children: state.whyDreesReducer.whyDreesPageData.whyDreesChildren,
    };
  });

  useEffect(() => {
    const uniqueTestiCorporateVideo = _.uniqBy(
      testimonialsVideos,
      function (item) {
        return item?.videoLink?.value;
      }
    );
    setUniqCorporateVideoList(uniqueTestiCorporateVideo);
    const uniqueTestiKioskVideo = _.uniqBy(
      filteredKioskVideosWithLink,
      function (item) {
        return item?.video?.value;
      }
    );
    setUniqKioskVideoList(uniqueTestiKioskVideo);
  }, [testimonialsVideos, testimonialsListKiosk]);

  const [videoId, setVideoId] = useState(
    filteredKioskVideosWithLink?.[0]?.contentLink?.guidValue ||
      testimonialsVideos?.[0]?.contentLink?.guidValue
  );
  useEffect(() => {
    if (children) {
      dispatch(testimonialsPage({ children, corporate }));
      dispatch(testimonialsVideoPage(corporate));
    }
  }, [children, corporate]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const filteredListKioskWithLink = testimonialsListKiosk?.filter(
      (item) => item?.video?.value
    );
    setFilteredKioskVideosWithLink(filteredListKioskWithLink);
    const filteredListKioskWithoutLink = testimonialsListKiosk?.filter(
      (item) => item?.video?.value === null && item
    );
    setFilteredKioskVideosWithoutLink(filteredListKioskWithoutLink);
    const filteredListCorporate = uniqCorporateVideoList?.filter(
      (item) => item?.videoLink?.value
    );
    setTestimonialsCorporateVideos(filteredListCorporate);
  }, [testimonialsListKiosk, uniqCorporateVideoList]);

  useEffect(() => {
    if (
      uniqKioskVideoList?.length > 0 ||
      filteredKioskVideosWithoutLink?.length > 0
    ) {
		setTestimonialsKioskVideoList([
        ...uniqKioskVideoList,
        ...filteredKioskVideosWithoutLink,
      ]);
    }
  }, [uniqKioskVideoList, filteredKioskVideosWithoutLink]);

  const prevIcon = () => {
    return (
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#demo"
        data-bs-slide="prev"
      >
        <img src={GreenArrowLeft} alt="Left Icon" />
      </button>
    );
  };

  const nextIcon = () => {
    return (
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#demo"
        data-bs-slide="next"
      >
        <img src={GreenArrowRight} alt="Right Icon" />
      </button>
    );
  };

  return (
    <>
      <div
        className="dk-MainContentWrapper "
        style={{
          backgroundImage: `url(${corporate?.testimonials_background_image?.value}?scale=xxl)`,
        }}
      >
        <div className="dk-MainContentWrapper cc-main-container RightMenu testi-main-container">
          <div className="cc-wrapper drees-testimonial-wrapper">
            <p className="cc-why-drees-text text-uppercase">
              {corporate?.testimonials_title?.value}
            </p>
            <h1 className="cc-why-drees-title text-uppercase pb-20">
              {corporate?.testimonials_heading?.value}
            </h1>
            {testimonialsKioskVideoList?.length > 1 && (
              <Carousel
                fade
                slide={false}
                activeIndex={index}
                onSelect={handleSelect}
                interval={null}
                indicators={false}
                prevIcon={prevIcon()}
                nextIcon={nextIcon()}
              >
                {testimonialsKioskVideoList?.map((item) => {
                  return (
                    <Carousel.Item key={item?.contentLink?.guidValue}>
                      <div className="d-flex align-items-center justify-content-around testimonial-slider">
                        <div>
                          <p className="cc-why-drees-desc pb-40">
                            {item?.message.value}
                          </p>
                          <p className="cc-why-drees-desc">
                            - {item?.createdBy.value}
                          </p>
                        </div>
                        <div className="cards">
                          <div
                            className="card one"
                            style={{
                              backgroundImage: "url(" + testimonialimage3 + ")",
                            }}
                          ></div>
                          <div
                            className="card two"
                            style={{
                              backgroundImage: "url(" + testimonialimage2 + ")",
                            }}
                          ></div>
                          <div
                            className="card three"
                            style={{
                              backgroundImage: `url(${assetTrim(
                                damName,
                                item?.image?.value,
                                scale
                              )}?scale=medium)`,
                            }}
                          ></div>
                          {item?.video?.value && (
                            <div className="icon-player">
                              <ReactSVG
                                src={IconPlayer}
                                onClick={() => {
                                  setState({
                                    watchOurHomeownerStoriesBtn: true,
                                  });
                                  setVideoId(item?.contentLink?.guidValue);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-center">
                        {(testimonialsKioskVideoList?.length > 0 ||
                          uniqCorporateVideoList?.length > 0) && (
                          <button
                            type="submit"
                            className="btn primary-button cc-button dk-testi-button"
                            onClick={() => {
                              setState({ watchOurHomeownerStoriesBtn: true });
                              setVideoId(
                                item?.video?.value
                                  ? item?.contentLink?.guidValue
                                  : filteredKioskVideosWithLink.length > 0
                                  ? filteredKioskVideosWithLink?.[0]
                                      ?.contentLink?.guidValue
                                  : testimonialsCorporateVideos?.length > 0 &&
                                    testimonialsCorporateVideos?.[0]
                                      ?.contentLink?.guidValue
                              );
                            }}
                          >
                            {capitalizeEachWord(
                              corporate
                                ?.testimonials_watch_our_homeowner_stories
                                ?.value
                            )}
                          </button>
                        )}
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            )}
            {testimonialsKioskVideoList?.length === 1 && (
              <>
                <div className="d-flex align-items-center justify-content-around testimonial-slider testi-single">
                  <div>
                    <p className="cc-why-drees-desc pb-40">
                      {testimonialsKioskVideoList?.[0]?.message.value}
                    </p>
                    <p className="cc-why-drees-desc">
                      - {testimonialsKioskVideoList?.[0]?.createdBy.value}
                    </p>
                  </div>
                  <div className="cards">
                    <div
                      className="card"
                      style={{
                        backgroundImage: `url(${assetTrim(
                          damName,
                          testimonialsKioskVideoList?.[0]?.image?.value,
                          scale
                        )}?scale=medium)`,
                      }}
                    ></div>
                    {testimonialsKioskVideoList?.[0]?.video?.value && (
                      <div className="icon-player">
                        <ReactSVG
                          src={IconPlayer}
                          onClick={() => {
                            setState({ watchOurHomeownerStoriesBtn: true });
                            setVideoId(
								testimonialsKioskVideoList?.[0]?.video?.value
                                ? testimonialsKioskVideoList?.[0]?.contentLink
                                    ?.guidValue
                                : testimonialsCorporateVideos?.[0]?.contentLink
                                    ?.guidValue
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-center">
                  {(testimonialsKioskVideoList?.length > 0 ||
                    uniqCorporateVideoList?.length > 0) && (
                    <button
                      type="submit"
                      className="btn primary-button cc-button dk-testi-button"
                      onClick={() => {
                        setState({ watchOurHomeownerStoriesBtn: true });
                        setVideoId(
                          filteredKioskVideosWithLink?.[0]?.video?.value
                            ? filteredKioskVideosWithLink?.[0]?.contentLink
                                ?.guidValue
                            : testimonialsCorporateVideos?.[0]?.contentLink
                                ?.guidValue
                        );
                      }}
                    >
                      {capitalizeEachWord(
                        corporate?.testimonials_watch_our_homeowner_stories
                          ?.value
                      )}
                    </button>
                  )}
                </div>
              </>
            )}
            {testimonialsKioskVideoList?.length < 1 && (
              <button
                type="submit"
                className="btn primary-button cc-button dk-testi-button"
                onClick={() => {
                  setState({ watchOurHomeownerStoriesBtn: true });
                  setVideoId(
                    testimonialsCorporateVideos?.[0]?.contentLink?.guidValue
                  );
                }}
              >
                {capitalizeEachWord(
                  corporate?.testimonials_watch_our_homeowner_stories?.value
                )}
              </button>
            )}
            <div className="text-center">
              <Offcanvas
                show={state.watchOurHomeownerStoriesBtn}
                placement="end"
                offcanvasClassName="ccw-watch-video-wrapper w-100"
                onHide={() => setState({ watchOurHomeownerStoriesBtn: false })}
              >
                <div closeButton headerClassName="ccw-close-wrapper"></div>
                <div className="testimonial-offcanvasbody">
                  <section>
                    <div className="testimonial-video-container">
                      <div className="d-flex testi-video-wrapper">
                        {uniqKioskVideoList?.map((item) => {
                          if (
                            (item?.contentLink?.guidValue).includes(videoId) &&
                            item?.video?.value
                          ) {
                            return (
                              <div
                                className="td-video-wrapper"
                                key={item?.contentLink?.guidValue}
                              >
                                <h1 className="td-testimonial-title">
                                  {item?.createdBy?.value}
                                </h1>
                                <iframe
                                  title="testi-video"
                                  className="ccw-testimonial-videoIframe"
                                  src={`https://www.youtube.com/embed/${youtube_videoId(
                                    item?.video?.value
                                  )}?autoplay=1`}
                                  allow="autoplay"
                                ></iframe>
                              </div>
                            );
                          }
                        })}
                        {uniqCorporateVideoList?.map((item) => {
                          if (
                            (item?.contentLink?.guidValue).includes(videoId)
                          ) {
                            return (
                              <div
                                className="td-video-wrapper"
                                key={item?.contentLink?.guidValue}
                              >
                                <h1 className="td-testimonial-title">
                                  {item?.createdBy?.value ||
                                    item?.videoName?.value}
                                </h1>
                                <iframe
                                  className="ccw-testimonial-videoIframe"
                                  src={`https://www.youtube.com/embed/${youtube_videoId(
                                    item?.videoLink?.value
                                  )}?autoplay=1`}
                                  allow="autoplay"
                                ></iframe>
                              </div>
                            );
                          }
                        })}
                        <ul className="d-flex testimonials-list-wrapper flex-wrap">
                          {uniqKioskVideoList?.map((list) => {
                            if (list?.video?.value) {
                              return (
                                <li
                                  className="testimonials-list"
                                  key={list?.contentLink?.guidValue}
                                >
                                  <h2
                                    className="td-testimonial-heading"
                                    title={list?.createdBy?.value}
                                  >
                                    {list?.createdBy?.value}
                                  </h2>
                                  <div className="position-relative img-panel">
                                    <img
                                      className="td-testimonial-vc"
                                      src={`${assetTrim(
                                        damName,
                                        list?.image?.value,
                                        scale
                                      )}?scale=small`}
                                      alt="Testimonial Video Cover"
                                    />
                                    <button
                                      className="btn td-testi-btn primary-button"
                                      onClick={() =>
                                        setVideoId(list?.contentLink?.guidValue)
                                      }
                                    >
                                      Watch Video
                                    </button>
                                  </div>
                                </li>
                              );
                            }
                          })}
                          {uniqCorporateVideoList.map((list) => {
                            return (
                              <li
                                className="testimonials-list"
                                key={list?.contentLink?.guidValue}
                              >
                                <h2
                                  className="td-testimonial-heading"
                                  title={list?.videoName?.value}
                                >
                                  {list?.videoName?.value}
                                </h2>
                                <div className="position-relative img-panel">
                                  <img
                                    className="td-testimonial-vc"
                                    src={`https://img.youtube.com/vi/${youtube_videoId(
                                      list?.videoLink?.value
                                    )}/0.jpg`}
                                    alt="Testimonial Video Cover"
                                  />
                                  <button
                                    className="btn td-testi-btn primary-button"
                                    onClick={() =>
                                      setVideoId(list?.contentLink?.guidValue)
                                    }
                                  >
                                    Watch Video
                                  </button>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </Offcanvas>
            </div>
          </div>
          <div className="cc-slider-wrapper">
            <div
              className="slideArrows"
              onClick={() => {
                {
                  whyDreesPageDataKiosk
                    ?.why_drees_main_page_dreesmart_innovations_active
                    ?.value === true ||
                  whyDreesPageDataKiosk
                    ?.why_drees_main_page_dreesmart_innovations_active
                    ?.value === undefined
                    ? changeCarousel(3)
                    : changeCarousel(2);
                }
                setTransitionTime(true);
              }}
            >
              <img src={ArrowUp} alt="Upward arrow" />
            </div>
            <p className="cc-slider-text">
              {corporate?.testimonials_label_bottom_right?.value}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
