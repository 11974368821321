import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContentLoader } from "@episerver/content-delivery";

const contentLoader = new ContentLoader();

export const testimonialsPage = createAsyncThunk("testimonials/fetchTestimonialsPage", async ({ children }) => {
	const testimonialsPageKiosk = children?.find((item) => {
		return item?.contentType?.includes("TestiMonialPage");
	});

	const testimonialsListKiosk = await Promise.all(
		testimonialsPageKiosk?.testimonials_contentarea?.value?.map(async (testimonial) => {
			const testiMonial = await contentLoader.getContent(testimonial?.contentLink.guidValue, {
				branch: "en",
			});
			return testiMonial;
		})
	);

	return { testimonialsPageKiosk, testimonialsListKiosk };
});

export const testimonialsVideoPage = createAsyncThunk(
	"testimonials/fetchTestimonialsCorporateVideoPage",
	async (corporate) => {
		const testimonialsVideos = await Promise.all(
			corporate?.testimonials_watch_our_homeowner_stories_contentarea?.value?.map(async (video) => {
				const videos = await contentLoader.getContent(video?.contentLink?.guidValue, { branch: "en" });
				return videos;
			})
		);

		return { testimonialsVideos };
	}
);
