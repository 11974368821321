/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from "@reduxjs/toolkit";
import {
	customConstructPage,
	DesignCollectionCustomAndConstructHowWeDoPage,
} from "../../services/whyDrees/customConstruct/customConstructService";

const initialState = {
	loading: false,
	customConstructPageData: {},
	seeHowWeDoIt: [],
	error: "",
};

const customConstructSlice = createSlice({
	name: "customConstruct",
	initialState,
	reducers: {
		setPageStartId: (prevState, action) => {
			const state = prevState;
			state.pageStartId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(customConstructPage.pending, (prevState, action) => {
			const state = prevState;
			state.loading = true;
		});
		builder.addCase(customConstructPage.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.customConstructPageData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(customConstructPage.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.customConstructPageData = {};
			state.error = action.error.message;
		});
		builder.addCase(DesignCollectionCustomAndConstructHowWeDoPage.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.seeHowWeDoIt = { ...action.payload };
			state.error = "";
		});
		builder.addCase(DesignCollectionCustomAndConstructHowWeDoPage.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.seeHowWeDoIt = {};
			state.error = action.error.message;
		});
	},
});

const { actions, reducer: customConstructReducer } = customConstructSlice;

export const {
	// export actions here
	setPageStartId,
} = actions;

export default { customConstructReducer };
