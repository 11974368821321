import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_MDW_API_BASE_URL } = process.env;

export const postLead = createAsyncThunk(
	"post/signUp",
	async ({ formData, resetForm, setStateToggle, handleSignUpSuccess }, { rejectWithValue }) => {
		try {
			const res = await axios.post(`${REACT_APP_MDW_API_BASE_URL}/lead`, formData);
			{setStateToggle && setStateToggle({ signUpBtn: false } || { sendPlanBtn: false } || { sendMyPlansBtn: false })}
			handleSignUpSuccess();
			resetForm();
			return res.data;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);
