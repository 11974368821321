import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export const ThankYouBanner = ({ show, handleClose }) => {
	const { corporate } = useSelector((state) => {
		return {
			corporate: state?.homeReducer?.site,
		};
	});

	return (
		<div>
			<Modal
				show={show}
				onHide={handleClose}
				className="Modal-popover modal-Signup d-flex align-items-center justify-content-center">
				<Modal.Header closeButton className="ccw-close-wrapper"></Modal.Header>
				<Modal.Body>
					<p style={{ fontSize: "45px", textAlign: "center" }}>{corporate?.pop_up_reset_title?.value}</p>
				</Modal.Body>
			</Modal>
		</div>
	);
};
