import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { assetTrim, capitalizeEachWord, damName, scale } from "../../constant";
import { fetchNearByCommunities, fetchHomeSite } from "../../services/home/homeService";
import LocationPin from "../../assets/images/pin.svg";
import _ from "lodash";

function NearByCommunities({ setStateToggle, communities, setExploreBtn }) {
	const dispatch = useDispatch();

	const [location, setLocation] = useState();
	const { corporate, Kiosk, nearByKiosk } = useSelector((state) => {
		return {
			corporate: state.homeReducer.site,
			Kiosk: state.homeReducer.homepageData.kiosk,
			nearByKiosk: state.homeReducer.nearByCommunities?.nearByKiosk,
		};
	});

	// fetching location
	useEffect(() => {
		const list = corporate?.communities?.value?.filter((item) => {
			const data = nearByKiosk?.find((ele) => {
				return ele?.contentLink?.id.toString() === item?.pageReference && item?.active === true;
			});
			return data;
		});
		setLocation(list);
	}, [nearByKiosk]);

	return (
		<div className="dk-submenu-section">
			<h2 className="title">NEARBY COMMUNITIES</h2>
			<div className="dk-submenu-inrwrapper dk-scrollbar">
				{_.uniqBy(communities, function (item) {
					return item?.contentLink?.id;
				})?.map((item, index) => {
					if (!item?.title) return null;
					else
						return (
							<div className="dk-submenu-inner-content" key={item?.contentLink?.id}>
								<Link to="/" state={{ animation: true }}>
									<div
										onClick={() => {
											dispatch(fetchHomeSite(item?.contentLink.id));
											setStateToggle({ visitCommunitiesNearByBtn: false });
											setExploreBtn(false);
										}}>
										<h3>{capitalizeEachWord(item?.title?.value)}</h3>
										<p className="nb-location-name">
											<img className="pin-icon" src={LocationPin} alt="Location icon" />
											<span className="">{location?.[index]?.location}</span>
										</p>
										<br />
										<div className="nearbyimage">
											<img src={`${assetTrim(damName, item?.backgroundImage?.value, scale)}?scale=small`} alt="" />
										</div>
									</div>
								</Link>
							</div>
						);
				})}
			</div>
		</div>
	);
}

export default NearByCommunities;
