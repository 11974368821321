import { createAsyncThunk } from "@reduxjs/toolkit";

export const dreesSmartInnovationsPage = createAsyncThunk(
	"dreesSmartInnovations/fetchDreesSmartInnovationsPage",
	async ({ children }) => {
		const dreesSmartInnovationsPageKiosk = children?.find((item) => {
			return item?.contentType?.includes("DreesmartInnovationsPage");
		});

		return { dreesSmartInnovationsPageKiosk };
	}
); // end
