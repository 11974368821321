export const CurveAnimation = ({ pageName }) => {
    return (
        <div>
            <div className="drs-curvehide">
                <div className="curveanimation">
                    <h1 className="dk-lottie-title optima-regular white-color text-uppercase">{pageName}</h1>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 97 98"
                        preserveAspectRatio="xMidYMid slice"
                        className="curves curves--in">
                        <path
                            className="top"
                            fill="rgba(123,123,123,.85)"
                            d="M100 0v22.24352C94.16851 36.2785 83.15617 43.28285 70.51057 47.81014l-1.07245.3768a101.71082 101.71082 0 0 1-.53899.18416l-1.08322.36025-1.08981.34998-1.09592.34033c-.18314.05595-.3665.11152-.5501.16673l-1.1042.32699-1.10911.31886-1.11356.31134-1.11753.30443-1.12103.29814-1.12404.29245-1.69068.42935-2.26037.55826-2.46077.59364-5.3736 1.28071-2.17504.5261-1.44329.3556-1.4367.36104-1.42908.3678a200.4598 200.4598 0 0 0-1.42045.37585l-1.4108.38523a156.7169 156.7169 0 0 0-.70145.19655l-1.39438.40176-1.38217.41442c-8.02504 2.45694-15.3743 5.62713-21.13382 10.68368C7.75367 72.62296 3.59557 77.67903.38119 83.32026L0 84V0h100Z"
                        />
                        <path
                            className="bottom"
                            fill="rgba(43,43,43,.9)"
                            d="M.41336 89.01466c3.20995-3.84973 7.35672-7.30075 12.26464-10.20458 5.39899-3.2478 12.1949-5.3599 19.6349-6.99834l.79958-.17376 1.5085-.316 1.52369-.30513.76712-.14886 1.54394-.29105 1.55576-.28314 1.56623-.27642 1.57536-.27088 2.37718-.39853 7.06384-1.15589 1.84546-.30572 1.84312-.31107 2.32025-.40316 1.7331-.31206 1.15104-.2135 1.14697-.21842 1.14236-.22378 1.13724-.2296 1.1316-.23588 1.12545-.2426 1.11876-.2498 1.11157-.25744.55293-.13173 1.0998-.26976 1.09131-.27855 1.0823-.28778 1.07277-.29748c.76099-.21556 1.51432-.43886 2.25915-.67063l1.11077-.35411c9.38554-3.06555 17.3208-7.5695 22.05516-15.05141L100 47.20404V100H0V89.51905l.41336-.50439Z"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};
