import React, { useState, useEffect } from "react";
import "./customAndConstruct.scss";
import "../../styles/modal.scss";
import ArrowUp from "../../assets/images/rounded-arrowup.svg";
import ArrowDown from "../../assets/images/rounded-arrowdown.svg";
import { useDispatch, useSelector } from "react-redux";
import {
	customConstructPage,
	DesignCollectionCustomAndConstructHowWeDoPage,
} from "../../services/whyDrees/customConstruct/customConstructService";
import Loader from "../../app/component/loader/Loader";
import { Offcanvas } from "../components/Offcanvas";
import { assetTrim, damName, scale, youtube_videoId } from "../../constant";

export const Customandconstruct = ({ changeCarousel, subContent, setTransitionTime }) => {
	const dispatch = useDispatch();
	const [state, setState] = useState({
		watchVideoBtn: false,
		howWeDoItBtn: false,
	});

	const {
		loading,
		error,
		customConstructPageDataKiosk,
		customConstructList,
		corporate,
		children,
		customConstructChildrenData,
		customConstructListKiosk,
	} = useSelector((state) => {
		return {
			loading: state.customConstructReducer.loading,
			error: state.customConstructReducer.error,
			customConstructPageDataKiosk: state.customConstructReducer.customConstructPageData.customConstructPageKiosk,
			customConstructList: state.customConstructReducer.customConstructPageData.customConstructList,
			corporate: state.homeReducer.site,
			children: state.whyDreesReducer.whyDreesPageData.whyDreesChildren,
			customConstructChildrenData:
				state.customConstructReducer.customConstructPageData.customConstructChildrenData,
			customConstructListKiosk: state.customConstructReducer.seeHowWeDoIt.customConstructListKiosk,
		};
	});
	const [active, setActive] = useState(
		`${
			(customConstructChildrenData?.contentType?.includes("DesignCollectionCustomAndConstructHowWeDoPage") &&
			customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content?.value
				? customConstructList
				: customConstructChildrenData?.contentType?.includes("DesignCollectionCustomAndConstructHowWeDoPage") &&
				  customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content?.value === false
				? customConstructListKiosk
				: customConstructList)?.[0]?.custom_construct_how_we_do_it_block_title?.value
		}`
	);

	useEffect(() => {
		if (children) {
			dispatch(customConstructPage({ corporate, children }));
		}
	}, [children]);

	useEffect(() => {
		if (customConstructChildrenData?.contentType?.includes("DesignCollectionCustomAndConstructHowWeDoPage")) {
			dispatch(DesignCollectionCustomAndConstructHowWeDoPage({ children, customConstructChildrenData }));
		}
	}, [customConstructChildrenData]);

	return (
		<>
			{loading && <Loader />}

			{error}

			<div
				className="dk-MainContentWrapper cc-main-container RightMenu dk-custom-construct blackoverlay"
				style={{
					backgroundImage: `url(${assetTrim(
						damName,
						customConstructPageDataKiosk?.custom_construct_background_image?.value,
						scale
					)}?scale=xxl)`,
				}}>
				<div className="cc-wrapper">
					<p className="cc-why-drees-text text-uppercase"> {corporate?.custom_construct_title?.value}</p>
					<h1 className="cc-why-drees-title text-uppercase pb-40">{corporate?.custom_construct_heading?.value}</h1>
					<p className="cc-why-drees-desc helvetica-reg pb-40">{corporate?.custom_construct_description?.value}</p>
					{corporate?.custom_construct_watch_video?.value && (
						<button
							type="submit"
							className="btn primary-button cc-button mr-25"
							onClick={() => setState({ watchVideoBtn: true })}>
							{corporate?.custom_construct_watch_video_label?.value}
						</button>
					)}

					<Offcanvas
						show={state.watchVideoBtn}
						placement="end"
						offcanvasClassName="ccw-watch-video-wrapper w-100"
						onHide={() => setState({ watchVideoBtn: false })}>
						<div closeButton headerClassName="ccw-close-wrapper"></div>
						<div className="ccw-video-wrapper">
							<h1 className="ccw-heading">Drees Custom &amp; Construct Video</h1>
							<div className="ccw-video-main-wrapper">
								<iframe
									className="ccw-video"
									src={`https://www.youtube.com/embed/${youtube_videoId(
										corporate?.custom_construct_watch_video?.value
									)}?autoplay=1`}
									allow="autoplay"></iframe>
							</div>
						</div>
					</Offcanvas>

					<button
						type="submit"
						className="btn primary-button cc-button"
						onClick={() => {
							setState({ howWeDoItBtn: true });
							setActive(
								`${
									(customConstructChildrenData?.contentType?.includes(
										"DesignCollectionCustomAndConstructHowWeDoPage"
									) &&
									customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content?.value
										? customConstructList
										: customConstructChildrenData?.contentType?.includes(
												"DesignCollectionCustomAndConstructHowWeDoPage"
										  ) &&
										 (customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content
												?.value === false ||  customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content
												?.value === null )
										? customConstructListKiosk
										: customConstructList)?.[0]?.custom_construct_how_we_do_it_block_title?.value
								}`
							);
						}}>
						{corporate?.custom_construct_see_how_we_do_it_label?.value}
					</button>

					<Offcanvas
						show={state.howWeDoItBtn}
						placement="end"
						offcanvasClassName="ccw-howwedoit-container w-100"
						onHide={() => setState({ howWeDoItBtn: false })}>
						<div closeButton headerClassName="ccw-close-wrapper"></div>
							<div className="accordion d-flex flex-row-reverse align-items-center h-100" id="accordionExample">
								<div className="accordion-header ccw-accordion-header">
									{(customConstructChildrenData?.contentType?.includes(
										"DesignCollectionCustomAndConstructHowWeDoPage"
									) &&
									customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content?.value
										? customConstructList
										: customConstructChildrenData?.contentType?.includes(
												"DesignCollectionCustomAndConstructHowWeDoPage"
										  ) &&
										  (customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content
												?.value === false || customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content
												?.value === null )
										? customConstructListKiosk
										: customConstructList
									)?.map((item) => {
										return (
											<div key={item?.contentLink?.guidValue}>
												<div className="accordion-item">
													<h2 className="accordion-header ccw-accordion-header" id="headingOne">
														<button
															className="accordion-button ccw-accordion-title"
															type="button"
															data-bs-toggle="collapse"
															data-bs-target="#collapseOne"
															aria-expanded="true"
															aria-controls="collapseOne"
															onClick={() =>
																setActive(`${item?.custom_construct_how_we_do_it_block_title?.value}`)
															}>
															<span className="cch-steps d-flex align-items-center justify-content-center step-green">
																<img
																	className="icon-for-steps"
																	src={`${assetTrim(
																		damName,
																		item?.custom_construct_how_we_do_it_block_title_icon?.value,
																		scale
																	)}?scale=small`}
																	alt=" icons"
																/>{" "}
															</span>{" "}
															{item?.custom_construct_how_we_do_it_block_title?.value}
														</button>
													</h2>
												</div>
											</div>
										);
									})}
								</div>
								<div className="accordion-body ccw-accordion-body w-100 h-100 p-0">
									{(customConstructChildrenData?.contentType?.includes(
										"DesignCollectionCustomAndConstructHowWeDoPage"
									) &&
									customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content?.value
										? customConstructList
										: customConstructChildrenData?.contentType?.includes(
												"DesignCollectionCustomAndConstructHowWeDoPage"
										  ) &&
										  (customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content
											?.value === false || customConstructChildrenData?.custom_construnct_see_how_we_do_it_apply_corporate_content
											?.value === null )
										? customConstructListKiosk
										: customConstructList
									)?.map((item) => {
										return (
											<div key={item?.contentLink?.guidValue}>
												{active === item?.custom_construct_how_we_do_it_block_title?.value && (
													<div
														id="collapseOne"
														className="accordion-collapse collapse show"
														aria-labelledby="headingOne"
														data-bs-parent="#accordionExample">
														<div
															className="ccw-plan-wrapper ccw-step-detail-wrapper d-flex justify-content-center align-items-end background-properties"
															style={{
																backgroundImage: `url(${assetTrim(
																	damName,
																	item?.custom_construct_how_we_do_it_block_background_image?.value,
																	scale
																)}?scale=xl)`,
																minHeight: "calc(100vh - 105px)",
															}}>
															<div className="ccs-bg-overlay">
																<div className="ccw-step-detail">
																	<h2 className="ccw-step-title text-center white-color">
																		{item?.custom_construct_how_we_do_it_block_title?.value}
																	</h2>
																	<p className="ccw-step-desc white-color">
																		{item?.custom_construct_how_we_do_it_block_description?.value}
																	</p>
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										);
									})}
								</div>
							</div>
					</Offcanvas>
				</div>
				<div className="cc-slider-wrapper">
					<div
						className="slideArrows"
						state={{ enableTransition: false }}
						onClick={() => {
							changeCarousel(0);
							setTransitionTime(true);
						}}>
						<img src={ArrowUp} alt="Upward arrow" />
					</div>
					<p className="cc-slider-text">{corporate?.custom_construct_label_bottom_right?.value}</p>
					<div
						className="slideArrows"
						onClick={() => {
							changeCarousel(2);
							setTransitionTime(true);
						}}>
						<img src={ArrowDown} alt="Downward arrow" />
					</div>
				</div>
			</div>
		</>
	);
};
