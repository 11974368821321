import React, { useEffect, useState } from "react";
import "./planCollections.scss";
import { useSelector } from "react-redux";
import { getStore, removeStoreArray, setStoreArray } from "../../services/localStoreService";
import { DetailedCard } from "../components/DetailedCard";

function PlansCard({ plan, data, getPlanDetails }) {
	const [forceRender, setForceRender] = useState(false);
	const [plansData, setPlansData] = useState([]);

	useEffect(() => {
		const plansDatas = getStore("plans");
		setPlansData(plansDatas);
	}, [forceRender]);

	const { plansDetails } = useSelector((state) => {
		return {
			corporate: state?.homeReducer?.site,
			plansCommunityPage: state?.plansReducer.plansPageData.plansCommunityPage,
			neighbourhoodData: state.plansReducer.neighborhoodData?.neighbourhoodData,
			plansDetails: state?.plansReducer?.plansDetails,
			qmiDetails: state?.plansReducer?.qmiDetails,
		};
	});

	return (
		<div className="d-flex justify-content-center align-items-center position-relative flex-row ">
			<div className="card pv-collection-card position-relative pv-cardmain">
				<DetailedCard
					plan={plan}
					plansData={plansData}
					data={data}
					plansDetails={plansDetails}
					toggle={forceRender}
					setToggle={setForceRender}
					setStoreArray={setStoreArray}
					removeStoreArray={removeStoreArray}
				/>
			</div>
		</div>
	);
}

export default PlansCard;
