import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContentLoader } from "@episerver/content-delivery";

const contentLoader = new ContentLoader();

export const customConstructPage = createAsyncThunk(
	"customConstruct/fetchCustomConstructPage",
	async ({ corporate, children }) => {
		const customConstructPageKiosk = children?.find((item) => {
			return item?.contentType?.includes("CustomAndConstructPage");
		});

		const customConstructChildren = await contentLoader.getChildren(
			customConstructPageKiosk?.contentLink?.guidValue,
			{
				branch: "en",
			}
		);

		const customConstructChildrenData = customConstructChildren?.find((item) => {
			return (
				item?.contentType?.includes("DesignCollectionCustomAndConstructHowWeDoPage") ||
				item?.contentType?.includes("CustomAndConstructHowWeDoPage")
			);
		});

		const customConstructList = await Promise.all(
			corporate?.see_how_we_do_it_content_area?.value?.map(async (items) => {
				const list = await contentLoader.getContent(items?.contentLink?.guidValue, { branch: "en" });
				return list;
			})
		);

		return {
			customConstructPageKiosk,
			customConstructList,
			customConstructChildrenData,
		};
	}
); // end

export const DesignCollectionCustomAndConstructHowWeDoPage = createAsyncThunk(
	"customConstruct/fetchDesignCollectionCustomAndConstructHowWeDoPage",
	async ({ children, customConstructChildrenData }) => {
		const customConstructListKiosk = await Promise.all(
			customConstructChildrenData?.see_how_we_do_it_content_area?.value?.map(async (items) => {
				const list = await contentLoader.getContent(items?.contentLink?.guidValue, { branch: "en" });
				return list;
			})
		);

		return { customConstructListKiosk };
	}
);
