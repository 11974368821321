import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./communityLanding.scss";
import { fetchCommunityPage, fetchOurTeamDetails, fetchOur } from "../../services/community/communityServices";
import CommunityCardBg1 from "../../assets/images/Community-image.png";
import CommunityCardBg2 from "../../assets/images/Community-image2.png";
import GreenArrowLeft from "../../assets/images/green-arrow-left.svg";
import GreenArrowRight from "../../assets/images/green-arrow-right.svg";
import { assetTrim, capitalizeEachWord, damName, scale, youtube_videoId } from "../../constant";
import { CurveAnimation } from "../components/CurveAnimation";
import { RightNav } from "../../app/component/RightNav";
import { Offcanvas } from "../components/Offcanvas";
import _ from "lodash";

export const CommunityLanding = ({ setCustomeClassName }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const animation = location.state?.animation;
	const [state, setState] = useState({
		ammentiesSchoolsBtn: false,
		viewVideoBtn: false,
	});
	const [showCommunityImage, setShowCommunityImage] = useState(animation);
	const [videoId, setVideoId] = useState("");
	const [filteredOurTeam, setFilteredOurTeam] = useState([]);
	const [uniqVideoList, setUniqVideoList] = useState([]);

	const {
		subContent,
		communityKiosk,
		kiosk,
		amenitiesAndSchools,
		communityListKiosk,
		communityVideoListKiosk,
		corporate,
		ourTeam,
		ourTeamPage
	} = useSelector((state) => {
		return {
			loading: state?.communityReducer?.loading,
			communityKiosk: state.communityReducer.communityPageData.communityPageKioskData,
			communityListKiosk: state.communityReducer.communityPageData.communityListKiosk,
			communityVideoListKiosk: state.communityReducer.communityPageData.communityVideoList,
			subContent: state.homeReducer.subContent,
			corporate: state.homeReducer.site,
			kiosk: state.homeReducer.homepageData.kiosk,
			amenitiesAndSchools: state.communityReducer.communityPageData.amenitiesAndSchools,
			ourTeamPage: state.communityReducer.communityPageData.ourTeamPage,
			ourTeamDetails: state.communityReducer.ourTeamData,
			ourTeam: state.communityReducer.ourTeam,
		};
	});

	useEffect(() => {
		const uniqueCommunityVideo = _.uniqBy(communityVideoListKiosk, function (item) {
			return item?.videoLink?.value;
		});
		setUniqVideoList(uniqueCommunityVideo);
	}, [communityVideoListKiosk]);

	useEffect(() => {
		const uniqueTeam = _.uniqBy(ourTeam, function (item) {
			return item?.employee_first_name && item?.employee_last_name;
		});

		const filterBuilder = uniqueTeam?.filter((ele) => {
			return ele.employee_title === "Builder";
		});
		const filterMarketManager = uniqueTeam?.filter((ele) => {
			return ele.employee_title === "Market Manager";
		});
		const filterMarketManagerTrainee = uniqueTeam?.filter((ele) => {
			return ele.employee_title === "Market Manager Trainee";
		});
		const filterSeniorMarketManager = uniqueTeam?.filter((ele) => {
			return ele.employee_title === "Senior Market Manager";
		});
		const filterCustomerCareRep = uniqueTeam?.filter((ele) => {
			return ele.employee_title === "Customer Care Representative";
		});

		const filterLoanOfficer = uniqueTeam?.filter((ele) => {
			return ele.employee_title === "Loan Officer";
		});

		const filteredTeam = [
			...filterBuilder,
			...filterMarketManager,
			...filterMarketManagerTrainee,
			...filterSeniorMarketManager,
			...filterCustomerCareRep,
			...filterLoanOfficer,
		];
		setFilteredOurTeam(filteredTeam);
		localStorage.setItem('ourteam', JSON.stringify(filteredTeam))
	}, [ourTeam]);

	useEffect(() => {
		setVideoId(communityVideoListKiosk?.[0]?.contentLink.guidValue);
	}, [communityVideoListKiosk]);

	useEffect(() => {
		if (corporate) {
			setCustomeClassName("");
			dispatch(fetchCommunityPage(subContent));
			dispatch(fetchOur(kiosk));
		}
	}, [corporate, subContent]);

	useEffect(() => {
		if (showCommunityImage) {
			const toRef = setTimeout(() => {
				setShowCommunityImage(false);
				clearTimeout(toRef);
			}, 2500);
		}
	}, [showCommunityImage]);

	const prevIcon = () => {
		return (
			<button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
				<img src={GreenArrowLeft} alt="Left Icon" />
			</button>
		);
	};

	const nextIcon = () => {
		return (
			<button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
				<img src={GreenArrowRight} alt="Right Icon" />
			</button>
		);
	};

	return (
		<div>
			{showCommunityImage ? (
				<CurveAnimation pageName={"community"} />
			) : (
				<div>
					<div className="dk-MainContentWrapper blackoverlay">
						<div
							className="cc-main-container testi-main-container dk-community-container RightMenu h-100 background-properties"
							style={{
								backgroundImage: `url(${assetTrim(
									damName,
									communityKiosk?.community_background_image?.value,
									scale
								)}?scale=xxl)`,
							}}>
							<div className="cc-wrapper drees-testimonial-wrapper h-100">
								<h1 className="cc-why-drees-title text-uppercase pb-15 dk-community-heading">
									{" "}
									{kiosk?.title?.value}
								</h1>
								<p className="cc-why-drees-text white-color proximaNova-regular dc-community-desc">
									{communityKiosk?.community_description?.value}
								</p>
								<Carousel
									fade
									slide={false}
									interval={null}
									indicators={false}
									prevIcon={prevIcon()}
									nextIcon={nextIcon()}>
									{communityListKiosk?.map((item) => {
										return (
											<Carousel.Item key={item?.contentLink?.guidValue}>
												<div className="d-flex align-items-center justify-content-around testimonial-slider community-slider">
													<div className="cards">
														<div
															className="card one"
															style={{
																backgroundImage: "url(" + CommunityCardBg1 + ")",
															}}></div>
														<div
															className="card two"
															style={{
																backgroundImage: "url(" + CommunityCardBg2 + ")",
															}}></div>
														<div
															className="card three"
															style={{
																backgroundImage: `url(${assetTrim(
																	damName,
																	item?.imageUrl?.value,
																	scale
																)}?scale=small)`,
															}}></div>
													</div>
												</div>
											</Carousel.Item>
										);
									})}
								</Carousel>

								<div className="text-center dk-commmain-btn-wrapper">
									{amenitiesAndSchools && (
										<button
											type="submit"
											className="btn primary-button cc-button"
											onClick={() => setState({ ammentiesSchoolsBtn: true })}>
											Amenities and Schools
										</button>
									)}
									<Offcanvas
										show={state.ammentiesSchoolsBtn}
										offcanvasClassName="ccw-watch-video-wrapper w-100 ammenties-School-Wrapper"
										onHide={() => setState({ ammentiesSchoolsBtn: false })}
										headerClassName="ccw-close-wrapper">
										<div className="d-flex p-0 ammenties-main-wrapper h-100">
											<div className="dk-explore-left dk-ammenties-left d-flex flex-column">
												<div
													className="ammenties-img-wrapper background-properties"
													style={{
														backgroundImage: `url(${assetTrim(
															damName,
															amenitiesAndSchools?.image?.value,
															scale
														)}?scale=large)`,
													}}></div>
												<div className="ammenties-content-wrapper">
													<h2 className="font-size-30">{amenitiesAndSchools?.schools_headline?.value}</h2>
													{amenitiesAndSchools?.school_name_and_grade?.value?.map((item) => {
														return <p>{item?.school_name_and_grade_levels}</p>;
													})}
												</div>
											</div>
											<div className="dk-explore-right dk-ammenties-right d-flex justify-content-center align-items-start">
												<div className="scrollbar">
													<h2 className="text-center">FEATURED AMENITIES</h2>
													<ul className="d-flex flex-wrap ammenties-list p-0">
														{amenitiesAndSchools?.featured_amenities?.value?.map((item) => {
															return (
																<li className="white-color" key={item?.amenity_description}>
																	<img
																		src={`${assetTrim(damName, item?.amenity_icon, scale)}?scale=small`}
																		alt={item?.amenity_description}
																	/>{" "}
																	{item?.amenity_description}
																</li>
															);
														})}
													</ul>
												</div>
											</div>
										</div>
									</Offcanvas>
									{uniqVideoList?.length > 0 ? (
										<button
											type="submit"
											className="btn primary-button cc-button"
											onClick={() => setState({ viewVideoBtn: true })}>
											View Video
										</button>
									) : (
										<Link to="/ourTeam" state={{ filteredOurTeam }}>
											<button
												type="submit"
												className="btn primary-button cc-button"
												onClick={() => setState({ ourTeamBtn: true })}>
												{capitalizeEachWord(communityKiosk?.our_team_button_label?.value)}
											</button>
										</Link>
									)}

									{uniqVideoList?.length === 1 ? (
										<Offcanvas
											show={state.viewVideoBtn}
											offcanvasClassName="ccw-watch-video-wrapper w-100"
											onHide={() => setState({ viewVideoBtn: false })}
											headerClassName="ccw-close-wrapper"
											bodyClassName="ccw-video-wrapper">
											<h1 className="ccw-heading">{uniqVideoList?.[0]?.videoName?.value}</h1>
											<div className="ccw-video-main-wrapper">
												<iframe
													title="communityViewVideo"
													className="ccw-video"
													src={`https://www.youtube.com/embed/${youtube_videoId(
														communityVideoListKiosk?.[0]?.videoLink?.value
													)}?autoplay=1`}
													allow="autoplay"></iframe>
											</div>
										</Offcanvas>
									) : (
										<Offcanvas
											show={state.viewVideoBtn}
											placement="end"
											offcanvasClassName="ccw-watch-video-wrapper w-100"
											onHide={() => setState({ viewVideoBtn: false })}>
											<div closeButton headerClassName="ccw-close-wrapper"></div>
											<div className="testimonial-offcanvasbody">
												<section>
													<div className="testimonial-video-container">
														<div className="d-flex testi-video-wrapper">
															{uniqVideoList?.map((item) => {
																if ((item?.contentLink?.guidValue).includes(videoId) && item?.videoLink?.value) {
																	return (
																		<div className="td-video-wrapper" key={item?.contentLink?.guidValue}>
																			<h1 className="td-testimonial-title">{item?.videoName?.value}</h1>
																			<iframe
																				title="testi-video"
																				className="ccw-testimonial-videoIframe"
																				src={`https://www.youtube.com/embed/${youtube_videoId(
																					item?.videoLink?.value
																				)}?autoplay=1`}
																				allow="autoplay"></iframe>
																		</div>
																	);
																}
															})}
															<ul className="d-flex testimonials-list-wrapper flex-wrap">
																{uniqVideoList?.map((list) => {
																	if (list?.videoLink?.value) {
																		return (
																			<li className="testimonials-list" key={list?.contentLink?.guidValue}>
																				<h2 className="td-testimonial-heading" title={list?.videoName?.value}>
																					{list?.videoName?.value}
																				</h2>
																				<div className="position-relative img-panel">
																					<img
																						className="td-testimonial-vc"
																						src={`https://img.youtube.com/vi/${youtube_videoId(
																							list?.videoLink?.value
																						)}/0.jpg`}
																						alt="Testimonial Video Cover"
																					/>
																					<button
																						className="btn td-testi-btn primary-button"
																						onClick={() => setVideoId(list?.contentLink?.guidValue)}>
																						Watch Video
																					</button>
																				</div>
																			</li>
																		);
																	}
																})}
															</ul>
														</div>
													</div>
												</section>
											</div>
										</Offcanvas>
									)}
									{uniqVideoList?.length >= 1 && (
										<Link to="/ourTeam" state={{ filteredOurTeam }}>
											<button
												type="submit"
												className="btn primary-button cc-button our-team"
												onClick={() => setState({ ourTeamBtn: true })}>
												{capitalizeEachWord(communityKiosk?.our_team_button_label?.value)}
											</button>
										</Link>
									)}
								</div>
							</div>
						</div>
						<RightNav />
					</div>
				</div>
			)}
		</div>
	);
};
