import { ContentLoader } from "@episerver/content-delivery";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CommunityPage } from "../../constant";

const { REACT_APP_MDW_API_BASE_URL } = process.env;

const contentLoader = new ContentLoader();

export const fetchCommunityPage = createAsyncThunk("community/fetchCommunityPage", async (subContent) => {
	const communityPageKioskData = subContent?.find((ele) => {
		return ele?.contentType?.includes(CommunityPage);
	});

	const communityListKiosk = await Promise.all(
		communityPageKioskData?.community_images_cards?.value.map(async (image) => {
			const images = await contentLoader.getContent(image?.contentLink.guidValue, { branch: "en" });
			return images;
		})
	);

	const children = await contentLoader.getChildren(communityPageKioskData?.contentLink.guidValue, {
		branch: "en",
	});

	const amenitiesAndSchools = children?.find((item) => {
		return item?.contentType?.includes("AmenitiesAndSchoolsPage");
	});

	const ourTeamPage = children?.find((item) => {
		return item?.contentType?.includes("OurTeamPage");
	});

	let communityVideoList = [];

	if (communityPageKioskData?.community_view_video_contentarea?.value !== null) {
		communityVideoList = await Promise.all(
			communityPageKioskData?.community_view_video_contentarea?.value.map(async (video) => {
				const videos = await contentLoader.getContent(video?.contentLink.guidValue, { branch: "en" });
				return videos;
			})
		);
	}

	return { amenitiesAndSchools, communityPageKioskData, ourTeamPage, communityListKiosk, communityVideoList };
});

export const fetchOur = createAsyncThunk("community/fetchTeam", async (kiosk, { rejectWithValue }) => {
	const team = await Promise.all(
		kiosk?.neighborhoods?.value?.map(async (code) => {
			const codes = await axios
				.get(`${REACT_APP_MDW_API_BASE_URL}/neighborhood/get-team?neighborhood_code=${code?.neighborhoodCode}`)
				.then((res) => {
					return res?.data?.data;
				})
				.catch((err) => {
					throw rejectWithValue(err);
				});
			return codes;
		})
	);
	const teams = team?.filter((ele) => {
		return ele.length > 0;
	});
	const ourTeams = teams?.flat(1);
	return ourTeams;
});
