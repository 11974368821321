import { React, useEffect, useState } from "react";
import ArrowLeft from "../../assets/images/prev-arrow-white.svg";
import "./planQuickMoveIn.scss";
import { RightNav } from "../../app/component/RightNav";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { assetTrim, capitalizeEachWord, damName, scale } from "../../constant";
import { fetchPlansPage } from "../../services/plans/plansServices";
import QuickMoveInCard from "./QuickMoveInCard";

export const PlanQuickMoveIn = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const data = location.state?.data;
	const [persistData, setPersistData] = useState({});

	const { corporate, subContent, kiosk, plansQmiPage, plansCommunityPage } = useSelector((state) => {
		return {
			corporate: state?.homeReducer?.site,
			plansCommunityPage: state.plansReducer.plansPageData.plansCommunityPage,
			neighbourhoodData: state.plansReducer.neighborhoodData?.neighbourhoodData,
			neighborhoods: state.plansReducer.neighborhoods,
			subContent: state.homeReducer.subContent,
			kiosk: state.homeReducer.homepageData.kiosk,
			plansQmiPage: state.plansReducer?.plansPageData?.plansQmiPage,
		};
	});

	useEffect(() => {
		if (localStorage.getItem("data")) {
			const persistData = JSON.parse(localStorage.getItem("data"));
			setPersistData(persistData);
		}
	}, [localStorage.getItem("data")]);

	useEffect(() => {
		if (corporate && kiosk?.contentLink?.id !== plansCommunityPage?.parentLink?.id) {
			dispatch(fetchPlansPage({ subContent, kiosk }));
		}
	}, [corporate, plansCommunityPage, kiosk]);

	return (
		<div>
			<section
				className="pp-explore-wrapper background-properties RightMenu blackoverlayTopWave"
				style={{
					backgroundImage: `url(${assetTrim(
						damName,
						plansQmiPage?.plan_qmi_neighborhood_background_image?.value,
						scale
					)}?scale=xxl)`,
				}}>
				<div className="wevewrapper">
					<div className="d-flex justify-content-between align-items-center mb-5">
						<div>
							<Link to="/plans" className="pl-back-to-landing d-flex align-items-center" state={{ data: persistData }}>
								<img className="pl-backarrow" src={ArrowLeft} alt="Arrow left icon" /> Back to{" "}
								{capitalizeEachWord(kiosk?.title?.value)} Neighborhoods
							</Link>
						</div>
						<div className="d-flex flex-column pl-btn-wrapper">
							<Link to="/planCollection" state={{ data: persistData }}>
								<button className="btn pl-explore-btn mb-10 primary-button">Back to Plans</button>
							</Link>
						</div>
					</div>
					<div className="text-center  pb-35">
						<h1 className="pl-heading text-green">{persistData?.market_name}</h1>
						<h2 className="pl-subheading white-color">{corporate?.quick_move_in_homes_heading?.value}</h2>
					</div>

					<div className="d-flex flex-wrap justify-content-center align-items-center pqm-main-container">
						{persistData?.qmis?.map((plan) => (
							<QuickMoveInCard plan={plan} data={persistData} plansQmiPage={plansQmiPage} key={plan?.job_number} />
						))}
					</div>
				</div>
			</section>
			<RightNav />
		</div>
	);
};
