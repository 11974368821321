import React from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-web";
import HomeWaveAnimation from "../../assets/images/animations/HomeWaveAnimation.json";
import "./dreesKioskHome.scss";
import TestimonialCarousel from "./TestimonialCarousel";
import { useDispatch } from "react-redux";
import { animation, homeSitesBtn } from "./homeSlice";
import { assetTrim, damName, scale } from "../../constant";

export const HomeContent = ({ corporate, kiosk }) => {
	const { title, backgroundImage, whyDreesImage, designInspirationImage, communityImage, plansImage } = kiosk;

	React.useEffect(() => {
		Lottie.loadAnimation({
			container: document.querySelector("#HomeWaveAnimationID"),
			animationData: HomeWaveAnimation,
			loop: true,
			autoplay: true,
			height: "100%",
			width: "100%",
		});
	}, []);

	const dispatch = useDispatch();

	return (
		<div>
			<div id="HomeWaveAnimationID">
				<div
					className="dk-homeBg"
					style={{
						backgroundImage: `url(${assetTrim(damName, backgroundImage?.value, scale)}?scale=xxl)`,
					}}></div>
			</div>

			<div className="dk-home-section w-100 d-flex justify-content-center">
				<div className="dk-home-text-panel d-flex flex-column align-items-start justify-content-center">
					<h2 className="basicSans-regular welcome-text">{corporate?.welcome?.value}</h2>
					<h3 className="basicSans-regular text-start sub-heading">{title?.value}</h3>
				</div>
				<div className="dk-home-animation-panel d-flex align-items-stretch">
					<div className="d-flex">
						<div className="animate-box position-relative" style={{ width: "273px", marginRight: "20px" }}>
							<Link to="/whyDrees" state={{ animation: true }}>
								<div
									className="boxbgImage background-properties h-100 w-100"
									style={{
										backgroundImage: `url( ${assetTrim(damName, whyDreesImage?.value, scale)}?scale=large )`,
										backgroundPosition: "0 center",
									}}></div>
								<div className="box d-flex align-items-center justify-content-center text-center box1">
									{corporate?.whyDreesText?.value}
								</div>
							</Link>
						</div>
					</div>

					<div className="d-flex flex-column flex-grow-1">
						<div className="d-flex" style={{ height: "50%" }}>
							<div
								className="animate-box position-relative"
								style={{ width: "35%", minWidth: "273px", marginRight: "20px" }}>
								<Link
									to=""
									state={{ animation: true }}
									onClick={() => Promise.all([dispatch(homeSitesBtn(true)), dispatch(animation(true))])}
									className="boxbgImage background-properties h-100 w-100"
									style={{
										backgroundImage: `url( ${assetTrim(
											damName,
											corporate?.homeSitesImage?.value,
											scale
										)}?scale=large )`,
									}}>
									{" "}
									<div className="box d-flex align-items-center justify-content-center text-center box2">
										{corporate?.homeSitesText?.value}
									</div>
								</Link>
							</div>
							<div className="animate-box position-relative" style={{ width: "65%", minWidth: "562px" }}>
								<Link to="/community" state={{ animation: true }}>
									<div
										className="boxbgImage background-properties h-100 w-100"
										style={{
											backgroundImage: `url( ${assetTrim(damName, communityImage?.value, scale)}?scale=large )`,
										}}>
										{" "}
									</div>
									<div className="box d-flex align-items-center justify-content-center text-center box3">
										{corporate?.communityText?.value}
									</div>
								</Link>
							</div>
						</div>

						<div className="d-flex" style={{ height: "50%", marginTop: "20px" }}>
							<div
								className="animate-box position-relative"
								style={{ width: "55%", minWidht: "440px", marginRight: "20px" }}>
								<Link to="/plans" state={{ animation: true }}>
									<div
										className="boxbgImage background-properties h-100 w-100"
										style={{
											backgroundImage: `url( ${assetTrim(damName, plansImage?.value, scale)}?scale=large )`,
										}}></div>
									<div className="box d-flex align-items-center justify-content-center text-center box4">
										{corporate?.plansText?.value}
									</div>
								</Link>
							</div>
							<div className="animate-box position-relative" style={{ width: "45%", minWidht: "390px" }}>
								<Link to="/designInspiration" state={{ animation: true }}>
									<div
										className="boxbgImage background-properties h-100 w-100"
										style={{
											backgroundImage: `url( ${assetTrim(
												damName,
												designInspirationImage?.value,
												scale
											)}?scale=large )`,
										}}></div>
									<div className="box d-flex align-items-center justify-content-center text-center box5">
										{corporate?.designInspirationText?.value}
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="dk-hometestimonial-wrapper w-100" style={{ position: "fixed" }}>
					<TestimonialCarousel
						testiMonials={corporate?.testiMonials?.value}
						createdBy={corporate?.testiMonials?.value[0].createdBy}
						testimonialsIcon={corporate?.testimonialIcon?.value}
					/>
				</div>
			</div>
		</div>
	);
};
