import React, { useEffect, useState } from "react";
import "./planCollections.scss";
import { getStore, removeStoreArray, setStoreArray } from "../../services/localStoreService";
import { DetailedCard } from "../components/DetailedCard";

function QuickMoveInCard({ plan, data, plansQmiPage }) {
  const [forceRender, setForceRender] = useState(false);
  const [plansData, setPlansData] = useState([]);

  useEffect(() => {
    const plansDatas = getStore("quickMoveIn");
    setPlansData(plansDatas);
  }, [forceRender]);

  return (
    <div className="card pv-collection-card pqm-collection-card">
      <DetailedCard
        plan={plan}
        data={data}
        plansQmiPage={plansQmiPage}
        plansData={plansData}
        toggle={forceRender}
        setToggle={setForceRender}
        setStoreArray={setStoreArray}
        removeStoreArray={removeStoreArray}
      />
    </div>
  );
}

export default QuickMoveInCard;
