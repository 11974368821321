import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo-white.svg";
import "./header.scss";
import "./submenu.scss";
import { SignUp } from "./SignUp";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas } from "../../features/components/Offcanvas";
import Explore from "./Explore";
import { fetchHomeSite } from "../../services/home/homeService";
import { assetTrim, damName, scale } from "../../constant";

export const Header = ({ exploreBtn, setExploreBtn }) => {
	const location = useLocation();
	const pathname = location.pathname;
	const kioskId = localStorage.getItem("kiosk");
	const [explore, setExplore] = useState(false);
	const [state, setState] = useState({
		visitCommunitiesNearByBtn: false,
		signUpBtn: false,
	});
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const setStateToggle = (button) => {
		setState(button);
	};
	const { kiosk, corporate } = useSelector((state) => {
		return {
			kiosk: state.homeReducer.homepageData.kiosk,
			corporate: state.homeReducer.site,
		};
	});
	
	const handleSignUpSuccess = () => {
		setShow(true);
	};

	const handleClose = () => {
		setShow(false);
	};
	const handleExploreBtn = (bool) => {
		if (setExploreBtn === undefined) {
			setExplore(bool);
		} else {
			setExploreBtn(bool);
		}
	};
	return (
		<>
			{pathname !== '/login' && (
				<header>
					<div className="dk-header d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<div className="dk-logo-wrapper">
								{kiosk?.contentLink?.id?.toString() !== kioskId ? (
									<Link to="/">
										<img
											className="dk-logo"
											src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
											alt="Drees Homes Logo"
											onClick={() => dispatch(fetchHomeSite(kioskId))}
										/>
									</Link>
								) : location?.pathname === "/" ? (
									<img
										className="dk-logo"
										src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
										alt="Drees Homes Logo"
									/>
								) : (
									<Link to="/">
										<img
											className="dk-logo"
											src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
											alt="Drees Homes Logo"
										/>
									</Link>
								)}
							</div>
							<ul className="d-flex align-items-center dk-header-link-wrapper list-unstyled mb-0">
								<li>
									<div
										className="d-flex align-items-center basicSans-regular dk-link-wrapper"
										onClick={() => setState({ signUpBtn: true })}>
										<span className="icon-sign-up dk-icon-wrapper">
											<span className="visually-hidden">SignUp icon</span>
										</span>
										<span className="dk-header-link">Sign Up</span>
									</div>
								</li>
								<li>
									<Link
										to="/favorite"
										className={`d-flex align-items-center  ${pathname === "/favorite" ? "active" : ""}`}>
										<span className="icon-favorite dk-icon-wrapper">
											<span className="visually-hidden">Favorite icon</span>
										</span>
										<span className="dk-header-link active">Favorites</span>
									</Link>
								</li>
							</ul>
						</div>
						<div>
							<a
								color="primary"
								onClick={() => {
									handleExploreBtn(true);
									setState({ visitCommunitiesNearByBtn: false });
								}}
								className="nav-explore d-flex align-items-center text-uppercase proximaNova-regular mar-r75"
								role="button"
								aria-controls="exploreMenu">
								<span className="dk-header-link">Explore</span>
								<span className="icon-hamburger hamburger-icon ps-2">
									<span className="visually-hidden">hamburger icon</span>
								</span>
							</a>
						</div>
					</div>
					<Offcanvas
						show={state.signUpBtn}
						offcanvasClassName="view-ele-offcanvas ccw-watch-video-wrapper h-100 signupWrapper"
						onHide={() => setState({ signUpBtn: false })}
						placement="top">
						<SignUp setStateToggle={setStateToggle} handleSignUpSuccess={handleSignUpSuccess} />
					</Offcanvas>

					<Offcanvas
						show={explore || exploreBtn}
						placement="end"
						offcanvasClassName="w-100 OffcanvasExploreMenu view-body-offcanvas"
						onHide={() => handleExploreBtn(false)}
						bodyClassName="d-flex p-0">
						<Explore
							setStateToggle={setStateToggle}
							state={state}
							setExploreBtn={handleExploreBtn}
							exploreBtn={exploreBtn}
						/>
					</Offcanvas>
					<Modal
						show={show}
						onHide={handleClose}
						className="Modal-popover modal-Signup d-flex align-items-center justify-content-center">
						<Modal.Header closeButton className="ccw-close-wrapper">
							{/* <Modal.Title></Modal.Title> */}
						</Modal.Header>
						<Modal.Body>
							<p style={{ fontSize: "45px", textAlign: "center" }}>{corporate?.signup_pop_up_title?.value}</p>
						</Modal.Body>
					</Modal>
				</header>
			)}
		</>
	);
};
