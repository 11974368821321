import { React, useEffect, useState } from "react";
import _ from "lodash";
import "./comparePlanDetail.scss";
import CloseIcon from "../../assets/images/close-white-rounded.svg";
import { fetchAllPlans, fetchSelectedPlans } from "../../services/plans/plansServices";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ComparePlan from "./ComparePlan";
import { ComparePlanListing } from "./ComparePlanListing";
import { Offcanvas } from "react-bootstrap";
import Loader from "../../app/component/loader/Loader";

export const ComparePlanDetail = ({
	setstate,
	state,
	checkedPlansDetails,
	data,
	plan,
	checkedPlans,
	setCheckedPlans,
	comparePlans,
}) => {
	const dispatch = useDispatch();
	const [selectedPlans, setSelectedPlans] = useState([]);
	const [switched, setSwitched] = useState("plans");
	const [carouselFirstPlans, setCarouselFirstPlans] = useState([]);
	const [carouselSecondPlans, setCarouselSecondPlans] = useState([]);
	const [selectedPlanElevations, setSelectedPlanElevations] = useState([]);
	const [firstPlanElevations, setFirstPlanElevations] = useState([]);
	const [secondPlanElevations, setSecondPlanElevations] = useState([]);
	const [anotherPlanElevations, setAnotherPlanElevations] = useState([]);
	const [carouselAnotherPlansElevations, setCarouselAnotherPlansElevations] = useState([]);
	const [allPlansElevations, setAllPlansElevations] = useState([]);
	const [carouselAllPlans, setCarouselAllPlans] = useState([]);
	const [anotherPlan, setAnotherPlan] = useState([]);
	const [carouselAnotherPlans, setCarouselAnotherPlans] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedSlide, setSelectedSlide] = useState(0);

	const { corporate, allPlansDetails, selectedPlansDetails } = useSelector((state) => {
		return {
			corporate: state?.homeReducer?.site,
			selectedPlansDetails: state?.plansReducer?.selectedPlansDetails,
			allPlansDetails: state?.plansReducer?.allPlansDetails,
		};
	});

	// fetch all plans
	useEffect(() => {
		if (corporate) {
			dispatch(fetchAllPlans(data?.plans));
		}
	}, [corporate, data]);

	// fetch selected plans
	useEffect(() => {
		if (corporate) {
			dispatch(fetchSelectedPlans(selectedPlans));
		}
	}, [corporate, selectedPlans]);

	// selected another plan
	useEffect(() => {
		const plans = checkedPlansDetails?.map((code) => {
			const plan = data?.plans?.filter((ele) => {
				if (ele?.plan_code === code) {
					return [ele];
				}
			});
			return plan[0];
		});
		setSelectedPlans(plans);
	}, [checkedPlansDetails]);

	useEffect(() => {
		const allElevations = allPlansDetails?.map((ele) => {
			const filterElevations = ele?.assets?.filter((item) => {
				return item.image_type === "rendering";
			});
			return filterElevations;
		});
		const result = allElevations?.filter((item) => item?.length > 0);
		setAllPlansElevations(result);
	}, [allPlansDetails]);

	// toggle plans and Elevations
	const select = (e) => {
		if (e.target.checked) {
			setSwitched("elevations");
		} else {
			setSwitched("plans");
		}
	};

	// filtering elevations
	useEffect(() => {
		const selectedElevations = selectedPlansDetails?.map((ele) => {
			const filter = ele?.assets?.filter((item) => {
				return item.image_type === "rendering";
			});
			return filter;
		});
		setSelectedPlanElevations(selectedElevations);
		const anotherElevations = carouselAnotherPlans?.map((ele) => {
			const filter = ele?.assets?.filter((item) => {
				return item.image_type === "rendering";
			});
			return filter;
		});
		setCarouselAnotherPlansElevations(anotherElevations);
	}, [selectedPlansDetails, anotherPlan, carouselAnotherPlans]);

	// slot plans
	useEffect(() => {
		const total = allPlansDetails?.filter((ele) => {
			return ele?.plan_code !== selectedPlansDetails?.[0]?.plan_code;
		});
		setCarouselAllPlans(total);

		const firstPlans = allPlansDetails?.filter((ele) => {
			return (
				ele?.plan_code !== selectedPlansDetails?.[0]?.plan_code &&
				ele?.plan_code !== selectedPlansDetails?.[1]?.plan_code
			);
		});
		setCarouselFirstPlans(firstPlans);

		const secondPlans = allPlansDetails?.filter((ele) => {
			return (
				ele?.plan_code !== selectedPlansDetails?.[0]?.plan_code &&
				ele?.plan_code !== selectedPlansDetails?.[2]?.plan_code
			);
		});
		setCarouselSecondPlans(secondPlans);
	}, [allPlansDetails, selectedPlansDetails]);

	// filter slot plan elevations
	useEffect(() => {
		const firstPlanElevation = carouselFirstPlans?.map((ele) => {
			const filter = ele?.assets?.filter((item) => {
				return item.image_type === "rendering";
			});
			return filter;
		});
		setFirstPlanElevations(firstPlanElevation);
		const secondPlanElevation = carouselSecondPlans?.map((ele) => {
			const filter = ele?.assets?.filter((item) => {
				return item.image_type === "rendering";
			});
			return filter;
		});
		setSecondPlanElevations(secondPlanElevation);
		const anotherPlanElevation = anotherPlan?.map((ele) => {
			const filter = ele?.assets?.filter((item) => {
				return item.image_type === "rendering";
			});
			return filter;
		});
		setAnotherPlanElevations(anotherPlanElevation);
	}, [carouselFirstPlans, carouselSecondPlans, anotherPlan]);

	// selecting one plan
	const selected = (index) => {
		const checks = document.querySelectorAll(".compare");
		let total = 0;
		for (let i = 0; i < checks.length; i++) {
			if (checks[i].checked) {
				total += 1;
			}
		}
		setCheckedPlans(total);
		if (total > 1) {
			checks[index].checked = false;
		}
		return false;
	};

	// on submit another plan
	const anotherCompareSubmit = () => {
		const checked = document.querySelectorAll(".compare:checked");
		const selected = [...checked].map((c) => c.value);
		const selectedPlan = allPlansDetails?.filter((item) => selected.includes(item?.plan_code));
		setAnotherPlan(selectedPlan);
		setCarouselAnotherPlans(
			allPlansDetails?.filter(
				(item) =>
					item?.plan_code !== selectedPlan[0]?.plan_code && item?.plan_code !== selectedPlansDetails[0]?.plan_code
			)
		);
	};

	const changeSlide = (index) => {
		setSelectedSlide(index);
	};

	useEffect(() => {
		if (loading) {
			const toRef = setTimeout(() => {
				setLoading(false);
				clearTimeout(toRef);
			}, 4000);
		}
	}, [loading]);

	return (
		<div>
			<section className="cpd-plandetail-wrapper d-flex RightMenu">
				<div className="pv-view-right d-flex flex-wrap cpd-row align-items-start">
					<>
						{!selectedPlansDetails[0] ? (
							<Loader />
						) : (
							<ComparePlan
								planDetails={selectedPlansDetails[0]}
								setstate={setstate}
								state={state}
								switched={switched}
								select={select}
								selectedPlan={selectedPlansDetails[0]}
								elevations={selectedPlanElevations[0]}
								allPlansElevations={allPlansElevations}
							/>
						)}
					</>
				</div>

				<div className="pv-view-right align-items-start d-flex flex-wrap cpd-row">
					{!selectedPlansDetails[1] ? (
						<Loader />
					) : (
						<Carousel
							selectedItem={selectedSlide}
							infiniteLoop={false}
							transitionTime="500"
							interval="2000"
							showThumbs={false}
							showArrows={false}
							stopOnHover={false}
							axis="vertical"
							verticalSwipe="standard"
							emulateTouch={true}
							centerMode={true}
							showIndicators={false}
							showStatus={false}
							swipeable={true}
							onChange={changeSlide}>
							{[selectedPlansDetails[1], ...carouselFirstPlans]?.map((item, index) => {
								return (
									<div>
										<ComparePlan
											planDetails={item}
											switched={switched}
											state={state}
											setstate={setstate}
											firstPlanElevations={[selectedPlanElevations[1], ...firstPlanElevations][index]}
											allPlansElevations={allPlansElevations}
										/>
									</div>
								);
							})}
						</Carousel>
					)}
				</div>

				<div className="pv-view-right align-items-start d-flex flex-wrap cpd-row">
					{loading ? (
						<Loader />
					) : selectedPlansDetails[2] ? (
						<Carousel
							infiniteLoop={false}
							transitionTime="500"
							interval="2000"
							showThumbs={false}
							showArrows={false}
							stopOnHover={false}
							axis="vertical"
							verticalSwipe="standard"
							emulateTouch={true}
							centerMode={true}
							showIndicators={false}
							showStatus={false}
							swipeable={true}
							scroll={true}>
							{[selectedPlansDetails[2], ...carouselSecondPlans]?.map((item, index) => {
								return (
									<ComparePlan
										planDetails={item}
										switched={switched}
										setstate={setstate}
										state={state}
										secondPlanElevations={[selectedPlanElevations[2], ...secondPlanElevations][index]}
										allPlansElevations={allPlansElevations}
									/>
								);
							})}
						</Carousel>
					) : anotherPlan[0] ? (
						<Carousel
							infiniteLoop={false}
							transitionTime="500"
							interval="2000"
							showThumbs={false}
							showArrows={false}
							stopOnHover={false}
							axis="vertical"
							verticalSwipe="standard"
							emulateTouch={true}
							centerMode={true}
							showIndicators={false}
							showStatus={false}
							swipeable={true}>
							{[anotherPlan[0], ...carouselAnotherPlans]?.map((item, index) => {
								return (
									<ComparePlan
										planDetails={item}
										switched={switched}
										state={state}
										setstate={setstate}
										anotherPlanElevations={[anotherPlanElevations[0], ...carouselAnotherPlansElevations][index]}
										allPlansElevations={allPlansElevations}
									/>
								);
							})}
						</Carousel>
					) : (
						<div className="bordered-btn ">
							<button
								disabled={carouselAllPlans.length > 1 ? false : true}
								className="btn vpl-plan-btn primary-button"
								onClick={() => {
									setstate({ addAnotherPlanBtn: true, comparePlansDetailsBtn: true });
								}}>
								{carouselAllPlans.length > 1 ? "+ Compare Another Plan" : "No plans"}
							</button>
						</div>
					)}

					<div>
						<Offcanvas
							show={state.addAnotherPlanBtn}
							placement="end"
							className="compare-wrapper anotherPlanCompare"
							onHide={() => setstate({ addAnotherPlanBtn: false })}>
							<Offcanvas.Body className="">
								<ComparePlanListing
									setState={setstate}
									plan={plan}
									handleCompare={anotherCompareSubmit}
									comparePlans={comparePlans}
									selected={selected}
									checkedPlans={checkedPlans}
									setCheckedPlans={setCheckedPlans}
									anotherPlan={anotherPlan}
									setSelectedSlide={setSelectedSlide}
								/>
							</Offcanvas.Body>
						</Offcanvas>
					</div>
				</div>
			</section>
			<img
				className="cpd-close-btn btncompare position-absolute"
				src={CloseIcon}
				alt="Close icon"
				onClick={() => {
					setstate({ comparePlansDetailsBtn: false });
				}}
			/>
		</div>
	);
};
