/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from "@reduxjs/toolkit";
import {
	fetchPlansDetails,
	fetchPlansPage,
	fetchSelectedPlans,
	fetchAllPlans,
	fetchQmiDetails,
	fetchNeighborhoodData,
} from "../../services/plans/plansServices";

const initialState = {
	loading: false,
	plansPageData: {},
	plansDetails: {},
	qmiDetails: {},
	selectedPlansDetails: [],
	neighborhoodData: {},
	allPlansDetails: [],
	error: "",
};

const plansSlice = createSlice({
	name: "plans",
	initialState,
	reducers: {
		neighborhoods: (prevState, action) => {
			const state = prevState;
			state.neighborhoods = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPlansPage.pending, (prevState, action) => {
			const state = prevState;
			state.loading = true;
		});
		builder.addCase(fetchPlansPage.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.plansPageData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchPlansPage.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.plansPageData = {};
			state.error = action.payload?.message;
		});
		builder.addCase(fetchPlansDetails.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.plansDetails = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchPlansDetails.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.plansDetails = {};
			state.error = action.payload?.message;
		});
		builder.addCase(fetchSelectedPlans.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.selectedPlansDetails = [...action.payload];
			state.error = "";
		});
		builder.addCase(fetchSelectedPlans.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.selectedPlansDetails = [];
			state.error = action.payload?.message;
		});
		builder.addCase(fetchAllPlans.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.allPlansDetails = [...action.payload];
			state.error = "";
		});
		builder.addCase(fetchAllPlans.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.allPlansDetails = [];
			state.error = action.payload?.message;
		});
		builder.addCase(fetchQmiDetails.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.qmiDetails = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchQmiDetails.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.qmiDetails = {};
			state.error = action.payload?.message;
		});
		builder.addCase(fetchNeighborhoodData.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.neighborhoodData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchNeighborhoodData.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.neighborhoodData = {};
			state.error = action.payload?.message;
		});
	},
});

const { actions, reducer: plansReducer } = plansSlice;

export const {
	// export actions here
	neighborhoods,
} = actions;

export default { plansReducer };
