import React, { useEffect, useState } from "react";
import FavDefault from "../../assets/images/Favorites-icon-default.svg";
import { Carousel as Slides } from "react-bootstrap";
import { assetTrim, commaSeperator, damName, defaultImage } from "../../constant";
import swipeToTop from "../../assets/images/swipetotop.png";
import { Offcanvas } from "../components/Offcanvas";
import { PlanView } from "./PlanView";
import _ from "lodash";
import FavIcon from "../../assets/images/Favorites-icon.svg";
import { getStore, removeStoreArray, setStoreArray } from "../../services/localStoreService";

function ComparePlan({
	planDetails,
	setstate,
	state,
	switched,
	select,
	selectedPlan,
	elevations,
	firstPlanElevations,
	secondPlanElevations,
	anotherPlanElevations,
	allPlansElevations,
}) {
	const [swipe, setSwipe] = useState(true);
	const [viewPlan, setViewPlan] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [plansData, setPlansData] = useState([]);
	const [selectedLevel, setSelectedLevel] = useState();
	const [sortedPlanLevels, setSortedPlanLevels] = useState();

	useEffect(() => {
		const sortedLevels = _.sortBy(planDetails?.plan_levels, function (item) {
			return item.floor_level === "Main Level" ? 0 : item.floor_level === "Second Level" ? 1 : 2;
		});
		setSortedPlanLevels(sortedLevels);
	}, [planDetails]);
	useEffect(() => {
		if (swipe) {
			const toRef = setTimeout(() => {
				setSwipe(false);
				clearTimeout(toRef);
			}, 1500);
		}
	}, [swipe]);
	useEffect(() => {
		const plansDatas = getStore("plans");
		setPlansData(plansDatas);
	}, [state.toggle, toggle]);

	return (
		<>
			{planDetails !== selectedPlan && swipe && (
				<div className="swipeToTopWrapper">
					<img src={swipeToTop} alt="" /> <p className="text">Swipe to Switch Plans</p>
				</div>
			)}
			<div className="cpd-wraper">
				<div
					className="cpd-header-wrapper w-100"
					style={{
						backgroundImage: `url(${
							planDetails?.assets?.length === 0
								? defaultImage
								: assetTrim(damName, planDetails?.assets?.[0]?.asset_value)
						}?scale=medium)`,
					}}>
					{planDetails === selectedPlan && (
						<div className="d-flex justify-content-between cpd-header-top align-items-start">
							<div className="form-check cpd-check-wrapper d-flex align-items-start">
								<input
									className="form-check-input cpl-check-input"
									type="checkbox"
									defaultChecked={true}
									value=""
									id="flexCheckDefault"
								/>
								<label className="form-check-label cpd-label-compare" for="flexCheckDefault">
									Currently selected
								</label>
							</div>
							{allPlansElevations?.length > 0 && (
								<div className="form-check form-switch d-flex cpd-form-check justify-content-end">
									<label className="form-check-label cpd-switch-label" for="flexSwitchCheckDefault">
										Plans
									</label>
									<input
										className="switch form-check-input cpd-switch-input"
										type="checkbox"
										role="switch"
										onChange={select}
										id="flexSwitchCheckDefault"
									/>
									<label className="form-check-label cpd-switch-label" for="flexSwitchCheckDefault">
										Elevations
									</label>
								</div>
							)}
						</div>
					)}

					<div className="d-flex pv-right-top justify-content-between cpd-header-bottom align-items-end px-3">
						<h1 className="pl-subheading cpd-plan-heading mb-0 white-color vp-subheading text-capitalize">
							{planDetails?.market_name}
						</h1>
						<button
							className="pv-fav-btn"
							onClick={() => {
								if (
									plansData?.some(
										(item) =>
											item?.plan_code === planDetails?.plan_code &&
											item?.neighborhood_code === planDetails?.neighborhood_code
									)
								) {
									removeStoreArray("plans", planDetails, {
										plan_code: "plan_code",
										neighborhood_code: "neighborhood_code",
									});
								} else {
									setStoreArray("plans", planDetails, {
										plan_code: "plan_code",
										neighborhood_code: "neighborhood_code",
									});
								}
								setstate({ toggle: !state.toggle, comparePlansDetailsBtn: true });
								setToggle(!toggle);
							}}>
							<img
								src={
									plansData?.some(
										(item) =>
											item?.plan_code === planDetails?.plan_code &&
											item?.neighborhood_code === planDetails?.neighborhood_code
									)
										? FavIcon
										: FavDefault
								}
								alt="Favorites icon"
							/>{" "}
						</button>
					</div>
				</div>
				<div className="d-flex justify-content-center w-100 cpd-price-wrapper align-items-center">
					<p className="cpd-card-text text-start ">
						{planDetails?.display_price_low === planDetails?.display_price_high ? (
							<span>${commaSeperator(planDetails?.display_price_low)}</span>
						) : (
							<>
								<span>${commaSeperator(planDetails?.display_price_low)}</span> -{" "}
								<span>${commaSeperator(planDetails?.display_price_high)}</span>
							</>
						)}
					</p>
					<button
						className="btn pl-explore-btn vpl-plan-btn primary-button vpd-button"
						onClick={() => setViewPlan(true)}>
						View Plan
					</button>
					<Offcanvas
						show={viewPlan}
						offcanvasClassName="ccw-watch-video-wrapper w-100 planViewWrapper"
						onHide={() => setViewPlan(false)}
						bodyClassName="testimonial-offcanvasbody view-elevation-wrapper">
						<PlanView
							comparePlanDetails={planDetails}
							setPlanView={setViewPlan}
							toggle={toggle}
							setToggle={setToggle}
						/>
					</Offcanvas>
				</div>
				{switched === "plans" ? (
					<div className="d-flex cpd-plan-desc-wrapper">
						<div className="cpd-info-container">
							<p className="d-flex flex-column cpd-info-wrapper">
								<span className="cpd-plan-info">
									{planDetails?.sqft_low === planDetails?.sqft_high ? (
										<span>{commaSeperator(planDetails?.sqft_low)}</span>
									) : (
										<span>
											{commaSeperator(planDetails?.sqft_low)} - {commaSeperator(planDetails?.sqft_high)}
										</span>
									)}
								</span>
								<span className="cpd-plan-info"> Sq Ft</span>
							</p>
							<p className="d-flex flex-column cpd-info-wrapper">
								<span className="cpd-plan-info">
									{planDetails?.stories_min === planDetails?.stories_max ? (
										<span>{planDetails?.stories_min} </span>
									) : (
										<span>
											{planDetails?.stories_min} - {planDetails?.stories_max}
										</span>
									)}
								</span>
								<span className="cpd-plan-info"> Stories</span>
							</p>
							<p className="d-flex flex-column cpd-info-wrapper">
								<span className="cpd-plan-info">
									{planDetails?.bedrooms_min === planDetails?.bedrooms_max ? (
										<span>{planDetails?.bedrooms_min}</span>
									) : (
										<span>
											{planDetails?.bedrooms_min} - {planDetails?.bedrooms_max}
										</span>
									)}
								</span>
								<span className="cpd-plan-info"> Beds</span>
							</p>
							<p className="d-flex flex-column cpd-info-wrapper">
								<span className="cpd-plan-info">
									{planDetails?.full_baths_min === planDetails?.full_baths_max ? (
										<span>{planDetails?.full_baths_min}</span>
									) : (
										<span>
											{planDetails?.full_baths_min} - {planDetails?.full_baths_max}
										</span>
									)}
								</span>
								<span className="cpd-plan-info">Baths</span>
							</p>
							<p className="d-flex flex-column cpd-info-wrapper">
								<span className="cpd-plan-info">
									{planDetails?.half_baths_min === planDetails?.half_baths_max ? (
										<span>{planDetails?.half_baths_min}</span>
									) : (
										<span>
											{planDetails?.half_baths_min} - {planDetails?.half_baths_max}
										</span>
									)}
								</span>
								<span className="cpd-plan-info"> Half Baths</span>
							</p>
							<p className="d-flex flex-column cpd-info-wrapper">
								<span className="cpd-plan-info">
									{planDetails?.garage_spaces_min === planDetails?.garage_spaces_max ? (
										<span>{planDetails?.garage_spaces_min}</span>
									) : (
										<span>
											{planDetails?.garage_spaces_min} - {planDetails?.garage_spaces_max}
										</span>
									)}
								</span>
								<span className="cpd-plan-info">Car garage</span>
							</p>
						</div>
						{sortedPlanLevels?.length === 0 ? null : (
							<div className="cpd-right-container d-flex flex-column justify-content-between">
								<div className="com-floorMap">
									<img
										className="compare-floor-plan"
										src={
											selectedLevel
												? `${assetTrim(damName, selectedLevel.src)}?scale=original`
												: `${assetTrim(damName, sortedPlanLevels?.[0]?.src)}?scale=original`
										}
										alt={selectedLevel?.floor_level}
									/>
								</div>
								<div className="d-flex justify-content-start cpd-radio-wrapper">
									{_.uniqBy(sortedPlanLevels, function (item) {
										return item.floor_level;
									})?.map((item) => (
										<div
											className="form-check vp-check"
											onClick={() => {
												setSelectedLevel(item);
											}}
											key={item.id}>
											<input
												className="form-check-input vp-check-input"
												type="radio"
												id={item?.floor_level}
												readOnly
												checked={selectedLevel ? selectedLevel.id === item.id : sortedPlanLevels?.[0] === item}
											/>
											<label
												className="form-check-label cpd-radio-label cpd-switch-label text-capitalize"
												for={item.floor_level}>
												{item.floor_level}
											</label>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
				) : (
					<>
						{(firstPlanElevations
							? firstPlanElevations
							: secondPlanElevations
							? secondPlanElevations
							: anotherPlanElevations
							? anotherPlanElevations
							: elevations
						)?.length === 0 ? (
							<div className="cpd-plan-desc-wrapper"></div>
						) : (
							<div className="cpd-plan-desc-wrapper">
								<Slides interval={null} controls={false} className="compareSlider">
									{(firstPlanElevations
										? firstPlanElevations
										: secondPlanElevations
										? secondPlanElevations
										: anotherPlanElevations
										? anotherPlanElevations
										: elevations
									)?.map((ele) => {
										return (
											<Slides.Item key={ele.elevation_code}>
												{" "}
												<h2 className="cpd-detail-title">
													{planDetails?.market_name} Elevation {ele.elevation_code}
												</h2>
												<div className="compareSliderImageWrapper">
													<img
														src={`${assetTrim(damName, ele?.asset_value)}?scale=medium`}
														alt={ele.elevation_code}
													/>
												</div>
											</Slides.Item>
										);
									})}
								</Slides>
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
}

export default ComparePlan;
