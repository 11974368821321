import React, { useState, useEffect } from "react";
import {Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./features/home/Home";
import { Profile } from "./features/profile/Profile";
import { About } from "./features/about/About";
import { WhyDrees } from "./features/whyDrees/WhyDrees";
import { DesignInspiration } from "./features/designInspiration/DesignInspiration";
import { Favorite } from "./features/favorite/Favorite";
import { CommunityLanding } from "./features/communityLanding/CommunityLanding";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeSite, fetchNearByCommunities, fetchSite } from "./services/home/homeService";
import { homeSitesBtn, animation } from "./features/home/homeSlice";
import { PlanCollection } from "./features/plans/PlanCollection";
import { ComparePlanListing } from "./features/plans/ComparePlanListing";
import { ComparePlanDetail } from "./features/plans/ComparePlanDetail";
import { PlanView } from "./features/plans/PlanView";
import { PlanQuickMoveIn } from "./features/plans/PlanQuickMoveIn";
import { Offcanvas  } from "./features/components/Offcanvas";
import { Plans } from "./features/plans/Plans";
import { CurveAnimation } from "./features/components/CurveAnimation";
import { Header } from "./app/component/Header";
import SessionTimeOut from "./features/sessionTimeOut/SessionTimeOut";
import { fetchNeighborhoodData } from "./services/plans/plansServices";
import  LoginForm from "./features/login/LoginForm"
import OurTeam from "./features/communityLanding/OurTeam";
import Promotions from "./app/component/Promotions";

function App() {
	const location = useLocation();
	const dispatch = useDispatch();
	const transition = location.state?.animation;
	const [customeClassName, setCustomeClassName] = useState("");
	const [exploreBtn, setExploreBtn] = useState(false);
	const siteId = localStorage.getItem("kiosk");

	const { btn, kiosk, anim, corporate } = useSelector((state) => {
		return {
			corporate: state?.homeReducer?.site,
			btn: state?.homeReducer?.homeSitesBtn,
			anim: state?.homeReducer?.animation,
			kiosk: state.homeReducer.homepageData.kiosk,
		};
	});

	useEffect(() => {
		if (corporate && !kiosk) {
			dispatch(fetchHomeSite(siteId));
		}
	}, [corporate, kiosk]);

	const [showHomeSitesImage, setShowHomeSitesImage] = useState(anim);
	const params = new URLSearchParams(location.search);

	useEffect(() => {
		if (anim) {
			setShowHomeSitesImage(true);
		}
	}, [anim]);

	useEffect(() => {
		if (kiosk && location.state?.data === true) {
			setExploreBtn(true);
		}
		window.history.replaceState({}, document.title);
	}, [location.state?.data]);

	useEffect(() => {
		if (showHomeSitesImage) {
			const toRef = setTimeout(() => {
				setShowHomeSitesImage(false);
			}, 2500);
		}
	}, [showHomeSitesImage]);

	useEffect(() => {
		dispatch(fetchSite(params.get("community")));
	}, []);

	useEffect(() => {
		dispatch(fetchNeighborhoodData(kiosk));
	}, [kiosk]);

	const handleExloreBtn = (bool) => {
		setExploreBtn(bool)
	}
	useEffect(() => {
		if (corporate  && kiosk && corporate?.nearbyCommunities?.value !== null) {
			const data = corporate?.nearbyCommunities?.value?.map((item) => {
				if (Object.values(item)?.includes(kiosk?.contentLink?.id?.toString())) {
					if (kiosk?.contentLink?.id?.toString() === item?.community_reference) {
						return item?.near_by_community_reference;
					} else {
						return item?.community_reference;
					}
				}
			});
			dispatch(fetchNearByCommunities(data?.filter((item) => item !== undefined)));
		}
	}, [corporate, kiosk]);

	return (
		<div className={`App ${customeClassName}`} style={{ overflow: "hidden" }}>
			<Header exploreBtn={exploreBtn} setExploreBtn={handleExloreBtn} />

			{transition === true ? (
				<Routes>
					<Route path="/" element={<Home setCustomeClassName={setCustomeClassName} />} />
					<Route path="/whyDrees" element={<WhyDrees setCustomeClassName={setCustomeClassName} />} />
					<Route path="/favorite" element={<Favorite setCustomeClassName={setCustomeClassName} />} />
					<Route path="/plans" element={<Plans setCustomeClassName={setCustomeClassName} />} />
					<Route
						path="/designInspiration"
						element={<DesignInspiration setCustomeClassName={setCustomeClassName} />}
					/>
					<Route path="/community" element={<CommunityLanding setCustomeClassName={setCustomeClassName} />} />
					<Route path="/planCollection" element={<PlanCollection />} />
					<Route path="/planView" element={<PlanView />} />
					<Route path="/planQuickMoveIn" element={<PlanQuickMoveIn />} />
					<Route path="/comparePlanListing" element={<ComparePlanListing />} />
					<Route path="/comparePlanDetail" element={<ComparePlanDetail />} />
					<Route path='/ourTeam' element={<OurTeam />} />
					<Route path='/promotions' element={<Promotions />} />
				</Routes>
			) : transition === undefined ? (
				<TransitionGroup component={null}>
					<CSSTransition key={location.key} classNames="slide" timeout={800}>
						<Routes location={location}>
							<Route path="/" element={<Home setCustomeClassName={setCustomeClassName} />} />
							<Route path="/favorite" element={<Favorite setCustomeClassName={setCustomeClassName} />} />
							<Route path="/whyDrees" element={<WhyDrees setCustomeClassName={setCustomeClassName} />} />
							<Route path="/plans" element={<Plans setCustomeClassName={setCustomeClassName} />} />
							<Route
								path="/designInspiration"
								element={<DesignInspiration setCustomeClassName={setCustomeClassName} />}
							/>
							<Route path="/community" element={<CommunityLanding setCustomeClassName={setCustomeClassName} />} />
							<Route path="/planCollection" element={<PlanCollection setCustomeClassName={setCustomeClassName} />} />
							<Route path="/planView" element={<PlanView setCustomeClassName={setCustomeClassName} />} />
							<Route path="/planQuickMoveIn" element={<PlanQuickMoveIn setCustomeClassName={setCustomeClassName} />} />
							<Route path="/comparePlanListing" element={<ComparePlanListing setCustomeClassName={setCustomeClassName} />} />
							<Route path="/comparePlanDetail" element={<ComparePlanDetail setCustomeClassName={setCustomeClassName} />} /> 	
							<Route path='/ourTeam' element={<OurTeam setCustomeClassName={setCustomeClassName} />} />
							<Route path='/promotions' element={<Promotions />} />
						</Routes>
					</CSSTransition>
				</TransitionGroup>
			) : (
				<TransitionGroup component={null}>
					<CSSTransition key={location.key} classNames="" timeout={800}>
						<Routes location={location}>
							<Route path="/" element={<Home setCustomeClassName={setCustomeClassName} />} />
							<Route path="/favorite" element={<Favorite setCustomeClassName={setCustomeClassName} />} />
							<Route path="/whyDrees" element={<WhyDrees setCustomeClassName={setCustomeClassName} />} />
							<Route path="/plans" element={<Plans setCustomeClassName={setCustomeClassName} />} />
							<Route
								path="/designInspiration"
								element={<DesignInspiration setCustomeClassName={setCustomeClassName} />}
							/>
							<Route path="/community" element={<CommunityLanding setCustomeClassName={setCustomeClassName} />} />
							<Route path="/planCollection" element={<PlanCollection setCustomeClassName={setCustomeClassName} />} />
							<Route path="/planView" element={<PlanView setCustomeClassName={setCustomeClassName} />} />
							<Route path="/planQuickMoveIn" element={<PlanQuickMoveIn setCustomeClassName={setCustomeClassName} />} />
							<Route path="/comparePlanListing" element={<ComparePlanListing setCustomeClassName={setCustomeClassName} />} />
							<Route path="/comparePlanDetail" element={<ComparePlanDetail setCustomeClassName={setCustomeClassName} />} />
							<Route path='/ourTeam' element={<OurTeam setCustomeClassName={setCustomeClassName} />} />
							<Route path='/promotions' element={<Promotions />} />
						</Routes>
					</CSSTransition>
				</TransitionGroup>
			)}

			<Routes>
				<Route path="/about" element={<About />} />
				<Route path="/profile" element={<Profile />} />
				<Route path='/login' element={<LoginForm setCustomeClassName={setCustomeClassName}/>} />
			</Routes>
			<div>
				{showHomeSitesImage ? (
					<CurveAnimation pageName={"Home Sites"} />
				) : (
					<Offcanvas
						show={btn}
						placement="end"
						offcanvasClassName="ccw-homesites-wrapper w-100"
						onHide={() => Promise.all([dispatch(homeSitesBtn(false)), dispatch(animation(false))])}
						headerClassName="ccw-close-wrapper">
							<iframe title="site map" width="100%" height="99%" src={kiosk?.site_map_link?.value}></iframe>
					</Offcanvas>
				)}
			</div>
			<SessionTimeOut />
		</div>
	);
}

export default App;
