/* eslint-disable import/no-anonymous-default-export */
import homeReducer from "../features/home/homeSlice";
import aboutReducer from "../features/about/aboutSlice";
import whyDreesReducer from "../features/whyDrees/whyDreesSlice";
import customConstructReducer from "../features/customAndConstruct/customConstructSlice";
import dreesSmartInnovationsReducer from "../features/smartInnovation/dreeSmartInnovationsSlice";
import ourStoryReducer from "../features/ourStory/ourStorySlice";
import testimonialsReducer from "../features/testimonials/testimonialsSlice";
import designInspirationReducer from "../features/designInspiration/designInsipirationSlice";
import plansReducer from "../features/plans/plansSlice";
import communityReducer from "../features/communityLanding/communitySlice";
import signUpReducer from "../app/component/webToLeadSlice";
import exploreReducer from "../app/component/exploreSlice"

export default {
	...homeReducer,
	...whyDreesReducer,
	...aboutReducer,
	...customConstructReducer,
	...dreesSmartInnovationsReducer,
	...ourStoryReducer,
	...testimonialsReducer,
	...designInspirationReducer,
	...plansReducer,
	...communityReducer,
	...signUpReducer,
	...exploreReducer
};
