/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from "@reduxjs/toolkit";
import {
  testimonialsPage,
  testimonialsVideoPage,
} from "../../services/whyDrees/testimonials/testimonialsServices";

const initialState = {
  loading: false,
  testimonialsPageData: {},
  error: "",
};

const testimonialsSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {
    setPageStartId: (prevState, action) => {
      const state = prevState;
      state.pageStartId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(testimonialsPage.pending, (prevState, action) => {
      const state = prevState;
      state.loading = true;
    });
    builder.addCase(testimonialsPage.fulfilled, (prevState, action) => {
      const state = prevState;
      state.loading = false;
      state.testimonialsPageData = { ...action.payload };
      state.error = "";
    });
    builder.addCase(testimonialsPage.rejected, (prevState, action) => {
      const state = prevState;
      state.loading = false;
      state.testimonialsPageData = {};
      state.error = action.error.message;
    });
    builder.addCase(testimonialsVideoPage.fulfilled, (prevState, action) => {
      const state = prevState;
      state.loading = false;
      state.testimonialsVideoPage = { ...action.payload };
      state.error = "";
    });
    builder.addCase(testimonialsVideoPage.rejected, (prevState, action) => {
      const state = prevState;
      state.loading = false;
      state.testimonialsVideoPage = {};
      state.error = action.error.message;
    });
  },
});

const { actions, reducer: testimonialsReducer } = testimonialsSlice;

export const {
  // export actions here
  setPageStartId,
} = actions;

export default { testimonialsReducer };
