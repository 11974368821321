import { React, useState } from "react";
import { assetTrim, damName } from "../../constant";

export const ViewElevations = ({ elevations, marketName }) => {
  const [elvis, setElvis] = useState(elevations[0]);
  return (
    <div className="d-flex h-100">
      {
        <div
          className="ve-left-wrapper background-properties"
          key={elvis?.asset_id}
          style={{
            backgroundImage: `url(${assetTrim(damName, elvis?.asset_value)}?scale=xl)`,
          }}>
          <span className="tag-wrapper">{`Elevation ${
            elvis?.elevation_code || elevations[0]?.elevation_code
          }`}</span>
        </div>
      }
      <div className="vg-right-wrapper">
        <div className="text-center">
          <h1 className="vg-style-heading">{marketName} Elevations</h1>
        </div>
        <ul className="d-flex vg-images-list-wrapper flex-wrap ve-images-list-wrapper">
          {elevations?.map((elev) => {
            return (
              <li className="ve-image-list" key={elev?.elevation_code}>
                <span>{`Elevation ${elev.elevation_code}`}</span>
                <div
                  className="position-relative ve-images-wrapper vg-gallery-one background-properties"
                  onClick={() => setElvis(elev)}>
                  <img
                    className={`${elvis?.asset_id === elev?.asset_id ? "active" : ""} td-testimonial-vc`}
                    src={`${assetTrim(damName, elev?.asset_value)}?scale=small`}
                    alt="elevation"
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
