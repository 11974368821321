/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from "@reduxjs/toolkit";
import { dreesSmartInnovationsPage } from "../../services/whyDrees/dreesSmartInnovation/dreeSmartInnovationsService";

const initialState = {
    loading: false,
    dreesSmartInnovationsPageData: {},
    error: "",
};

const dreesSmartInnovationsSlice = createSlice({
    name: "dreesSmartInnovations",
    initialState,
    reducers: {
        setPageStartId: (prevState, action) => {
            const state = prevState;
            state.pageStartId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(dreesSmartInnovationsPage.pending, (prevState, action) => {
            const state = prevState;
            state.loading = true;
        });
        builder.addCase(dreesSmartInnovationsPage.fulfilled, (prevState, action) => {
            const state = prevState;
            state.loading = false;
            state.dreesSmartInnovationsPageData = { ...action.payload };
            state.error = "";
        });
        builder.addCase(dreesSmartInnovationsPage.rejected, (prevState, action) => {
            const state = prevState;
            state.loading = false;
            state.dreesSmartInnovationsPageData = {};
            state.error = action.error.message;
        });
    },
});

const { actions, reducer: dreesSmartInnovationsReducer } = dreesSmartInnovationsSlice;

export const {
    // export actions here
    setPageStartId,
} = actions;

export default { dreesSmartInnovationsReducer };
