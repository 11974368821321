import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import closeIcon from "../../assets/images/close-green.svg";
import PlusIcon from "../../assets/images/plus.svg";
import RightArrowIcon from "../../assets/images/right-arrow.svg";
import ResetImg from "../../assets/images/reset-red.svg";
import {
  assetTrim,
  damName,
  ExplorePage,
  scale,
  WhyDreesDesignCollectionPage,
} from "../../constant";
import { Offcanvas } from "../../features/components/Offcanvas";
import { homeSitesBtn } from "../../features/home/homeSlice";
import { testimonialsPage } from "../../services/whyDrees/testimonials/testimonialsServices";
import "./header.scss";
import NearByCommunities from "./NearByCommunities";
import { fetchNearByCommunities } from "../../services/home/homeService";
import { fetchWhyDreesPage } from "../../services/whyDrees/whyDreesService";
import { fetchExplorePromotionsPage } from "../../services/explore/exploreService";

function Explore({ setExploreBtn, exploreBtn, setStateToggle, state }) {
  const location = useLocation();
  const [whyDreesBtn, setWhyDreesBtn] = useState(false);
  const [explorePage, setExplorePage] = useState({});
  const [communities, setCommunities] = useState();
  const dispatch = useDispatch();
  const {
    subContent,
    corporate,
    testimonialsPageKiosk,
    testimonialsListKiosk,
    whyDreesPageDataKiosk,
    nearByKiosk,
    kiosk,
    children,
    promoLinks,
  } = useSelector((state) => {
    return {
      neighbourhoodData: state.plansReducer.neighborhoodData?.neighbourhoodData,
      subContent: state.homeReducer.subContent,
      testimonialsPageKiosk:
        state.testimonialsReducer.testimonialsPageData.testimonialsPageKiosk,
      testimonialsListKiosk:
        state.testimonialsReducer.testimonialsPageData.testimonialsListKiosk,
      whyDreesPageDataKiosk:
        state.whyDreesReducer.whyDreesPageData.whyDreesPageKiosk,
      corporate: state.homeReducer.site,
      nearByKiosk: state.homeReducer.nearByCommunities?.nearByKiosk,
      kiosk: state.homeReducer.homepageData.kiosk,
      children: state.whyDreesReducer.whyDreesPageData.whyDreesChildren,
      promoLinks: state.exploreReducer.explorePageData.promoLinks,
    };
  });

  useEffect(() => {
    const explorePage = subContent?.find((item) => {
      return item?.contentType?.includes(ExplorePage);
    });
    setExplorePage(explorePage);
  }, [subContent]);

  useEffect(() => {
    if (corporate && subContent) {
      dispatch(fetchExplorePromotionsPage(subContent));
    }
  }, [corporate, subContent]);

  useEffect(() => {
    if (corporate && subContent && !whyDreesPageDataKiosk) {
      dispatch(fetchWhyDreesPage(subContent));
    }
  }, [corporate, subContent]);

  useEffect(() => {
    if (corporate && !testimonialsPageKiosk) {
      dispatch(testimonialsPage({ children, corporate }));
    }
  }, [children, corporate, testimonialsPageKiosk, dispatch]);

  // filtering active kiosk
  useEffect(() => {
    const list = nearByKiosk?.filter((item) => {
      const data = corporate?.communities?.value?.find((ele) => {
        return (
          ele?.pageReference === item?.contentLink?.id.toString() &&
          ele?.active === true
        );
      });
      return data;
    });
    setCommunities(list);
  }, [nearByKiosk]);
  //  clear Data
  const clearData = () => {
    localStorage.removeItem("plans");
    localStorage.removeItem("formDetails");
    localStorage.removeItem("quickMoveIn");
    localStorage.removeItem("data");
    localStorage.removeItem("plan");
    localStorage.removeItem("qmi");
    localStorage.removeItem("ourteam");
    localStorage.removeItem("image");
    setExploreBtn(false);
  };

  return (
    <div className="d-flex p-0 dk-exploreMenu-wrapper">
      <div className="dk-explore-left d-flex">
        <div className=" -secondary dk-explore-subleft d-flex flex-column">
          <div className="tiles tile-one rounded-10">
            <p className="pb-4">
              {
                testimonialsListKiosk?.[
                  explorePage?.explore_menu_display_testimonials?.value - 1
                ]?.message?.value
              }
            </p>
            <p>
              -{" "}
              {
                testimonialsListKiosk?.[
                  explorePage?.explore_menu_display_testimonials?.value - 1
                ]?.createdBy?.value
              }
            </p>
            <Link
              to="/whyDrees"
              state={{ page: "Testimonials", transition: false }}
            >
              <button
                className="btn dk-testi-btn w-100"
                onClick={() => setExploreBtn(false)}
              >
                View All Testimonials
              </button>
            </Link>
          </div>
          <Link
            to={
              promoLinks?.leftImage1?.contentType?.includes(
                "SalesAndPromotionPage"
              )
                ? "/promotions"
                : ""
            }
            state={{ image: "image1" }}
          >
            <div
              className="tiles tile-two"
              style={{
                backgroundImage: `url(${assetTrim(
                  damName,
                  explorePage?.explore_menu_left_side_image_1?.value,
                  scale
                )}?scale=medium)`,
              }}
              onClick={
                promoLinks?.leftImage1?.contentType?.includes(
                  "SalesAndPromotionPage"
                )
                  ? () => setExploreBtn(false)
                  : () => setExploreBtn(true)
              }
            ></div>
          </Link>
          <Link
            to={
              promoLinks?.leftImage2?.contentType?.includes(
                "SalesAndPromotionPage"
              )
                ? "/promotions"
                : ""
            }
            state={{ image: "image2" }}
          >
            <div
              className="tiles tile-three"
              style={{
                backgroundImage: `url(${assetTrim(
                  damName,
                  explorePage?.explore_menu_left_side_image_2?.value,
                  scale
                )}?scale=medium)`,
              }}
              onClick={
                promoLinks?.leftImage2?.contentType?.includes(
                  "SalesAndPromotionPage"
                )
                  ? () => setExploreBtn(false)
                  : () => setExploreBtn(true)
              }
            ></div>
          </Link>
        </div>
        <div className=" nger dk-explore-subright d-flex flex-column">
          <Link
            to={
              promoLinks?.rightImage1?.contentType?.includes(
                "SalesAndPromotionPage"
              )
                ? "/promotions"
                : ""
            }
            state={{ image: "image3" }}
          >
            <div
              className="tiles tile-four"
              style={{
                backgroundImage: `url(${assetTrim(
                  damName,
                  explorePage?.explore_menu_right_side_image_1?.value,
                  scale
                )}?scale=medium)`,
              }}
              onClick={
                promoLinks?.rightImage1?.contentType?.includes(
                  "SalesAndPromotionPage"
                )
                  ? () => setExploreBtn(false)
                  : () => setExploreBtn(true)
              }
            ></div>
          </Link>
          <Link
            to={
              promoLinks?.rightImage2?.contentType?.includes(
                "SalesAndPromotionPage"
              )
                ? "/promotions"
                : ""
            }
            state={{ image: "image4" }}
          >
            <div
              className="tiles tile-five"
              style={{
                backgroundImage: `url(${assetTrim(
                  damName,
                  explorePage?.explore_menu_right_side_image_2?.value,
                  scale
                )}?scale=medium)`,
              }}
              onClick={
                promoLinks?.rightImage2?.contentType?.includes(
                  "SalesAndPromotionPage"
                )
                  ? () => setExploreBtn(false)
                  : () => setExploreBtn(true)
              }
            ></div>
          </Link>
          <Link
            to={
              promoLinks?.rightImage3?.contentType?.includes(
                "SalesAndPromotionPage"
              )
                ? "/promotions"
                : ""
            }
            state={{ image: "image5" }}
          >
            <div
              className="tiles tile-six"
              style={{
                backgroundImage: `url(${assetTrim(
                  damName,
                  explorePage?.explore_menu_right_side_image_3?.value,
                  scale
                )}?scale=medium)`,
              }}
              onClick={
                promoLinks?.rightImage3?.contentType?.includes(
                  "SalesAndPromotionPage"
                )
                  ? () => setExploreBtn(false)
                  : () => setExploreBtn(true)
              }
            ></div>
          </Link>
        </div>
      </div>
      <div className="dk-explore-right d-flex flex-column justify-content-between">
        <button
          onClick={() => {
            setStateToggle({ visitCommunitiesNearByBtn: false });
            setExploreBtn(false);
          }}
          type="button"
          className="dk-btn-close d-flex align-items-center justify-content-end"
          aria-label="Close"
        >
          <span>Close</span>
          <img src={closeIcon} alt="Close Icon" />
        </button>
        <ul className="dk-menu-top-list p-0">
          <li>
            <Link
              to="/community"
              state={{ animation: false }}
              onClick={() => setExploreBtn(false)}
            >
              Community
            </Link>
          </li>
          <li className="position-relative">
            <Link
              className="btn"
              data-bs-toggle="collapse"
              to="/plans"
              role="button"
              state={{ animation: false }}
              aria-expanded="false"
              aria-controls="Plan Expand/collapse"
              onClick={() => setExploreBtn(false)}
            >
              Plans{" "}
            </Link>
          </li>
          <li>
            <Link
              to=""
              onClick={() => {
                dispatch(homeSitesBtn(true));
                setExploreBtn(false);
              }}
            >
              Home Sites
            </Link>
          </li>
          <li>
            <Link
              to="/designInspiration"
              state={{ animation: false }}
              onClick={() => setExploreBtn(false)}
            >
              Design Inspirations
            </Link>
          </li>
          <li className="position-relative">
            <a
              className="btn wd-menu"
              data-bs-toggle="collapse"
              role="button"
              onClick={() => setWhyDreesBtn(!whyDreesBtn)}
              aria-controls="Expand/collapse"
            >
              {!whyDreesBtn ? (
                <span>
                  <span className="black">Why Drees </span>
                  <img
                    src={PlusIcon}
                    alt="Plus icon"
                    className="btn"
                    onClick={() => setWhyDreesBtn(!whyDreesBtn)}
                  />
                </span>
              ) : (
                <span>
                  <span className="green">Why Drees </span>
                  <img
                    src={PlusIcon}
                    alt="Close icon"
                    className="btn closeIcon"
                    onClick={() => setWhyDreesBtn(!whyDreesBtn)}
                  />
                </span>
              )}
            </a>
            {whyDreesBtn && (
              <div id="dk-whydrees-submenu">
                <ul className="dk-submenu-wrapper dk-whydrees-submenu-wrapper list-unstyled">
                  <li>
                    <Link
                      to="/whyDrees"
                      state={{
                        page: "Custom + Construct",
                        transition: false,
                        animation: false,
                      }}
                      className="d-flex align-items-center"
                      onClick={() => setExploreBtn(false)}
                    >
                      <span className="dk-header-link">
                        Custom + Construct{" "}
                      </span>{" "}
                    </Link>
                  </li>
                  {(whyDreesPageDataKiosk
                    ?.why_drees_main_page_dreesmart_innovations_active?.value ||
                    whyDreesPageDataKiosk
                      ?.why_drees_main_page_dreesmart_innovations_active
                      ?.value === undefined) && (
                    <li>
                      <Link
                        to="/whyDrees"
                        state={{
                          page: "DreesSmart Innovations",
                          transition: false,
                          animation: false,
                        }}
                        className="d-flex align-items-center"
                        onClick={() => setExploreBtn(false)}
                      >
                        <span className="dk-header-link">
                          DreeSmart Innovations{" "}
                        </span>{" "}
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to="/whyDrees"
                      state={{
                        page: "Our Story",
                        transition: false,
                        animation: false,
                      }}
                      className="d-flex align-items-center"
                      onClick={() => setExploreBtn(false)}
                    >
                      <span className="dk-header-link">Our Story</span>{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/whyDrees"
                      state={{
                        page: "Testimonials",
                        transition: false,
                        animation: false,
                      }}
                      className="d-flex align-items-center"
                      onClick={() => setExploreBtn(false)}
                    >
                      <span className="dk-header-link">Testimonials</span>{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </li>
        </ul>
        <div className="dk-menu-bottom-list d-flex flex-column align-items-start justify-content-end">
          {communities && communities?.length !== 0 && (
            <a
              className="btn visit-comm-link"
              role="button"
              aria-controls="exploreMenu1"
              onClick={() => {
                setStateToggle({ visitCommunitiesNearByBtn: true });
                setExploreBtn(true);
              }}
            >
              Visit Communities Nearby
              <img
                src={RightArrowIcon}
                alt="Right Arrow"
                className="visit-comm-arrow"
              />
            </a>
          )}
          <Offcanvas
            show={state.visitCommunitiesNearByBtn}
            offcanvasClassName="near-by-communities"
            onHide={() => {
              setStateToggle({ visitCommunitiesNearByBtn: false });
              setExploreBtn(true);
            }}
            placement="start"
            headerClassName="ccw-close-wrapper closebtn"
            bodyClassName="d-flex p-0"
          >
            <NearByCommunities
              setStateToggle={setStateToggle}
              communities={communities}
              setExploreBtn={setExploreBtn}
            />
          </Offcanvas>
          <div className="d-flex align-items-center justify-content-between w-100">
            <ul className="d-flex align-items-center dk-exmenu-wrapper list-unstyled mb-0">
              <li>
                <a
                  className="d-flex align-items-center"
                  onClick={() => setStateToggle({ signUpBtn: true })}
                >
                  <span className="icon-sign-up dk-icon-wrapper">
                    <span className="visually-hidden">SignUp icon</span>
                  </span>
                  <span className="dk-header-link">Sign Up</span>
                </a>
              </li>
              <li>
                <Link
                  to="/favorite"
                  className="d-flex align-items-center"
                  state={{ animation: true }}
                  onClick={() => setExploreBtn(false)}
                >
                  <span className="icon-favorite dk-icon-wrapper">
                    <span className="visually-hidden">Favorite icon</span>
                  </span>
                  <span className="dk-header-link">Favorites</span>
                </Link>
              </li>
            </ul>
            <div className="reset-link-wrapper">
              <Link to="/" state={{ animation: true }} className="reset-link">
                <span className="dk-header-link" onClick={clearData}>
                  {/* <img src={ResetImg} alt="Reset Image" /> */}
                  Reset
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Explore;
