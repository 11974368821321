import React, { useEffect, useState } from "react";
import { Offcanvas } from "../components/Offcanvas";
import Carousel from "react-bootstrap/Carousel";
import { RightNav } from "../../app/component/RightNav";
import "./ourStory.scss";
import "../../styles/modal.scss";
import "animate.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowUp from "../../assets/images/rounded-arrowup.svg";
import ArrowDown from "../../assets/images/rounded-arrowdown.svg";
import ArrowRightGreenIcon from "../../assets/images/green-arrow-right.svg";
import ArrowLeftGreenIcon from "../../assets/images/green-arrow-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { ourStoryPage } from "../../services/whyDrees/ourStory/ourStoryServices";
import DreesOurStoryAnimation from "../../assets/images/animations/DreesOurStoryAnimation_V2.mp4";
import CloseIcon from "../../assets/images/close-white-rounded.svg";
import CloseIconGreen from "../../assets/images/close-green-rounded.svg";
import { assetTrim, damName, scale, youtube_videoId } from "../../constant";

export const OurStory = ({ changeCarousel, subContent, setTransitionTime }) => {
	const dispatch = useDispatch();
	const [state, setState] = useState({
		readOurStoryBtn: false,
		historyVideoBtn: false,
		whereWeBuildBtn: false,
	});
	const [currentSlide, setCurrentSlide] = useState(0);

	const { readOurStoryCorp, corporate, whyDreesPageDataKiosk, children } = useSelector((state) => {
		return {
			loading: state.ourStoryReducer.loading,
			error: state.ourStoryReducer.error,
			ourStoryPageDataKiosk: state.ourStoryReducer.ourStoryPageData.ourStoryPageKiosk,
			readOurStoryCorp: state.ourStoryReducer.ourStoryPageData.readOurStoryCorp,
			corporate: state.homeReducer.site,
			children: state.whyDreesReducer.whyDreesPageData.whyDreesChildren,
			whyDreesPageDataKiosk: state.whyDreesReducer.whyDreesPageData.whyDreesPageKiosk,
		};
	});

	useEffect(() => {
		if (children) {
			dispatch(ourStoryPage({ children, corporate }));
		}
	}, [children]);

	return (
		<>
			<video
				src={
					assetTrim(damName, corporate?.our_story_background_image_movie?.value, scale) || DreesOurStoryAnimation
				}
				loop={true}
				autoPlay="autoplay"
				preload="true"
				id="DreesOurStoryAnimation"></video>
			<div className="dk-MainContentWrapper cc-main-container RightMenu cc-main-container-ourstory">
				<div className="cc-wrapper os-story-wrapper">
					<p className="cc-why-drees-text text-uppercase"> {corporate?.our_story_title?.value}</p>
					<h1 className="cc-why-drees-title text-uppercase pb-40">{corporate?.our_story_heading?.value}</h1>
					<p className="cc-why-drees-desc helvetica-reg pb-40">{corporate?.our_story_description?.value}</p>
				</div>
				<div className="cc-wrapper btn-wrapper os-story-btn-wrapper">
					<button
						type="submit"
						className="btn primary-button btn-ourstory mr-25"
						onClick={() => {
							setState({ readOurStoryBtn: true });
							setCurrentSlide(0);
						}}>
						{corporate?.our_story_read_our_story_label?.value}
					</button>
					<Offcanvas
						show={state.readOurStoryBtn}
						placement="end"
						offcanvasClassName="ccw-watch-video-wrapper w-100 ccw-readourstory-wrappers RightMenu"
						onHide={() => {
							setState({ readOurStoryBtn: false });
						}}>
						{/* <div> */}
							<Carousel interval={null} indicators={false} activeIndex={currentSlide} controls={false}>
								{readOurStoryCorp?.map((item, index) => {
									return (
										<Carousel.Item key={item?.contentLink?.guidValue}>
											<div class="cc-main-container ourstory-main-container RightMenu">
												<div
													class="drees-ourstory-wrapper h-100 background-properties"
													style={{
														backgroundImage: `url(${assetTrim(
															damName,
															item?.read_our_story_background_image?.value,
															scale
														)}?scale=xl)`,
													}}>
													<h1
														class={`ros-ourstory-title text-uppercase ${
															item?.contentType?.includes("ReadOurStoryWhiteBlock") ? "black-color" : "white-color"
														}`}>
														{item?.read_our_story_heading?.value}{" "}
														<span class="text-green">{item?.read_our_story_subheading?.value}</span>{" "}
													</h1>
													<div
														className={`cpd-close-btn btnColseReadStory position-absolute ${
															item?.contentType?.includes("ReadOurStoryWhiteBlock") ? "black-color" : "white-color"
														}`}
														onClick={() => setState({ readOurStoryBtn: false })}>
														<img className="white" src={CloseIcon} alt="Close icon" />
														<img className="green" src={CloseIconGreen} alt="Close icon" />
													</div>
													<div class="carousel-inner d-flex align-items-center ros-carousel-inner">
														<div class="carousel-item active ">
															<div id="demo" class="carousel slide ros-carousel " data-bs-ride="carousel">
																<div
																	class="d-flex justify-content-center "
																	style={{ width: "65%", margin: "auto" }}>
																	<div
																		class="ros-card first ros-img-card background-properties animate__animated animate__fadeInUpBig"
																		style={{
																			backgroundImage: `url(${assetTrim(
																				damName,
																				item?.read_our_story_thumbnail_image?.value,
																				scale
																			)}?scale=medium)`,
																		}}></div>
																	<div class="ros-card second ros-text-card background-properties animate__animated animate__fadeInRightBig">
																		<h3 class="text-green card-subtitle">{item?.read_our_story_title?.value}</h3>
																		<p class="white-color ros-ourstory-subtitle">
																			{item?.read_our_story_subTitle?.value}
																		</p>
																		<p class="ros-ourstory-desc white-color">
																			{item?.read_our_story_description?.value}
																			<span>
																				{index === readOurStoryCorp.length - 1 && (
																					<button
																						type="submit"
																						className="btn primary-button btn-ourstory btn-backourstory"
																						onClick={() =>
																							setState({
																								readOurStoryBtn: false,
																							})
																						}>
																						Back to Our Story
																					</button>
																				)}
																			</span>
																		</p>
																	</div>
																</div>
															</div>
															{index === 0 ? (
																<div
																	className={`carousel-control-prev ${
																		item?.contentType?.includes("ReadOurStoryWhiteBlock")
																			? "black-color"
																			: "white-color"
																	}`}
																	onClick={() => {
																		setState({ readOurStoryBtn: false });
																	}}>
																	<img
																		className="white"
																		src={ArrowUp}
																		alt="Left arrow"
																		style={{ transform: "rotate(270deg)" }}
																	/>
																	<img className="black" src={ArrowLeftGreenIcon} alt="left arrow" />
																</div>
															) : (
																<div
																	className={`carousel-control-prev ${
																		item?.contentType?.includes("ReadOurStoryWhiteBlock")
																			? "black-color"
																			: "white-color"
																	}`}
																	onClick={() => setCurrentSlide(index - 1)}>
																	<img
																		className="white"
																		src={ArrowUp}
																		alt="Right arrow"
																		style={{ transform: "rotate(270deg)" }}
																	/>
																	<img className="black" src={ArrowLeftGreenIcon} alt="left arrow" />
																</div>
															)}
															{readOurStoryCorp?.length - 1 === index ? (
																""
															) : (
																<div
																	className={`carousel-control-next ${
																		item?.contentType?.includes("ReadOurStoryWhiteBlock")
																			? "black-color"
																			: "white-color"
																	}`}
																	onClick={() => setCurrentSlide(index + 1)}>
																	<img
																		className="white"
																		src={ArrowDown}
																		alt="Upward arrow"
																		style={{ transform: "rotate(270deg)" }}
																	/>
																	<img className="black" src={ArrowRightGreenIcon} alt="right arrow" />
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</Carousel.Item>
									);
								})}
							</Carousel>

							<RightNav />
						{/* </div> */}
					</Offcanvas>
					{corporate?.our_story_our_history_video?.value && (
						<button
							type="submit"
							className="btn primary-button btn-ourstory mr-25"
							onClick={() => setState({ historyVideoBtn: true })}>
							{corporate?.our_story_our_history_video_label?.value}
						</button>
					)}
					<Offcanvas
						show={state.historyVideoBtn}
						placement="end"
						offcanvasClassName="ccw-watch-video-wrapper w-100"
						onHide={() => setState({ historyVideoBtn: false })}>
						<div closeButton headerClassName="ccw-close-wrapper"></div>
						<div className="ccw-video-wrapper">
							<h1 className="ccw-heading">Drees History Video</h1>
							<div className="ccw-video-main-wrapper">
								<iframe
									title="our story history video"
									className="ccw-video"
									src={`https://www.youtube.com/embed/${youtube_videoId(
										corporate?.our_story_our_history_video?.value
									)}?autoplay=1`}
									allow="autoplay"></iframe>
							</div>
						</div>
					</Offcanvas>
					<button
						type="submit"
						className="btn primary-button btn-ourstory"
						onClick={() => setState({ whereWeBuildBtn: true })}>
						{corporate?.our_story_our_where_we_build_label?.value}
					</button>

					<Offcanvas
						show={state.whereWeBuildBtn}
						placement="end"
						offcanvasClassName="ccw-watch-video-wrapper w-100"
						onHide={() => setState({ whereWeBuildBtn: false })}>
						<div closeButton headerClassName="ccw-close-wrapper"></div>
						<div className="ccw-content-wrapper text-center">
							<img
								className="ros-map-image"
								src={`${assetTrim(damName, corporate?.our_story_where_we_build?.value, scale)}?scale=large`}
								alt="Where we build"
							/>

							<h1 className="text-uppercase ros-wherewebuild-title">Where we build</h1>
							<div className="ros-text-wrapper">
								<p className="ros-wherewebuild-text">
									We operate in <span className="ros-cities-count">10 </span>
									<br /> major metropolitan areas
								</p>
							</div>
						</div>
					</Offcanvas>
				</div>
				<div className="cc-slider-wrapper">
					<div
						className="slideArrows"
						onClick={() => {
							{
								whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === true ||
								whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === undefined
									? changeCarousel(2)
									: changeCarousel(1);
							}
							setTransitionTime(true);
						}}>
						<img src={ArrowUp} alt="Upward arrow" />
					</div>
					<p className="cc-slider-text">{corporate?.our_story_label_bottom?.value}</p>
					<div
						className="slideArrows"
						onClick={() => {
							{
								whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === true ||
								whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === undefined
									? changeCarousel(4)
									: changeCarousel(3);
							}
							setTransitionTime(true);
						}}>
						<img src={ArrowDown} alt="Downward arrow" />
					</div>
				</div>
			</div>
		</>
	);
};
