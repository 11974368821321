import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContentLoader } from "@episerver/content-delivery";
import { WhyDreesPage, WhyDreesDesignCollectionPage } from "../../constant";

const contentLoader = new ContentLoader();

export const fetchWhyDreesPage = createAsyncThunk("whydrees/fetchWhyDreesPage", async (subContent) => {
	const whyDreesPageKiosk = subContent?.find((ele) => {
		return ele?.contentType?.includes(WhyDreesPage) || ele?.contentType?.includes(WhyDreesDesignCollectionPage);
	});

	const whyDreesChildren = await contentLoader.getChildren(whyDreesPageKiosk?.contentLink?.guidValue, {
		branch: "en",
	});
	return {
		whyDreesPageKiosk,
		whyDreesChildren,
	};
}); // end
