import React from "react";
import { ReactSVG } from "react-svg";

import loaderImage from "../../../assets/images/oval.svg";

import "./loader.scss";

const Loader = () => {
    return (
        <div className="loader-wrapper">
            <ReactSVG
                src={loaderImage}
                className="loader-image-wrapper loader-image"
            />
        </div>
    );
};

export default Loader;