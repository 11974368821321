import { React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { homeSitesBtn } from "../../features/home/homeSlice";
import "./rightNav.scss";

export const RightNav = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { btn } = useSelector((state) => {
    return { btn: state?.homeReducer?.homeSitesBtn };
  });
  return (
    <div className="dk-right-nav">
      <ul className="d-flex flex-column h-100 justify-content-center r-nav-listwrapper">
        <li className="r-nav-list text-center">
          <Link to="/" className="r-nav-link">
            {" "}
            <span className="icon-Home dk-rnav-wrapper">
              <span className="visually-hidden">Home icon</span>
            </span>
            <span className="dk-nav-link proximaNova-regular">Home </span>
          </Link>
        </li>
        <li className="r-nav-list text-center">
          <Link className={`r-nav-link ${pathname === "/community" ? "active" : ""}`} to="/community">
            <span className="icon-community dk-rnav-wrapper">
              <span className="visually-hidden">Community icon</span>
            </span>
            <span className="dk-nav-link proximaNova-regular">Community </span>
          </Link>
        </li>
        <li className="r-nav-list text-center">
          <Link
            className={`r-nav-link ${
              pathname === "/plans"
                ? "active"
                : pathname === "/planCollection"
                ? "active"
                : pathname === "/planView"
                ? "active"
                : pathname === "/planQuickMoveIn"
                ? "active"
                : ""
            }`}
            to="/plans">
            <span className="icon-plans dk-rnav-wrapper">
              <span className="visually-hidden">Plans icon</span>
            </span>
            <span className="dk-nav-link proximaNova-regular">Plans </span>
          </Link>
        </li>
        <li className="r-nav-list text-center" onClick={() => dispatch(homeSitesBtn(true))}>
          <div className={`r-nav-link ${btn ? "active" : ""}`}>
            <span className="icon-home-site dk-rnav-wrapper"></span>
            <span className="visually-hidden">Home Sites icon</span>
            <span className="dk-nav-link proximaNova-regular">Home Sites </span>
          </div>
        </li>

        <li className="r-nav-list text-center">
          <Link
            className={`r-nav-link ${pathname === "/designInspiration" ? "active" : ""}`}
            to="/designInspiration">
            <span className="icon-design-inspiration dk-rnav-wrapper">
              <span className="visually-hidden">Design Inspirations icon</span>
            </span>
            <span className="dk-nav-link proximaNova-regular">
              Design <br /> Inspirations{" "}
            </span>
          </Link>
        </li>
        <li className="r-nav-list text-center">
          <Link className={`r-nav-link ${pathname === "/whyDrees" ? "active" : ""}`} to="/whyDrees">
            <span className="icon-why-drees dk-rnav-wrapper">
              <span className="visually-hidden">Why Drees icon</span>
            </span>
            <span className="dk-nav-link proximaNova-regular">Why Drees </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
