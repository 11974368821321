import { React } from "react";
import "./plans.scss";
import { RightNav } from "../../app/component/RightNav";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { assetTrim, commaSeperator, damName, scale } from "../../constant";
import Loader from "../../app/component/loader/Loader";

export const PlansLanding = (props) => {
	const { loading, error, neighbourhoodData, kiosk, plansCommunityPage } = useSelector((state) => {
		return {
			loading: state.plansReducer.loading,
			error: state.plansReducer.error,
			neighbourhoodData: state.plansReducer.neighborhoodData?.neighbourhoodData,
			plansCommunityPage: state.plansReducer.plansPageData.plansCommunityPage,
			kiosk: state.homeReducer.homepageData.kiosk,
		};
	});

	return (
		<>
			{loading && <Loader />}
			{error}
			{!loading && !error && (
				<div className="dk-MainContentWrapper">
					<section
						className="dk-plan-landing-wrapper background-properties d-flex flex-column h-100 RightMenu blackoverlayTopWave"
						style={{
							backgroundImage: `url(${assetTrim(
								damName,
								plansCommunityPage?.plan_community_background_image?.value,
								scale
							)}?scale=xxl)`,
						}}>
						<div className="wevewrapper">
							<div className="dk-plans-header text-center d-flex align-items-center">
								<h1 className="basicSans-regular font-size-40 white-color dk-plan-title text-center">
									{kiosk?.title?.value}
								</h1>
							</div>
							<div className="dk-comm-main-container d-flex">
								<div className="maxCardNumber">
									{neighbourhoodData?.map((data) => {
										return (
											<div className="card" key={data?.market_name}>
												<div className="card-body">
													<h3 className="card-title">{data?.market_name}</h3>
													<p className="card-text">
														<span>${commaSeperator(data?.price_low)}</span> -{" "}
														<span>${commaSeperator(data?.price_high)}</span>
													</p>
													{kiosk?.neighborhoods?.value?.map((ele) => {
														if (ele.neighborhoodCode === data?.code) {
															return (
																<img
																	key={ele.neighborhoodCode}
																	src={`${assetTrim(damName, ele?.panelImage, scale)}?scale=small`}
																	className="d-block card-img-top"
																	alt="Imge of Plan"
																/>
															);
														}
													})}
													<Link to="/planCollection" state={{ data: data }}>
														<button type="submit" className="primary-button dk-plans-btn ">
															Explore
														</button>
													</Link>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</section>
				</div>
			)}
			<RightNav />
		</>
	);
};
