import { createSlice } from "@reduxjs/toolkit";
import { fetchExplorePromotionsPage } from "../../services/explore/exploreService";

const initialState = {
	loading: false,
	explorePageData: {},
	error: "",
};

const exploreSlice = createSlice({
	name: "explore",
	initialState,
	reducers: {
		setPageStartId: (prevState, action) => {
			const state = prevState;
			state.pageStartId = action.payload;
		},
	},
    extraReducers: (builder) => {
        builder.addCase(fetchExplorePromotionsPage.pending, (prevState, action) => {
			const state = prevState;
			state.loading = true;
		});
		builder.addCase(fetchExplorePromotionsPage.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.explorePageData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchExplorePromotionsPage.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.explorePageData = {};
			state.error = action.error.message;
		});
    },
});

const { actions, reducer: exploreReducer } = exploreSlice;

export const {
	// export actions here
	setPageStartId,
} = actions;

export default { exploreReducer };