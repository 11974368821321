import React, { useEffect, useState } from "react";
import "./loginForm.scss";
import { Link, useLocation } from "react-router-dom";
import { postLead } from "../../services/webToLeadService";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeSite } from "../../services/home/homeService";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/logo-white.svg";
import { assetTrim, damName, scale } from "../../constant";

function LoginForm(props) {
	const { setCustomeClassName } = props;
	const location = useLocation();
	const [show, setShow] = useState(false);
	const formDetails = JSON.parse(localStorage.getItem("formDetails"));
	const [firstName, setFirstName] = useState(formDetails?.kiosk_user?.first_name || "");
	const [lastName, setLastName] = useState(formDetails?.kiosk_user?.last_name || "");
	const [email, setEmail] = useState(formDetails?.kiosk_user?.email || "");
	const [phoneNumber, setPhoneNumber] = useState(formDetails?.kiosk_user?.phone || "");
	const [zipCode, setZipCode] = useState(formDetails?.kiosk_user?.zip_code || "");

	useEffect(() => {
		setCustomeClassName("loginForm");
	}, []);

	const dispatch = useDispatch();

	const { kiosk, corporate } = useSelector((state) => {
		return {
			kiosk: state.homeReducer.homepageData.kiosk,
			corporate: state.homeReducer.site,
		};
	});

	const params = new URLSearchParams(location.search);
	const path = params.get("path");
	const data = JSON.parse(params.get("data"));
  const kioskData = JSON.parse(params.get("kioskData"))

	const handleSignUpSuccess = () => {
		setShow(true);
	};

	const handleClose = () => {
		// setShow(false);
		window.location.replace('https://www.dreeshomes.com', '_self');
	};
	
  useEffect(() => {
		if (corporate && !kiosk) {
			setCustomeClassName("");
			dispatch(fetchHomeSite(kioskData));
		}
	}, [corporate, kiosk]);

	const fields = {
		firstName: firstName,
		lastName: lastName,
		email: email,
		zipCode: zipCode,
		phoneNumber: phoneNumber,
	};

	const handleChange = (e) => {
		if (e.target.name === "firstName") {
			setFirstName(e.target.value);
		} else if (e.target.name === "lastName") {
			setLastName(e.target.value);
		} else if (e.target.name === "email") {
			setEmail(e.target.value);
		} else if (e.target.name === "phoneNumber") {
			setPhoneNumber(e.target.value);
		} else if (e.target.name === "zipCode") {
			setZipCode(e.target.value);
		}
	};

	const handleSignUpSubmit = (e) => {
		e.preventDefault();
		const formData = {
			kiosk_id: kiosk?.name,
			kiosk_user: {
				first_name: firstName,
				last_name: lastName,
				email: email,
				zip_code: zipCode,
				phone: phoneNumber,
			},
			lead_type: "contact_only",
		};
		const resetForm = () => {
			setFirstName("");
			setLastName("");
			setEmail("");
			setZipCode("");
			setPhoneNumber("");
		};
		localStorage.setItem("formDetails", JSON.stringify(formData));
		dispatch(postLead({ formData, resetForm, handleSignUpSuccess }));
	};

	const handleSendPlansAndHomesSubmit = (e) => {
		e.preventDefault();
		const formData = {
			kiosk_id: kiosk?.name,
			kiosk_user: {
				first_name: firstName,
				last_name: lastName,
				email: email,
				zip_code: zipCode,
				phone: phoneNumber,
			},
			lead_type: (data?.qmis?.length === 0 || data?.qmis === undefined) ? "plans" : (data?.plans?.length === 0 || data?.plans === undefined ) ? "qmi" : "plans_and_qmi",
			interests: {
				plans: data?.plans || [],
				qmis: data?.qmis || [],
			},
		};
		localStorage.setItem("formDetails", JSON.stringify(formData));
		const resetForm = () => {
			setFirstName("");
			setLastName("");
			setEmail("");
			setPhoneNumber("");
			setZipCode("");
		};
		dispatch(postLead({ formData, resetForm, handleSignUpSuccess }));
	};
console.log(data)
	const handleSendMeThisPlanSubmit = (e) => {
		e.preventDefault();
		const formData = {
			kiosk_id: kiosk?.name,
			kiosk_user: {
				first_name: firstName,
				last_name: lastName,
				email: email,
				zip_code: zipCode,
				phone: phoneNumber,
			},
			lead_type: "plans",
			interests: {
				plans: [
					{
						neighborhood_code: data?.neighborhood_code,
						plan_code: data?.plan_code,
					},
				],
			},
		};
		localStorage.setItem("formDetails", JSON.stringify(formData));
		const resetForm = () => {
			setFirstName("");
			setLastName("");
			setEmail("");
			setPhoneNumber("");
			setZipCode("");
		};
		dispatch(postLead({ formData, resetForm, handleSignUpSuccess }));
	};

	const handleSendMeThisHomeSubmit = (e) => {
		e.preventDefault();
		const formData = {
			kiosk_id: kiosk?.name,
			kiosk_user: {
				first_name: firstName,
				last_name: lastName,
				email: email,
				zip_code: zipCode,
				phone: phoneNumber,
			},
			lead_type: "qmi",
			interests: {
				plans: [],
				qmis: [
					{
						job_number: data?.job_number,
					},
				],
			},
		};
		const resetForm = () => {
			setFirstName("");
			setLastName("");
			setEmail("");
			setPhoneNumber("");
			setZipCode("");
		};
		localStorage.setItem("formDetails", JSON.stringify(formData));
		dispatch(postLead({ formData, resetForm, handleSignUpSuccess }));
	};

	return (
		<>
			<header>
				<div className="dk-header d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center">
						<div className="dk-logo-wrapper">
							<img
								className="dk-logo"
								src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
								alt="Drees Homes Logo"
							/>
						</div>
					</div>
					<div></div>
				</div>
			</header>

			<div className="ccw-watch-video-wrapper signupWrapper sm-form-container scan">
				<form
					className="sm-signup-form"
					onSubmit={
						path === "signup"
							? handleSignUpSubmit
							: path === "plans"
							? handleSendMeThisPlanSubmit
							: path === "qmis"
							? handleSendMeThisHomeSubmit
							: path === "favorites"
							? handleSendPlansAndHomesSubmit
							: ""
					}>
					<div className="sm-field-mainwrapper mb-50 text-start">
						<div className="sm-field-wrapper pr-10 ">
							<h1 className="text-center white-color sm-signup-heading">
								{path === "signup"
									? "Sign Up"
									: path === "favorites"
									? "Send your saved items to yourself"
									: path === "plans"
									? `Send Me ${data?.market_name} Plan`
									: path === "qmis"
									? `Send Me ${data?.market_name}`
									: ""}
							</h1>
							{path === "favorites" ? (
								<p className="login-selected">
									You have selected {data?.plans?.length} Plans and {data?.qmis?.length ? data?.qmis?.length : 0} QMI
								</p>
							) : path === "signup" ? (
								<p className="text-center white-color dk-signup-desc login-selected">
									Create an account to stay up-to-date with all things Drees!
								</p>
							) : path === "plans" ? (
								<p className="text-center white-color dk-signup-desc login-selected">
									Like this plan? Send it to yourself for future reference
								</p>
							) : path === "qmis" ? (
								<p className="text-center white-color dk-signup-desc login-selected">
									Like this home? Send it to yourself for future reference
								</p>
							) : (
								""
							)}
							<label htmlFor="first-name" className="form-label">
								First Name*
							</label>
							<input
								type="text"
								className="form-control"
								id="first-name"
								value={fields.firstName}
								name="firstName"
								placeholder="Enter First Name"
								onChange={handleChange}
								required
								autoComplete="off"
							/>
						</div>
						<div className="sm-field-wrapper pr-10">
							<label htmlFor="last-name" className="form-label">
								Last Name*
							</label>
							<input
								type="text"
								className="form-control"
								id="last-name"
								value={fields.lastName}
								name="lastName"
								placeholder="Enter Last Name"
								onChange={handleChange}
								required
								autoComplete="off"
							/>
						</div>
						<div className="sm-field-wrapper pr-10">
							<label htmlFor="email" className="form-label">
								Email address*
							</label>
							<input
								type="email"
								className="form-control"
								id="email"
								value={fields.email}
								name="email"
								placeholder="Enter Email address"
								onChange={handleChange}
								required
								autoComplete="off"
							/>
						</div>
						<div className="sm-field-wrapper">
							<label htmlFor="zip" className="form-label">
								Zip Code*
							</label>
							<input
								type="text"
								className="form-control"
								id="Zip"
								placeholder="Enter Zip Code"
								value={fields.zipCode}
								name="zipCode"
								onChange={handleChange}
								required
								autoComplete="off"
								maxLength={5}
							/>
						</div>
						<div className="sm-field-wrapper">
							<label htmlFor="ph-number" className="form-label">
								Phone Number
							</label>
							<input
								type="text"
								className="form-control"
								id="ph-number"
								placeholder="Enter Phone Number"
								value={fields.phoneNumber}
								name="phoneNumber"
								onChange={handleChange}
								autoComplete="off"
								maxLength={10}
							/>
						</div>
					</div>
					<div className="sm-field-wrapper">
						<button type="submit" className="btn primary-button">
							{path === "signup" ? "Submit" : "Send it!"}
						</button>
						{/* {(lead !== 'plan' && lead !== 'qmi' && lead !== 'signUp')  && <p className="selected">You have selected {plansData?.length} Plans and {quickMoveInData?.length} QMI</p>} */}
					</div>
				</form>
				<Modal
					show={show}
					onHide={handleClose}
					className="Modal-popover modal-Signup d-flex align-items-center justify-content-center barcode-popup">
					<Modal.Header closeButton className="ccw-close-wrapper"></Modal.Header>
					<Modal.Body>
						<p style={{ fontSize: "25px", textAlign: "center" }}>
							{path === "signup" ? corporate?.signup_pop_up_title?.value : corporate?.pop_up_reset_title?.value}
						</p>
					</Modal.Body>
				</Modal>
			</div>
		</>
	);
}

export default LoginForm;
