import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import whiteArrow from "../../../assets/images/next-arrow-white.svg";
import { assetTrim, damName, defaultImage } from "../../../constant";

export const PlanViewAssets = ({ details }) => {
	const prevIcon = () => {
		return (
			<button
				className="carousel-control-prev"
				type="button"
				data-bs-target="#demo"
				data-bs-slide="prev"
				style={{ transform: "rotate(180deg)" }}>
				<img src={whiteArrow} alt="Left Icon" />
			</button>
		);
	};

	const nextIcon = () => {
		return (
			<button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
				<img src={whiteArrow} alt="Right Icon" />
			</button>
		);
	};

	return (
		<div className="pb-35">
			{details?.assets?.filter((item) => (item?.content_type || item?.content_type_name) === "elvis")?.length ===
			1 ? (
				<div className="position-relative">
					{details?.target_date && <p className="viewPlan-Availability">{details?.target_date}</p>}
					<div
						className="card-img-top dk-community-image qmi-explore-image"
						style={{
							backgroundImage: `url(${assetTrim(damName, details?.assets?.[0]?.asset_value)}?scale=large)`,
						}}></div>
					{details?.special_message && <p className="viewPlan-AvailMessage">{details?.special_message}</p>}
				</div>
			) : details?.assets?.filter((item) => (item?.content_type || item?.content_type_name) === "elvis")
					?.length === 0 ? (
				<div className="position-relative">
					{details?.target_date && <p className="viewPlan-Availability">{details?.target_date}</p>}
					<div
						className="card-img-top dk-community-image qmi-explore-image"
						style={{
							backgroundImage: `url(${defaultImage})`,
						}}></div>
				</div>
			) : (
				<Carousel interval={null} indicators={false} prevIcon={prevIcon()} nextIcon={nextIcon()}>
					{details?.assets?.map((asset) => {
						if (asset?.content_type_name === "elvis" || asset?.content_type === "elvis") {
							return (
								<Carousel.Item key={asset?.content_id}>
									<div className="position-relative">
										{details?.target_date && <p className="viewPlan-Availability">{details?.target_date}</p>}
										<div
											className="card-img-top dk-community-image qmi-explore-image"
											style={{
												backgroundImage: `url(${assetTrim(damName, asset?.asset_value)}?scale=large)`,
											}}></div>
										{details?.special_message && (
											<p className="viewPlan-AvailMessage">{details?.special_message}</p>
										)}
									</div>
								</Carousel.Item>
							);
						}
					})}
				</Carousel>
			)}
		</div>
	);
};
