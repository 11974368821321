import { ContentLoader } from "@episerver/content-delivery";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { PlanCommunityPage, PlanNeighborhoodPage, PlanQMINeighborhoodPage } from "../../constant";

const { REACT_APP_MDW_API_BASE_URL } = process.env;

const contentLoader = new ContentLoader();

export const fetchPlansPage = createAsyncThunk(
	"plans/fetchPlansPage",
	async ({ subContent, kiosk }, { rejectWithValue, dispatch }) => {
		const plansCommunityPage = subContent.find((ele) => {
			return ele?.contentType?.includes(PlanCommunityPage);
		});

		const plansCommunityChildren = await contentLoader.getChildren(plansCommunityPage?.contentLink?.guidValue, {
			branch: "en",
		});

		const plansQmiPage = plansCommunityChildren.find((ele) => {
			return ele?.contentType?.includes(PlanQMINeighborhoodPage);
		});

		const plansCollectionsPage = plansCommunityChildren.find((ele) => {
			return ele?.contentType?.includes(PlanNeighborhoodPage);
		});

		return { plansCommunityPage, plansQmiPage, plansCollectionsPage };
	}
);

export const fetchNeighborhoodData = createAsyncThunk(
	"plans/fetchNeighborhoodData",
	async (kiosk, { rejectWithValue }) => {
		const neighbourhood = await Promise.all(
			kiosk?.neighborhoods?.value?.map(async (code) => {
				const codes = await axios
					.get(`${REACT_APP_MDW_API_BASE_URL}/neighborhood/details?codes=${code?.neighborhoodCode}`)
					.then((res) => {
						return res.data?.data?.[0];
					})
					.catch((err) => {
						throw rejectWithValue(err);
					});
				return codes;
			})
		);
		const neighbourhoodData = neighbourhood.filter((ele) => {
			return ele !== undefined;
		});
		return { neighbourhoodData };
	}
);
export const fetchPlansDetails = createAsyncThunk(
	"plans/fetchPlansDetails",
	async ({ plan_code, neighborhood_code }, { rejectWithValue }) => {
		const details = await axios
			.get(
				`${REACT_APP_MDW_API_BASE_URL}/neighborhood/plan/detail?plan_code=${plan_code}&neighborhood_code=${neighborhood_code}`
			)
			.then((res) => {
				return res.data.data;
			})
			.catch((err) => {
				throw rejectWithValue(err);
			});
		return details;
	}
);

export const fetchQmiDetails = createAsyncThunk("plans/fetchQmiDetails", async (job, { rejectWithValue }) => {
	const details = await axios
		.get(`${REACT_APP_MDW_API_BASE_URL}/qmi/detail?job=${job}`)
		.then((res) => {
			return res.data?.data;
		})
		.catch((err) => {
			throw rejectWithValue(err);
		});
	return details;
});

export const fetchSelectedPlans = createAsyncThunk(
	"plans/fetchSelectedPlans",
	async (selectedPlans, { rejectWithValue }) => {
		const plans = await Promise.all(
			selectedPlans?.map(async (ele) => {
				const details = await axios
					.get(
						`${REACT_APP_MDW_API_BASE_URL}/neighborhood/plan/detail?plan_code=${ele.plan_code}&neighborhood_code=${ele.neighborhood_code}`
					)
					.then((res) => {
						return res.data.data;
					})
					.catch((err) => {
						throw rejectWithValue(err);
					});
				return details;
			})
		);
		return plans;
	}
);

export const fetchAllPlans = createAsyncThunk("plans/fetchAllPlans", async (allPlans, { rejectWithValue }) => {
	const plans = await Promise.all(
		allPlans?.map(async (ele) => {
			const details = await axios
				.get(
					`${REACT_APP_MDW_API_BASE_URL}/neighborhood/plan/detail?plan_code=${ele.plan_code}&neighborhood_code=${ele.neighborhood_code}`
				)
				.then((res) => {
					return res.data.data;
				})
				.catch((err) => {
					throw rejectWithValue(err);
				});
			return details;
		})
	);
	return plans;
});
