import { useEffect } from "react";
import { React } from "react";
import { Alert } from "react-bootstrap";
import CloseIcon from "../../assets/images/close-green-rounded.svg";
import { assetTrim, capitalizeEachWord, commaSeperator, damName, defaultImage } from "../../constant";
import "./comparePlanListing.scss";

export const ComparePlanListing = ({
	setState,
	plan,
	comparePlans,
	selected,
	handleCompare,
	checkedPlans,
	setCheckedPlans,
	anotherPlan,
	setSelectedSlide,
}) => {
	useEffect(() => {
		if (anotherPlan) {
			setCheckedPlans(0);
		}
	}, [anotherPlan]);

	return (
		<section className="cpl-main-container ">
			<div className="cpl-wrapper">
				{anotherPlan ? (
					<button
						className="ccw-close-btn position-relative cpl-close-btn-wrapper w-100"
						onClick={() => {
							setState({ comparePlanBtn: false, comparePlansDetailsBtn: true });
							setCheckedPlans(0);
						}}>
						<img className="cpl-close-btn position-absolute" src={CloseIcon} alt="Close icon" />
					</button>
				) : (
					<button
						className="ccw-close-btn position-relative cpl-close-btn-wrapper w-100"
						onClick={() => {
							setState({ comparePlanBtn: false });
							setCheckedPlans(0);
						}}>
						<img className="cpl-close-btn position-absolute" src={CloseIcon} alt="Close icon" />
					</button>
				)}
				<div className="planListWrapper">
					<div className="pb-25">
						<h1 className="cpl-heading black-color">{capitalizeEachWord(plan?.neighborhood_name)}</h1>
						<h2 className="cpl-subheading black-color">
							{anotherPlan ? "Select other plans to compare." : "Select up to two plans to compare."}
						</h2>
						<div className="text-center pl-btn-wrapper">
							<div className="bordered-btn">
								<button
									disabled={checkedPlans < 1}
									className="btn vpl-plan-btn primary-button btn-compareplan"
									onClick={() => {
										handleCompare();
										setState({ comparePlansDetailsBtn: true });
									}}>
									Compare Plans
								</button>
							</div>
							<br />
							{anotherPlan
								? checkedPlans > 1 && (
										<Alert variant="danger" className="bordered-btn w-50">
											<Alert.Heading>Only one plan is allowed for selection</Alert.Heading>
										</Alert>
								  )
								: checkedPlans > 2 && (
										<Alert variant="danger" className="bordered-btn w-50">
											<Alert.Heading>A maximum of two plans are allowed for selection</Alert.Heading>
										</Alert>
								  )}
						</div>
					</div>
					<div className="d-flex justify-content-start align-items-start flex-wrap cpl-card-container">
						{comparePlans?.map((ele, index) => {
							return (
								<div className="card cpl-card " key={ele?.plan_code}>
									<div className="card-body d-flex flex-column">
										<h3 className="cpl-card-title card-title d-flex align-items-start justify-content-between">
											<span className="cpl-plan-name">{ele?.market_name}</span>
											<div className="form-check cpl-check d-flex align-items-center">
												<input
													className="compare form-check-input cpl-check-input"
													type="checkbox"
													value={ele?.plan_code}
													onClick={() => selected(index)}
													id={ele?.plan_code}
												/>
												<label className="form-check-label label-compare" htmlFor={ele?.plan_code}>
													Compare
												</label>
											</div>
										</h3>
										<p className="card-text text-start text-green">
											{ele?.display_price_low === ele?.display_price_high ? (
												<span>${commaSeperator(ele?.display_price_low)}</span>
											) : (
												<>
													<span>${commaSeperator(ele?.display_price_low)}</span> -{" "}
													<span>${commaSeperator(ele?.display_price_high)}</span>
												</>
											)}
										</p>

										{ele?.assets?.length > 0 ? (
											<img
												src={`${assetTrim(damName, ele?.assets[0]?.asset_value)}?scale=small`}
												className="card-img-top "
												alt="Imge of Community"
											/>
										) : (
											<img src={defaultImage} className="card-img-top " alt="Imge of Community" />
										)}
									</div>
								</div>
							);
						})}
					</div>
					<div className="text-center pl-btn-wrapper">
						<div className="bordered-btn">
							<button
								disabled={checkedPlans < 1}
								className="btn vpl-plan-btn primary-button btn-compareplan"
								onClick={() => {
									handleCompare();
									setState({ comparePlansDetailsBtn: true });
								}}>
								Compare Plans
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
