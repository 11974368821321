import React, { useState, useEffect } from "react";
import "./dreesSmartInnovation.scss";
import "../../styles/modal.scss";
import ArrowUp from "../../assets/images/rounded-arrowup.svg";
import ArrowDown from "../../assets/images/rounded-arrowdown.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../app/component/loader/Loader";
import { dreesSmartInnovationsPage } from "../../services/whyDrees/dreesSmartInnovation/dreeSmartInnovationsService";
import { Carousel } from "react-bootstrap";
import { Offcanvas } from "../components/Offcanvas";
import whiteArrow from "../../assets/images/next-arrow-white.svg";
import { assetTrim, damName, scale, youtube_videoId } from "../../constant";

export const DreesSmartInnovation = ({ changeCarousel, subContent, setTransitionTime }) => {
	const dispatch = useDispatch();
	const [state, setState] = useState({
		videoBtn: false,
		smartEnergyComareBtn: false,
	});

	const { loading, error, dreesSmartInnovationsPageDataKiosk, corporate, children } = useSelector((state) => {
		return {
			loading: state.dreesSmartInnovationsReducer.loading,
			error: state.dreesSmartInnovationsReducer.error,
			dreesSmartInnovationsPageDataKiosk:
				state.dreesSmartInnovationsReducer.dreesSmartInnovationsPageData.dreesSmartInnovationsPageKiosk,
			dreesSmartInnovationsEnergyCorp:
				state.dreesSmartInnovationsReducer.dreesSmartInnovationsPageData.dreesmartInnovationsEnergyCorp,
			corporate: state.homeReducer.site,
			children: state.whyDreesReducer.whyDreesPageData.whyDreesChildren,
		};
	});

	useEffect(() => {
		if (children) {
			dispatch(dreesSmartInnovationsPage({ children, corporate }));
		}
	}, [children]);

	return (
		<>
			{loading && <Loader />}
			{error}
			<div
				className="dk-MainContentWrapper cc-main-container RightMenu cc-main-container-dreesmart blackoverlay"
				style={{
					backgroundImage: `url(${assetTrim(
						damName,
						corporate?.dreesmart_innovation_background_image?.value,
						scale
					)}?scale=xxl)`,
				}}>
				<div className="cc-wrapper">
					<p className="cc-why-drees-text text-uppercase"> {corporate?.dreesmart_innovation_title?.value}</p>
					<h1 className="cc-why-drees-title text-uppercase pb-40">
						{corporate?.dreesmart_innovation_heading?.value}
					</h1>
					<p className="cc-why-drees-desc helvetica-reg pb-40">
						{corporate?.dreesmart_innovation_description?.value}
					</p>
					{corporate?.dreesmart_innovation_watch_dreeSmart_technology_video?.value && (
						<button
							type="submit"
							className="btn primary-button cc-button cc-drees-smart-btn mr-25"
							onClick={() => setState({ videoBtn: true })}>
							{corporate?.dreesmart_innovation_watch_dreeSmart_technology_video_label?.value}
						</button>
					)}
					<Offcanvas
						show={state.videoBtn}
						placement="end"
						offcanvasClassName="ccw-watch-video-wrapper w-100"
						onHide={() => setState({ videoBtn: false })}>
						<div closeButton headerClassName="ccw-close-wrapper"></div>
						<div className="ccw-video-wrapper">
							<h1 className="ccw-heading">DreeSmart Innovation Video</h1>
							<div className="ccw-video-main-wrapper">
								<iframe
									className="ccw-video"
									src={`https://www.youtube.com/embed/${youtube_videoId(
										corporate?.dreesmart_innovation_watch_dreeSmart_technology_video?.value
									)}?autoplay=1`}
									allow="autoplay"></iframe>
							</div>
						</div>
					</Offcanvas>
					<button
						type="submit"
						className="btn primary-button cc-button cc-drees-smart-btn"
						onClick={() => setState({ smartEnergyComareBtn: true })}>
						{corporate?.dreesmart_innovation_see_how_dreesmart_energy_compares_label?.value}
					</button>
					<Offcanvas
						show={state.smartEnergyComareBtn}
						placement="end"
						offcanvasClassName="ccw-watch-video-wrapper w-100 graph-popover"
						onHide={() => setState({ smartEnergyComareBtn: false })}>
						<div> 
							<Carousel fade interval={null} indicators={false} controls={true}>
								<Carousel.Item
									className="drs-energybg"
									style={{
										backgroundImage: `url(${assetTrim(
											damName,
											corporate?.see_how_dreesmart_energy_compares_background_image?.value,
											scale
										)}?scale=xl)`,
									}}>
									<img
										src={`${assetTrim(
											damName,
											dreesSmartInnovationsPageDataKiosk?.see_how_dreesmart_energy_compares_our_cost_image?.value,
											scale
										)}?scale=large`}
										alt="Our Cost"
									/>
									<div className="drs-energyImageTitle">
										{
											dreesSmartInnovationsPageDataKiosk?.see_how_dreesmart_energy_compares_how_we_score_label
												?.value
										}
									</div>
									<button
										className="carousel-control-next"
										type="button"
										data-bs-target="#demo"
										data-bs-slide="prev">
										<img src={whiteArrow} alt="Left Icon" />
									</button>
								</Carousel.Item>
								<Carousel.Item
									className="drs-energybg"
									style={{
										backgroundImage: `url(${assetTrim(
											damName,
											corporate?.see_how_dreesmart_energy_compares_background_image?.value,
											scale
										)}?scale=xl)`,
									}}>
									<img
										src={`${assetTrim(
											damName,
											dreesSmartInnovationsPageDataKiosk?.see_how_dreesmart_energy_compares_how_we_score_image
												?.value,
											scale
										)}?scale=large`}
										alt="How We Score"
									/>
									<div className="drs-energyImageTitle">
										{corporate?.see_how_dreesmart_energy_compares_our_cost_label?.value}
									</div>
									<button
										className="carousel-control-next"
										type="button"
										data-bs-target="#demo"
										data-bs-slide="next"
										style={{ transform: "rotate(180deg)" }}>
										<img src={whiteArrow} alt="Right Icon" />
									</button>
								</Carousel.Item>
							</Carousel>
						</div>
					</Offcanvas>
				</div>
				<div className="cc-slider-wrapper">
					<div
						className="slideArrows"
						onClick={() => {
							changeCarousel(1);
							setTransitionTime(true);
						}}>
						<img src={ArrowUp} alt="Upward arrow" />
					</div>
					<p className="cc-slider-text">{corporate?.dreesmart_innovation_label_bottom_right?.value}</p>

					<div
						className="slideArrows"
						onClick={() => {
							changeCarousel(3);
							setTransitionTime(true);
						}}>
						<img src={ArrowDown} alt="Downward arrow" />
					</div>
				</div>
			</div>
		</>
	);
};
