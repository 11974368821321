import React, { useState } from "react";
import _ from "lodash";
import { assetTrim, damName } from "../../../constant";
import { useEffect } from "react";

export const PlanViewFloorMap = ({ details }) => {
	const [selectedLevel, setSelectedLevel] = useState();
	const [sortedPlanLevels, setSortedPlanLevels] = useState();

	useEffect(() => {
		const sortedLevels = _.sortBy(details?.plan_levels, function (item) {
			return item.floor_level === "Main Level" ? 0 : item.floor_level === "Second Level" ? 1 : 2;
		});
		setSortedPlanLevels(sortedLevels);
	}, [details]);

	return (
		<>
			{details?.plan_levels?.length === 0 ? null : (
				<>
					<div className="planview-floormapwrapper">
						<img
							className="w-100"
							src={
								selectedLevel
									? `${assetTrim(damName, selectedLevel.src)}?scale=original`
									: `${assetTrim(damName, sortedPlanLevels?.[0]?.src)}?scale=original`
							}
							alt={selectedLevel?.floor_level}
						/>
					</div>

					{/* floor plan label */}
					<div className="d-flex justify-space-between pv-planFloorPanel">
						{_.uniqBy(sortedPlanLevels, function (item) {
							return item.floor_level;
						})?.map((item) => (
							<div
								className="form-check vp-check"
								onClick={() => {
									setSelectedLevel(item);
								}}
								key={item.id}>
								<input
									className="form-check-input vp-check-input"
									type="radio"
									id={item?.floor_level}
									readOnly
									checked={selectedLevel ? selectedLevel.id === item.id : sortedPlanLevels?.[0] === item}
								/>
								<label className="form-check-label text-capitalize" for={item?.floor_level}>
									{item?.floor_level}
								</label>
							</div>
						))}
					</div>
				</>
			)}
		</>
	);
};
