import React from "react";
import { Offcanvas as OC } from "react-bootstrap";
import PropTypes from "prop-types";

export const Offcanvas = ({
    show,
    placement,
    offcanvasClassName,
    onHide,
    headerClassName,
    closeButton,
    headerContent,
    bodyClassName,
    children,
    scroll,
    backdrop,
}) => {
    return (
        <>
            <OC show={show} placement={placement} className={offcanvasClassName} onHide={onHide} scroll={scroll} backdrop={backdrop}>
                <OC.Header closeButton={closeButton} className={headerClassName}>
                    {headerContent}
                </OC.Header>
                <OC.Body className={bodyClassName}>{children}</OC.Body>
            </OC>
        </>
    );
};

Offcanvas.propTypes = {
    show: PropTypes.bool,
    offcanvasClassName: PropTypes.string,
    onHide: PropTypes.func,
    headerClassName: PropTypes.string,
    closeButton: PropTypes.bool,
    headerContent: PropTypes.string,
    bodyClassName: PropTypes.string,
    placement: PropTypes.string,
    scroll: PropTypes.bool,
    backdrop: PropTypes.bool,
};

Offcanvas.defaultProps = {
    show: false,
    offcanvasClassName: "ccw-watch-video-wrapper w-100",
    onHide: () => {},
    headerClassName: "ccw-close-wrapper",
    closeButton: true,
    headerContent: "",
    bodyClassName: "",
    placement: "end",
    scroll: false,
    backdrop: false,
};
