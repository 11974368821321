import { React, useState } from "react";
import "./header.scss";
import LogoWhite from "../../assets/images/logo-white.svg";
import logo from "../../assets/images/logo-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { postLead } from "../../services/webToLeadService";
import { LeadForm } from "../../features/components/LeadForm";
import { assetTrim, damName, scale } from "../../constant";
import { Link, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import { fetchHomeSite } from "../../services/home/homeService";

export const SignUp = ({ setStateToggle, handleSignUpSuccess }) => {
  const location = useLocation();
  const kioskId = localStorage.getItem("kiosk");
  const formDetails = JSON.parse(localStorage.getItem("formDetails"));
  const [firstName, setFirstName] = useState(
    formDetails?.kiosk_user?.first_name || ""
  );
  const [lastName, setLastName] = useState(
    formDetails?.kiosk_user?.last_name || ""
  );
  const [email, setEmail] = useState(formDetails?.kiosk_user?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(
    formDetails?.kiosk_user?.phone || ""
  );
  const [zipCode, setZipCode] = useState(
    formDetails?.kiosk_user?.zip_code || ""
  );

  const dispatch = useDispatch();

  const { kiosk } = useSelector((state) => {
    return {
      kiosk: state.homeReducer.homepageData.kiosk,
    };
  });

  const fields = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    zipCode: zipCode,
    phoneNumber: phoneNumber,
  };

  const handleSignUpChange = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "phoneNumber") {
      setPhoneNumber(e.target.value);
    } else if (e.target.name === "zipCode") {
      setZipCode(e.target.value);
    }
  };

  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    const formData = {
      kiosk_id: kiosk?.name,
      kiosk_user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        zip_code: zipCode,
        phone: phoneNumber,
      },
      lead_type: "contact_only",
    };
    const resetForm = () => {
      setFirstName("");
      setLastName("");
      setEmail("");
      setZipCode("");
      setPhoneNumber("");
    };
    localStorage.setItem("formDetails", JSON.stringify(formData));
    dispatch(
      postLead({ formData, resetForm, setStateToggle, handleSignUpSuccess })
    );
  };

  return (
    <>
      <section className="dk-signup-section">
        <div className="dk-signup-logo-wrapper text-start">
          {kiosk?.contentLink?.id?.toString() !== kioskId ? (
            <Link to="/">
              <img
                className="dk-logo-small"
                src={assetTrim(damName, kiosk?.logo?.value, scale) || LogoWhite}
                alt="Drees Homes Logo"
                onClick={() => {
                  dispatch(fetchHomeSite(kioskId));
                  setStateToggle({ signUpBtn: false });
                }}
              />
            </Link>
          ) : location?.pathname === "/" ? (
            <img
              className="dk-logo-small"
              src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
              alt="Drees Homes Logo"
              onClick={() => {
                setStateToggle({ signUpBtn: false });
              }}
            />
          ) : (
            <Link to="/">
              <img
                className="dk-logo-small"
                src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
                alt="Drees Homes Logo"
                onClick={() => setStateToggle({ signUpBtn: false })}
              />
            </Link>
          )}
        </div>
        <div className="pb-2 text-end">
          <div className="dk-form-container">
            <div className="text-center mt-10 ">
              <h1 className="white-color dk-signup-heading">Sign Up</h1>
              <p className="white-color dk-signup-desc">
                Create an account to stay up-to-date with all things Drees!
              </p>
            </div>
            <LeadForm
              handleChange={handleSignUpChange}
              handleSubmit={handleSignUpSubmit}
              fields={fields}
              lead="signUp"
            />
          </div>
        </div>

        <div className="dk-form-container">
          <div className="text-center mt-10 ">
            <h1 className="white-color dk-signup-headingor"> Or </h1>
            <p className="white-color dk-signup-desc-qr">
              Scan the QR code to fill out your information
            </p>
            <QRCode
              value={`${window.location.origin}/login?path=signup&kioskData=${kiosk?.contentLink?.id}`}
              size={200}
              bgColor="black"
              fgColor="white"
            />
          </div>
        </div>
      </section>
    </>
  );
};
