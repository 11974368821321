import { createSlice } from "@reduxjs/toolkit";
import { postLead } from "../../services/webToLeadService";

const initialState = {
    loading: false,
    error: "",
};

const signUpSlice = createSlice({
    name: "signUp",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postLead.pending, (prevState, action) => {
            const state = prevState;
            state.loading = true;
        });
        builder.addCase(postLead.fulfilled, (prevState, action) => {
            const state = prevState;
            state.loading = false;
            state.postLead = { ...action.payload };
            state.error = "";
        });
        builder.addCase(postLead.rejected, (prevState, action) => {
            const state = prevState;
            state.loading = false;
            state.postLead = {};
            state.error = action.error.message;
        });
    },
});

const { actions, reducer: signUpReducer } = signUpSlice;

export default { signUpReducer };
