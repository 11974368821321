import { React, useState } from "react";
import "../../app/component/header.scss";
import { Link, useLocation } from "react-router-dom";
import LogoWhite from "../../assets/images/logo-white.svg";
import logo from "../../assets/images/logo-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { postLead } from "../../services/webToLeadService";
import { LeadForm } from "../../features/components/LeadForm";
import { assetTrim, damName, scale } from "../../constant";
import QRCode from "react-qr-code";
import { fetchHomeSite } from "../../services/home/homeService";

export const SendPlansAndHomes = ({
  setStateToggle,
  handleSignUpSuccess,
  plansData,
  quickMoveInData,
}) => {
  const formDetails = JSON.parse(localStorage.getItem("formDetails"));
  const kioskId = localStorage.getItem("kiosk");
  const [firstName, setFirstName] = useState(
    formDetails?.kiosk_user?.first_name || ""
  );
  const [lastName, setLastName] = useState(
    formDetails?.kiosk_user?.last_name || ""
  );
  const [email, setEmail] = useState(formDetails?.kiosk_user?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(
    formDetails?.kiosk_user?.phone || ""
  );
  const [zipCode, setZipCode] = useState(
    formDetails?.kiosk_user?.zip_code || ""
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const { kiosk } = useSelector((state) => {
    return {
      kiosk: state.homeReducer.homepageData.kiosk,
    };
  });

  const fields = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    zipCode: zipCode,
    phoneNumber: phoneNumber,
  };

  const handleSendPlansAndHomes = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "phoneNumber") {
      setPhoneNumber(e.target.value);
    } else if (e.target.name === "zipCode") {
      setZipCode(e.target.value);
    }
  };

  const favPlans = plansData?.map((ele) => {
    return {
      neighborhood_code: ele?.neighborhood_code,
      plan_code: ele?.plan_code,
    };
  });
  const favQmis = quickMoveInData?.map((ele) => {
    return { job_number: ele?.job_number };
  });

  const handleSendPlansAndHomesSubmit = (e) => {
    e.preventDefault();
    const formData = {
      kiosk_id: kiosk?.name,
      kiosk_user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        zip_code: zipCode,
        phone: phoneNumber,
      },
      lead_type:
        favQmis === undefined || favQmis.length === 0
          ? "plans"
          : favPlans === undefined || favPlans?.length === 0
          ? "qmi"
          : "plans_and_qmi",
      interests: {
        plans: favPlans || [],
        qmis: favQmis || [],
      },
    };

    localStorage.setItem("formDetails", JSON.stringify(formData));
    const resetForm = () => {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setZipCode("");
    };
    dispatch(
      postLead({ formData, resetForm, setStateToggle, handleSignUpSuccess })
    );
  };

  const favoritesData = JSON.stringify({
    plans: favPlans,
    qmis: favQmis,
  });

  return (
    <>
      <section className="dk-signup-section">
        <div className="dk-signup-logo-wrapper text-start">
          {kiosk?.contentLink?.id?.toString() !== kioskId ? (
            <Link to="/">
              <img
                className="dk-logo-small"
                src={assetTrim(damName, kiosk?.logo?.value, scale) || LogoWhite}
                alt="Drees Homes Logo"
                onClick={() => {
                  dispatch(fetchHomeSite(kioskId));
                  setStateToggle({ sendMyPlanHomeBtn: false });
                }}
              />
            </Link>
          ) : location?.pathname === "/" ? (
            <img
              className="dk-logo-small"
              src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
              alt="Drees Homes Logo"
            />
          ) : (
            <Link to="/">
              <img
                className="dk-logo-small"
                src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
                alt="Drees Homes Logo"
                onClick={() => setStateToggle({ sendMyPlanHomeBtn: false })}
              />
            </Link>
          )}
        </div>
        <div className="pb-2 text-end">
          <div className="dk-form-container">
            <div className="text-center mt-10 ">
              <h1 className="white-color dk-signup-heading">
                Send your saved items to yourself
              </h1>
              <p className="white-color dk-signup-desc-qr">&nbsp;</p>
            </div>
            <LeadForm
              handleChange={handleSendPlansAndHomes}
              handleSubmit={handleSendPlansAndHomesSubmit}
              fields={fields}
              lead={true}
              plansData={plansData}
              quickMoveInData={quickMoveInData}
            />
          </div>
        </div>

        <div className="dk-form-container">
          <div className="text-center mt-10 ">
            <h1 className="white-color dk-signup-headingor"> Or </h1>
            <p className="white-color dk-signup-desc-qr">
              Scan the QR code to fill out your information
            </p>
            <div>
              <QRCode
                value={`${window.location.origin}/login?path=favorites&kioskData=${kiosk?.contentLink?.id}&data=${favoritesData}`}
                size={200}
                bgColor="black"
                fgColor="white"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
