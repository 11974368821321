import React from "react";
import { Carousel, Offcanvas } from "react-bootstrap";
import GreenArrowLeft from "../../assets/images/green-arrow-left.svg";
import GreenArrowRight from "../../assets/images/green-arrow-right.svg";
import ViewGallery from "./ViewGallery";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchViewGalleryPage } from "../../services/designInspiration/designInspirationServices";
import { assetTrim, damName, scale } from "../../constant";

function FindYourStyles({ corporate, setState, viewGallery, setViewGallery, findYourStyles }) {
	const [styleName, setStyleName] = useState();
	const [styles, setStyles] = useState({});
	const dispatch = useDispatch();

	const { kiosk, viewGalleryPage } = useSelector((state) => {
		return {
			kiosk: state.homeReducer.homepageData.kiosk,
			viewGalleryPage: state.designInspirationReducer.viewGalleryPageData.categories,
		};
	});

	useEffect(() => {
		if (corporate && !viewGalleryPage) {
			const neighborhoodCode = kiosk?.neighborhoods?.value?.[0]?.neighborhoodCode;
			dispatch(fetchViewGalleryPage(neighborhoodCode));
		}
	}, [corporate, kiosk, viewGalleryPage]);

	useEffect(() => {
		const styleSystemName = findYourStyles?.map((item) => {
			const style = viewGalleryPage?.find((ele) => {
				if (item.contentType?.includes("FindYourStyleBlackPage")) {
					return item?.find_your_style_black_system_name?.value.includes(ele.name);
				} else {
					return item?.find_your_style_white_system_name?.value.includes(ele.name);
				}
			});
			return style;
		});
		setStyleName(styleSystemName);
	}, [findYourStyles, viewGalleryPage]);

	const prevIcon = () => {
		return (
			<button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
				<img src={GreenArrowLeft} alt="Left Icon" />
			</button>
		);
	};

	const nextIcon = () => {
		return (
			<button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
				<img src={GreenArrowRight} alt="Right Icon" />
			</button>
		);
	};

	return (
		<div>
			{" "}
			<Carousel
				interval={null}
				indicators={false}
				prevIcon={prevIcon()}
				nextIcon={nextIcon()}
				className="drs-contem-sliderwrapper">
				{findYourStyles?.map((slide, index) => {
					if (slide?.contentType.includes("FindYourStyleBlackPage")) {
						return (
							<Carousel.Item className="fs-bg-black" key={slide?.contentLink?.guidValue}>
								<Offcanvas.Header closeButton className="ccw-close-wrapper"></Offcanvas.Header>
								<div
									className="background-properties fs-contem-background h-100 d-flex align-items-center flex-wrap justify-content-between p-20 "
									style={{
										backgroundImage: `url(${assetTrim(
											damName,
											slide?.find_your_style_black_background_image?.value,
											scale
										)}?scale=xxl)`,
									}}>
									<div className="fs-contem-image-container d-flex justify-content-center">
										<div
											className="fs-contem-style-wrapper background-properties fs-contem-text"
											style={{
												backgroundImage: `url(${assetTrim(
													damName,
													slide?.find_your_style_black_system_name_label?.value,
													scale
												)}?scale=small)`,
											}}></div>

										<div
											className="fs-contem-style-wrapper background-properties fs-contem-image2  position-relative"
											style={{
												backgroundImage: `url(${assetTrim(
													damName,
													slide?.find_your_style_black_image_middle?.value,
													scale
												)}?scale=large)`,
											}}>
											<div
												className="fs-contem-style-wrapper background-properties fs-contem-image1"
												style={{
													backgroundImage: `url(${assetTrim(
														damName,
														slide?.find_your_style_black_image_left?.value,
														scale
													)}?scale=small)`,
												}}></div>
											<div
												className="fs-contem-style-wrapper background-properties fs-contem-image3"
												style={{
													backgroundImage: `url(${assetTrim(
														damName,
														slide?.find_your_style_black_image_right?.value,
														scale
													)}?scale=small)`,
												}}></div>
										</div>
									</div>
									<div className="d-flex p-20 justify-content-between position-relative fs-desc-bot-wrapper align-self-end">
										<p className="fs-desc-text">{slide?.find_your_style_black_description?.value}</p>
										<div>
											{viewGalleryPage && (
												<div
													className="d-flex align-items-center fs-gallery-wrapper"
													onClick={() => {
														setStyles(styleName[index]);
														setViewGallery(true);
													}}>
													<img
														className="fs-gallery-icon"
														src={`${assetTrim(
															damName,
															corporate?.find_your_style_view_gallery_icon?.value,
															scale
														)}?scale=small`}
														alt="View gallery icon"
													/>
													<span className="fs-gallery-text">
														{corporate?.find_your_style_view_gallery_label?.value}
													</span>
												</div>
											)}

											<Offcanvas
												show={viewGallery}
												placement="bottom"
												className="ccw-watch-video-wrapper h-100 w-100 p-0 border-0"
												backdrop={false}
												onHide={() => setViewGallery(false)}>
												<Offcanvas.Body className="p-0 fs-bg-black">
													<ViewGallery
														setViewGallery={setViewGallery}
														styleName={styles}
														text={slide?.find_your_style_black_system_name_label?.value}
													/>
												</Offcanvas.Body>
											</Offcanvas>
										</div>
									</div>
								</div>
							</Carousel.Item>
						);
					} else {
						return (
							<Carousel.Item className="fs-bg-white" key={slide?.contentLink?.guidValue}>
								<Offcanvas.Header closeButton className="ccw-close-wrapper"></Offcanvas.Header>
								<div
									className="background-properties fs-contem-background h-100 p-20 d-flex align-items-center flex-wrap justify-content-between"
									style={{
										backgroundImage: `url(${assetTrim(
											damName,
											slide?.find_your_style_white_background_image?.value,
											scale
										)}?scale=small)`,
									}}>
									<div className="fs-contem-image-container d-flex justify-content-center">
										<div
											className="fs-contem-style-wrapper background-properties position-relative fs-bohemain-image2"
											style={{
												backgroundImage: `url(${assetTrim(
													damName,
													slide?.find_your_style_white_image_middle?.value,
													scale
												)}?scale=large)`,
											}}>
											<div
												className="fs-contem-style-wrapper background-properties  fs-bohemain-image1 "
												style={{
													backgroundImage: `url(${assetTrim(
														damName,
														slide?.find_your_style_white_image_left?.value,
														scale
													)}?scale=small)`,
												}}></div>
											<div
												className="fs-contem-style-wrapper background-properties fs-bohemain-image3"
												style={{
													backgroundImage: `url(${assetTrim(
														damName,
														slide?.find_your_style_white_image_right?.value,
														scale
													)}?scale=small)`,
												}}></div>
											<div
												className="fs-contem-style-wrapper background-properties fs-bohemain-text"
												style={{
													backgroundImage: `url(${assetTrim(
														damName,
														slide?.find_your_style_white_system_name_label?.value,
														scale
													)}?scale=small)`,
												}}></div>
										</div>
									</div>
									<div className="d-flex p-20 justify-content-between position-relative fs-desc-bot-wrapper align-self-end">
										<p className="fs-desc-text fs-boh-desc-text">
											{slide?.find_your_style_white_description?.value}
										</p>
										<div>
											<div
												className="d-flex align-items-center fs-gallery-wrapper"
												onClick={() => {
													setStyles(styleName[index]);
													setViewGallery(true);
												}}>
												<img
													className="fs-gallery-icon"
													src={`${assetTrim(
														damName,
														corporate?.find_your_style_view_gallery_icon?.value,
														scale
													)}?scale=small`}
													alt="View gallery icon"
												/>
												<span className="fs-gallery-text fs-boh-gallery-text">
													{corporate?.find_your_style_view_gallery_label?.value}
												</span>
											</div>
										</div>
									</div>
								</div>
								<Offcanvas
									show={viewGallery}
									placement="bottom"
									backdrop={false}
									className="ccw-watch-video-wrapper h-100 w-100 p-0 border-0"
									onHide={() => setState({ findYourStylesBtn: false })}>
									<Offcanvas.Header closeButton className="ccw-close-wrapper vg-gallery-close">
										<div
											className="ccw-close-wrapper"
											onClick={() => setState({ findYourStylesBtn: false })}></div>
									</Offcanvas.Header>
									<Offcanvas.Body className="p-0 fs-bg-white">
										<ViewGallery
											setViewGallery={setViewGallery}
											styleName={styles}
											text={slide?.find_your_style_white_system_name_label?.value}
										/>
									</Offcanvas.Body>
								</Offcanvas>
							</Carousel.Item>
						);
					}
				})}
			</Carousel>
		</div>
	);
}

export default FindYourStyles;
