/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from "@reduxjs/toolkit";
import {
	fetchDesignInspirationsPage,
	fetchExteriorPage,
	fetchInteriorPage,
	fetchViewGalleryPage,
} from "../../services/designInspiration/designInspirationServices";

const initialState = {
	loading: false,
	designInspirationPageData: {},
	viewGalleryPageData: {},
	VPInteriorPageData: {},
	VPExteriorPageData: {},
	error: "",
};

const designInspirationSlice = createSlice({
	name: "designInspiration",
	initialState,
	reducers: {
		setPageStartId: (prevState, action) => {
			const state = prevState;
			state.pageStartId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchDesignInspirationsPage.pending, (prevState, action) => {
			const state = prevState;
			state.loading = true;
		});
		builder.addCase(fetchDesignInspirationsPage.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.designInspirationPageData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchDesignInspirationsPage.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.designInspirationPageData = {};
			state.error = action.error.message;
		});
		builder.addCase(fetchViewGalleryPage.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.viewGalleryPageData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchViewGalleryPage.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.viewGalleryPageData = {};
			state.error = action.error.message;
		});
		builder.addCase(fetchInteriorPage.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.VPInteriorPageData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchInteriorPage.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.VPInteriorPageData = {};
			state.error = action.error.message;
		});
		builder.addCase(fetchExteriorPage.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.VPExteriorPageData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchExteriorPage.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.VPExteriorPageData = {};
			state.error = action.error.message;
		});
	},
});

const { actions, reducer: designInspirationReducer } = designInspirationSlice;

export const {
	// export actions here
	setPageStartId,
} = actions;

export default { designInspirationReducer };
