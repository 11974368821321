import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

export default function TestimonialCarousel({ testiMonials, testimonialsIcon }) {
    const item =
        testiMonials &&
        testiMonials.map((item) => {
            return (
                <div className="drs-htsbox d-flex justify-content-center align-items-center">
                    <img className="icon-chat" src={testimonialsIcon} alt="" />
                    <p className="dk-testimonial txt-testimonial">{item.message}</p>
                    <p className="dk-testimonial line">|</p>
                    <p className="dk-testimonial ">{item.createdBy}</p>
                </div>
            );
        });

    const options = {
        autoPlay: true,
        infinite: true,
        disableDotsControls: true,
        disableButtonsControls: true,
        autoPlayInterval: "5000",
        animationDuration: "800",
    };

    return (
        <div>
            <AliceCarousel mouseTracking items={item} {...options} />
        </div>
    );
}
