import { React, useEffect, useState } from "react";
import ArrowLeft from "../../assets/images/prev-arrow-white.svg";
import "./planCollections.scss";
import { RightNav } from "../../app/component/RightNav";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-spring-3d-carousel";
import { Link, useLocation } from "react-router-dom";
import { fetchPlansDetails, fetchPlansPage } from "../../services/plans/plansServices";
import PlansCard from "./PlansCard";
import { config } from "react-spring";
import { commaSeperator, capitalizeEachWord, assetTrim, damName, scale } from "../../constant";
import { Offcanvas } from "../components/Offcanvas";
import Loader from "../../app/component/loader/Loader";

export const PlanCollection = ({ item }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [state, setState] = useState({ viewVisualizerBtn: false });
	const [persistData, setPersistData] = useState({});

	const [settings, setSettings] = useState({
		goToSlide: 0,
		offsetRadius: 2,
		showNavigation: false,
		config: config.stiff,
	});

	const data = location.state?.data;

	useEffect(() => {
		if (data) {
			localStorage.setItem("data", JSON.stringify(data));
		} else if (item) {
			localStorage.setItem("data", JSON.stringify(item));
		}
	}, []);

	useEffect(() => {
		if (localStorage.getItem("data")) {
			const persistData = JSON.parse(localStorage.getItem("data"));
			setPersistData(persistData);
		}
	}, [localStorage.getItem("data")]);

	const {
		corporate,
		neighbourhoodData,
		plansDetails,
		plansCommunityPage,
		kiosk,
		subContent,
		plansCollectionsPage,
	} = useSelector((state) => {
		return {
			corporate: state?.homeReducer?.site,
			plansCommunityPage: state.plansReducer.plansPageData.plansCommunityPage,
			subContent: state.homeReducer.subContent,
			neighbourhoodData: state.plansReducer.neighborhoodData?.neighbourhoodData,
			kiosk: state.homeReducer.homepageData.kiosk,
			plansCollectionsPage: state.plansReducer?.plansPageData?.plansCollectionsPage,
			plansDetails: state?.plansReducer?.plansDetails,
		};
	});

	const plan_code = persistData?.plans?.[0]?.plan_code;
	const neighborhood_code = persistData?.plans?.[0]?.neighborhood_code;

	useEffect(() => {
		if (plan_code && neighborhood_code) {
			dispatch(fetchPlansDetails({ plan_code, neighborhood_code }));
		}
	}, [plan_code, neighborhood_code]);

	useEffect(() => {
		if (corporate && kiosk?.contentLink?.id !== plansCommunityPage?.parentLink?.id) {
			dispatch(fetchPlansPage({ subContent, kiosk }));
		}
	}, [corporate, plansCommunityPage, kiosk]);

	const plan = (persistData ? persistData : item)?.plans?.map((plan) => {
		return {
			key: plan?.plan_code,
			content: <PlansCard plan={plan} data={persistData ? persistData : item} />,
		};
	});
	const cards = plan?.map((slide, index) => {
		return {
			...slide,
			onClick: () => setSettings({ goToSlide: index }),
		};
	});

	// swiping carousel

	let xDown = null;
	let yDown = null;

	const getTouches = (evt) => {
		return evt.touches || evt.originalEvent.touches;
	};

	const handleTouchStart = (evt) => {
		const firstTouch = getTouches(evt)[0];
		xDown = firstTouch.clientX;
		yDown = firstTouch.clientY;
	};

	const handleTouchMove = (evt) => {
		if (!xDown || !yDown) {
			return;
		}

		let xUp = evt.touches[0].clientX;
		let yUp = evt.touches[0].clientY;

		let xDiff = xDown - xUp;
		let yDiff = yDown - yUp;

		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			/*most significant*/
			if (xDiff > 0) {
				/* left swipe */
				setSettings({ goToSlide: settings.goToSlide + 1 });
			} else {
				/* right swipe */
				setSettings({ goToSlide: settings.goToSlide - 1 });
			}
		} else {
			if (yDiff > 0) {
				/* up swipe */
			} else {
				/* down swipe */
			}
		}
		/* reset values */
		xDown = null;
		yDown = null;
	};
	return (
		<div>
			{kiosk?.contentLink?.id !== plansCommunityPage?.parentLink?.id ? (
				<Loader />
			) : (
				<div className="dk-MainContentWrapper">
					<section
						className="pp-explore-wrapper background-properties h-100 RightMenu blackoverlayTopWave"
						style={{
							backgroundImage: `url(${assetTrim(
								damName,
								plansCollectionsPage?.plan_neighborhood_background_image?.value,
								scale
							)}?scale=xxl)`,
						}}>
						<div className="wevewrapper">
							<div className="d-flex justify-content-between align-items-center">
								{neighbourhoodData?.length === 1 ? (
									""
								) : (
									<Link
										to="/plans"
										className="pl-back-to-landing d-flex align-items-center"
										state={{ data: persistData ? persistData : item }}>
										<img className="pl-backarrow" src={ArrowLeft} alt="Arrow left icon" /> Back to{" "}
										{capitalizeEachWord(kiosk?.title?.value)} Neighborhoods
									</Link>
								)}
								<span></span>
								<div className="d-flex flex-column pl-btn-wrapper">
									{(persistData ? persistData : item)?.qmis?.length > 0 && (
										<Link to="/planQuickMoveIn" state={{ data: persistData ? persistData : item }}>
											<button className="btn pl-explore-btn mb-10 primary-button mar-r50">
												View Quick Move-Ins
											</button>
										</Link>
									)}
									{plansDetails?.design_choice?.url && (
										<button
											className="btn pl-explore-btn primary-button mar-r50"
											onClick={() => setState({ viewVisualizerBtn: true })}>
											View Visualizer
										</button>
									)}
									<Offcanvas
										show={state.viewVisualizerBtn}
										offcanvasClassName=" ccw-watch-video-wrapper w-100 designChoiceIframe"
										onHide={() => setState({ viewVisualizerBtn: false })}
										bodyClassName="testimonial-offcanvasbody view-elevation-wrapper "
										headerClassName="ccw-close-wrapper video-tab-close">
										<iframe
											title="design choice"
											height="100%"
											width="100%"
											src={plansDetails?.design_choice?.url}></iframe>
									</Offcanvas>
								</div>
							</div>
							<div className="text-center  pb-35">
								<h1 className="pl-heading text-green">{(persistData ? persistData : item)?.market_name}</h1>
								<h2 className="pl-subheading white-color">{corporate?.plan_neighborhood_heading?.value}</h2>
								<p className="pl-plan-cost white-color">
									${commaSeperator((persistData ? persistData : item)?.price_low)} - $
									{commaSeperator((persistData ? persistData : item)?.price_high)}
								</p>
							</div>
							<div className="OnlyFourPlanCardsWrapper">
								{cards?.length < 5 ? (
									(persistData ? persistData : item)?.plans?.map((plan) => {
										return (
											<div className="OnlyFourCards cardCollection" key={plan?.plan_code}>
												<PlansCard plan={plan} data={persistData ? persistData : item} />;
											</div>
										);
									})
								) : (
									<>
										{cards && (
											<div
												className="planCardCarousel"
												onTouchStart={handleTouchStart}
												onTouchMove={handleTouchMove}>
												<Carousel
													slides={cards}
													goToSlide={settings.goToSlide}
													offsetRadius={settings.offsetRadius}
													showNavigation={settings.showNavigation}
													animationConfig={settings.config}
												/>
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</section>
					<RightNav />
				</div>
			)}
		</div>
	);
};
