import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContentLoader } from "@episerver/content-delivery";
import { DesignCollectionDesignInspirationsPage, DesignInspirationsPage } from "../../constant";
import axios from "axios";
const { REACT_APP_MDW_API_BASE_URL } = process.env;

const contentLoader = new ContentLoader();

export const fetchDesignInspirationsPage = createAsyncThunk(
	"DesignInspiration/fetchDesignInspirationsPage",
	async (subContent) => {
		const DesignInspirationsKiosk = subContent.find((ele) => {
			return (
				ele?.contentType?.includes(DesignInspirationsPage) ||
				ele?.contentType?.includes(DesignCollectionDesignInspirationsPage)
			);
		});

		const findYourStyles = await contentLoader.getChildren(DesignInspirationsKiosk?.contentLink?.guidValue, {
			branch: "en",
		});

		return {
			DesignInspirationsKiosk,
			findYourStyles,
		};
	}
);

export const fetchViewGalleryPage = createAsyncThunk(
	"DesignInspiration/fetchViewGalleryPage",
	async (neighborhoodCode, { rejectWithValue }) => {
		const codes = await axios
			.get(`${REACT_APP_MDW_API_BASE_URL}/neighborhood/details?codes=${neighborhoodCode}`)
			.then((res) => {
				return res.data?.data[0];
			})
			.catch((err) => {
				throw rejectWithValue(err);
			});

		const viewGallery = await axios
			.get(`${REACT_APP_MDW_API_BASE_URL}/flex/design-style?area_id=${codes?.area_id}`)
			.then((res) => {
				return res.data?.data;
			})
			.catch((err) => {
				throw rejectWithValue(err);
			});
		return viewGallery;
	}
);

export const fetchInteriorPage = createAsyncThunk("designInspiratio/fetchInteriorPage", async (id) => {
	const VPIteriorPage = await contentLoader.getContent(id, { branch: "en" });

	return { VPIteriorPage };
});

export const fetchExteriorPage = createAsyncThunk("designInspiratio/fetchExteriorPage", async (id) => {
	const VPExeriorPage = await contentLoader.getContent(id, { branch: "en" });

	return { VPExeriorPage };
});
