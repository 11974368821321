import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "animate.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Lottie from "lottie-web";
import whydreesWaveAnimationjson from "../../assets/images/animations/WhyDreesWaveAnimation.json";
import { RightNav } from "../../app/component/RightNav";
import "./whyDrees.scss";
import { fetchWhyDreesPage } from "../../services/whyDrees/whyDreesService";
import Loader from "../../app/component/loader/Loader";
import { useLocation } from "react-router-dom";
import { Customandconstruct } from "../customAndConstruct/CustomAndConstruct";
import { DreesSmartInnovation } from "../smartInnovation/DreesSmartInnovation";
import { Carousel } from "react-responsive-carousel";
import { OurStory } from "../ourStory/OurStory";
import { Testimonials } from "../testimonials/Testimonials";
import { assetTrim, damName, scale, WhyDreesDesignCollectionPage } from "../../constant";
import { CurveAnimation } from "../components/CurveAnimation";

export const WhyDrees = () => {
	const location = useLocation();
	const state = location.state?.animation;
	const page = location.state?.page;
	const target = location.state?.carousel;
	const transition = location.state?.transition;
	const [showWhyDreesImage, setShowWhyDreesImage] = useState(state);
	const dispatch = useDispatch();
	const [contentDisplay, setContentDisplay] = useState("whydrees");
	const [animation, setAnimation] = useState("");
	const [currentSlide, setCurrentSlide] = useState(0);
	const [transitionTime, setTransitionTime] = useState(transition);

	const { loading, error, whyDreesPageDataKiosk, subContent, corporate } = useSelector((state) => {
		return {
			loading: state.whyDreesReducer.loading,
			error: state.whyDreesReducer.error,
			whyDreesPageDataKiosk: state.whyDreesReducer.whyDreesPageData.whyDreesPageKiosk,
			subContent: state.homeReducer.subContent,
			corporate: state.homeReducer.site,
		};
	});
	useEffect(() => {
		if ((page || target) === "Custom + Construct") {
			setCurrentSlide(1);
		} else if ((page || target) === "DreesSmart Innovations") {
			setCurrentSlide(2);
		} else if ((page || target) === "Our Story") {
			(whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === undefined || whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === true)
				? setCurrentSlide(3)
				: setCurrentSlide(2);
		} else if ((page || target) === "Testimonials") {
			(whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === undefined || whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === true)
				? setCurrentSlide(4)
				: setCurrentSlide(3);
		}
	}, [page]);

	useEffect(() => {
		if (corporate && subContent && !whyDreesPageDataKiosk) {
			dispatch(fetchWhyDreesPage(subContent));
		}
	}, [corporate, subContent]);

	useEffect(() => {
		Lottie.loadAnimation({
			container: document.querySelector("#whydreesWaveAnimationID"),
			animationData: whydreesWaveAnimationjson,
			loop: true,
			autoplay: true,
		});
	}, [showWhyDreesImage, currentSlide, loading]);

	useEffect(() => {
		if (showWhyDreesImage) {
			const toRef = setTimeout(() => {
				setShowWhyDreesImage(false);
				clearTimeout(toRef);
			}, 2500);
		}
	}, [showWhyDreesImage, contentDisplay]);

	const handleComponentDisplay = (diplayType, animationType) => {
		setContentDisplay(diplayType);
		setAnimation(animationType);
	};

	const changeCarousel = (index) => {
		setCurrentSlide(index);
	};

	return (
		<div className="animationWhyDreesWrapper">
			{showWhyDreesImage ? (
				<div className="drs-curvehide">
					<CurveAnimation pageName={"Why Drees?"} />
				</div>
			) : (
				<div id="whydreesWaveAnimationID ForSlideOutLayout">
					<div
						className="dk-whydreesBg"
						style={{
							backgroundImage: `url(${assetTrim(
								damName,
								whyDreesPageDataKiosk?.why_drees_main_page_background_image?.value,
								scale
							)}?scale=xxl)`,
						}}></div>

					{loading && <Loader />}
					{error}
					{!loading && !error && (
						<div>
							<Carousel
								selectedItem={currentSlide}
								infiniteLoop={false}
								transitionTime={transitionTime === false ? "0" : "500"}
								interval="2000"
								showThumbs={false}
								showArrows={false}
								stopOnHover={false}
								axis="vertical"
								verticalSwipe="standard"
								emulateTouch={false}
								centerMode={false}
								showIndicators={false}
								showStatus={false}
								swipeable={false}>
								<div key={0}>
									<div className={animation}>
										<div id="whydreesWaveAnimationID">
											<div
												className="dk-whydreesBg"
												style={{
													backgroundImage: `url(${assetTrim(
														damName,
														whyDreesPageDataKiosk?.why_drees_main_page_background_image?.value,
														scale
													)}?scale=xxl)`,
												}}></div>
										</div>
										<div className="dk-MainContentWrapper RightMenu">
											<div className="dk-why-drees-section">
												<div className="dk-why-drees-mainpanel">
													<h2>
														{
															(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																? whyDreesPageDataKiosk
																: corporate
															)?.why_drees_main_page_heading?.value
														}
													</h2>
													<h3>
														{
															(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																? whyDreesPageDataKiosk
																: corporate
															)?.why_drees_main_page_description?.value
														}
													</h3>

													<div className="dk-wd-list-animation">
														<div
															className="animate-box box1"
															onClick={() => {
																changeCarousel(1);
															}}>
															<img
																src={`${assetTrim(
																	damName,
																	(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																		? whyDreesPageDataKiosk
																		: corporate
																	)?.why_drees_main_page_custom_construct_image?.value,
																	scale
																)}?scale=medium`}
																alt=""
															/>
															<div className="box box1">
																{`${
																	(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																		? whyDreesPageDataKiosk
																		: corporate
																	)?.why_drees_main_page_custom_construct_label?.value
																}`}
															</div>
														</div>
														{(whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value ||
															whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value ===
																undefined) && (
															<div className="animate-box box2">
																<div
																	className="animate-box box1"
																	onClick={() => {
																		changeCarousel(2);
																	}}>
																	<img
																		src={`${assetTrim(
																			damName,
																			(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																				? whyDreesPageDataKiosk
																				: corporate
																			)?.why_drees_main_page_dreesmart_innovations_image?.value,
																			scale
																		)}?scale=medium`}
																		alt=""
																	/>
																	<div className="box box2">
																		<span className="w-75p">
																			{
																				(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																					? whyDreesPageDataKiosk
																					: corporate
																				)?.why_drees_main_page_dreesmart_innovations_label?.value
																			}
																		</span>
																	</div>
																</div>
															</div>
														)}

														<div
															className="animate-box box3"
															onClick={() => {
																{
																	whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active
																		?.value === true ||
																	whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active
																		?.value === undefined
																		? changeCarousel(3)
																		: changeCarousel(2);
																}
															}}>
															<img
																src={`${assetTrim(
																	damName,
																	(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																		? whyDreesPageDataKiosk
																		: corporate
																	)?.why_drees_main_page_our_story_image?.value,
																	scale
																)}?scale=medium`}
																alt=""
															/>
															<div className="box box3">
																{
																	(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																		? whyDreesPageDataKiosk
																		: corporate
																	)?.why_drees_main_page_our_story_label?.value
																}
															</div>
														</div>

														<div
															className="animate-box box4"
															onClick={() => {
																{
																	whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active
																		?.value === true ||
																	whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active
																		?.value === undefined
																		? changeCarousel(4)
																		: changeCarousel(3);
																}
															}}>
															<img
																src={`${assetTrim(
																	damName,
																	(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																		? whyDreesPageDataKiosk
																		: corporate
																	)?.why_drees_main_page_testimonial_image?.value,
																	scale
																)}?scale=medium`}
																alt=""
															/>
															<div className="box box4">
																{
																	(whyDreesPageDataKiosk?.contentType?.includes(WhyDreesDesignCollectionPage)
																		? whyDreesPageDataKiosk
																		: corporate
																	)?.why_drees_main_page_testimonial_label?.value
																}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div key={1}>
									<Customandconstruct
										animation={animation}
										handleComponentDisplay={handleComponentDisplay}
										changeCarousel={changeCarousel}
										subContent={subContent}
										setTransitionTime={setTransitionTime}
									/>
								</div>
								{whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === true ||
								whyDreesPageDataKiosk?.why_drees_main_page_dreesmart_innovations_active?.value === undefined ? (
									<div key={2}>
										<DreesSmartInnovation
											animation={animation}
											handleComponentDisplay={handleComponentDisplay}
											changeCarousel={changeCarousel}
											subContent={subContent}
											setTransitionTime={setTransitionTime}
										/>
									</div>
								) : null}
								<div key={3}>
									<OurStory
										animation={animation}
										handleComponentDisplay={handleComponentDisplay}
										changeCarousel={changeCarousel}
										subContent={subContent}
										setTransitionTime={setTransitionTime}
									/>
								</div>
								<div key={4}>
									<Testimonials
										animation={animation}
										handleComponentDisplay={handleComponentDisplay}
										changeCarousel={changeCarousel}
										subContent={subContent}
										setTransitionTime={setTransitionTime}
									/>
								</div>
							</Carousel>

							<RightNav />
						</div>
					)}
				</div>
			)}
		</div>
	);
};
