import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { RightNav } from "../../app/component/RightNav";
import LeftArrow from "../../assets/images/prev-arrow-white.svg";
import { fetchCommunityPage, fetchOur } from "../../services/community/communityServices";
import OurTeamCard from "./OurTeamCard";

function OurTeam() {
  const location = useLocation();
  const filteredOurTeam =
    location.state?.filteredOurTeam ||
    JSON.parse(localStorage.getItem("ourteam"));
  const [sortedTeam, setSortedTeam] = useState([]);
  const dispatch = useDispatch()

  const { ourTeamPage, subContent, corporate, kiosk} = useSelector((state) => {
    return {
      ourTeamPage: state.communityReducer.communityPageData.ourTeamPage,
	  subContent: state.homeReducer.subContent,
	  corporate: state.homeReducer.site,
	  kiosk: state.homeReducer.homepageData.kiosk,
    };
  });

  useEffect(() => {
    const sortedteam = _.sortBy(filteredOurTeam, function (item) {
      return item.employee_title === "Senior Market Manager"
        ? 0
        : item.employee_title === "Market Manager"
        ? 1
        : item.employee_title === "Market Manager"
        ? 2
        : item.employee_title === "Builder"
        ? 3
        : item.employee_title === "Loan Officer"
        ? 4
        : item.employee_title === "Customer Care Representative"
        ? 5
        : "";
    });
    setSortedTeam(sortedteam);
  }, []);

  useEffect(() => {
	if (corporate) {
		dispatch(fetchCommunityPage(subContent));
	}
}, [corporate, subContent]);

  return (
    <div className="dk-MainContentWrapper">
      <section className="our-team-body-wrapper our-team-Wrapper pp-explore-wrapper h-100">
        <Link
          to="/community"
          state={{ filteredOurTeam: filteredOurTeam }}
          className="ot-back-to-community d-inline-flex align-items-center"
          style={{ cursor: "pointer" }}
        >
          <img className="ot-backarrow" src={LeftArrow} alt="Arrow left icon" />
          Back to Community
        </Link>
        <h1 className="ot-heading text-green text-center pb-35">{ourTeamPage?.our_team_heading?.value}</h1>
        <div className="our-team-cards-wrapper">
          <div className="our-team-cards cardCollection">
            <div className="d-flex flex-wrap card-align">
              {sortedTeam?.map((item, index) => {
                return <OurTeamCard item={item} key={index} />;
              })}
            </div>
          </div>
        </div>
      </section>
      <RightNav />
    </div>
  );
}

export default OurTeam;
