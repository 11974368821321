import React from "react";
import defaultOurTeamImage from "../../assets/images/ourTeam.png";

function OurTeamCard({ item }) {
  const addDefaultSrc = (ev) => {
    ev.target.src = defaultOurTeamImage;
  };
  return (
    <div className="d-flex justify-content-center align-items-center position-relative flex-row our-team ourTeamWrapper">
      <div className="card pv-collection-card position-relative">
        <div className="card-body d-flex flex-column">
          <div className="card-body-top-wrapper">
            <>
              <h2 className="card-title d-flex justify-content-center">
                {item?.employee_title.toUpperCase()}
              </h2>
            </>
            <div className="planCardImageWrapper">
              {item?.employee_asset ? (
                <img
                  src={item?.employee_asset}
                  className="card-img-top "
                  alt="Imge of Community"
                  onError={addDefaultSrc}
                />
              ) : (
                <img
                  src={defaultOurTeamImage}
                  className="card-img-top "
                  alt="Imge of Community"
                />
              )}
            </div>
            <h3 className="subtitle d-flex justify-content-center">
              {item?.employee_first_name} {item?.employee_last_name}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurTeamCard;
