import { React, useEffect, useState } from "react";
import "./customConstruct.scss";
import "./contemporary.scss";
import { RightNav } from "../../app/component/RightNav";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchDesignInspirationsPage,
	fetchExteriorPage,
	fetchInteriorPage,
	fetchViewGalleryPage,
} from "../../services/designInspiration/designInspirationServices";
import Loader from "../../app/component/loader/Loader";
import { Tab, Tabs } from "react-bootstrap";
import { Offcanvas } from "../components/Offcanvas";
import { Link, useLocation } from "react-router-dom";
import FindYourStyles from "./FindYourStyles";
import { assetTrim, damName, scale, youtube_videoId } from "../../constant";
import { CurveAnimation } from "../components/CurveAnimation";

export const DesignInspiration = (props) => {
	const { setCustomeClassName } = props;
	const location = useLocation();
	const animation = location.state?.animation;
	const [showDesignInspirationImage, setShowDesignInspirationImage] = useState(animation);
	const [contentDisplay] = useState("designInspiration");
	const [neighborhoodItem, setNeighborhoodItem] = useState({});
	const dispatch = useDispatch();
	const [key, setKey] = useState("video");
	const [state, setState] = useState({
		videoTourBtn: false,
		findYourStylesBtn: false,
		visualizeThePossibilitiesBtn: false,
		play: 0,
	});
	const [viewGallery, setViewGallery] = useState(false);

	const {
		loading,
		error,
		designInspirationsKiosk,
		findYourStyles,
		viewGalleryPage,
		subContent,
		kiosk,
		neighbourhoodData,
		corporate,
		VPInterior,
		VPExterior,
	} = useSelector((state) => {
		return {
			designInspirationsKiosk: state.designInspirationReducer.designInspirationPageData.DesignInspirationsKiosk,
			findYourStyles: state.designInspirationReducer.designInspirationPageData?.findYourStyles,
			subContent: state.homeReducer.subContent,
			neighbourhoodData: state.plansReducer.neighborhoodData?.neighbourhoodData,
			kiosk: state.homeReducer.homepageData.kiosk,
			corporate: state.homeReducer.site,
			viewGalleryPage: state.designInspirationReducer.viewGalleryPageData.categories,
			VPInterior: state.designInspirationReducer.VPInteriorPageData.VPIteriorPage,
			VPExterior: state.designInspirationReducer.VPExteriorPageData.VPExeriorPage,
		};
	});

	const pageTypes = {
		WhyDreesDesignCollectionPage: "WhyDreesDesignCollectionPage",
		WhyDreesPage: "WhyDreesPage",
		DesignCollectionCustomAndConstructHowWeDoPage: "DesignCollectionCustomAndConstructHowWeDoPage",
		CustomAndConstructHowWeDoPage: "CustomAndConstructHowWeDoPage",
		CustomAndConstructPage: "CustomAndConstructPage",
		DreesmartInnovationsPage: "DreesmartInnovationsPage",
		OurStoryPage: "OurStoryPage",
		ReadOurStoryPage: "ReadOurStoryPage",
		TestiMonialPage: "TestiMonialPage",
		CommunityPage: "CommunityPage",
		AmenitiesAndSchools: "AmenitiesAndSchoolsPage",
		DesignCollectionDesignInspirationsPage: "DesignCollectionDesignInspirationsPage",
		DesignInspirationsPage: "DesignInspirationsPage",
		ExplorePage: "ExplorePage",
		PlanCommunityPage: "PlanCommunityPage",
		PlanNeighborhoodPage: "PlanNeighborhoodPage",
		PlanQMINeighborhoodPage: "PlanQMINeighborhoodPage",
	};

	useEffect(() => {
		neighbourhoodData?.map((item) => {
			setNeighborhoodItem(item);
		});
	}, [neighbourhoodData]);
	useEffect(() => {
		if (
			corporate &&
			designInspirationsKiosk?.contentType?.includes("DesignInspirationsPage") &&
			!viewGalleryPage
		) {
			const neighborhoodCode = kiosk?.neighborhoods?.value?.[0]?.neighborhoodCode;
			dispatch(fetchViewGalleryPage(neighborhoodCode));
		}
	}, [corporate, kiosk, viewGalleryPage]);

	useEffect(() => {
		if (corporate) {
			setCustomeClassName("");
			dispatch(fetchDesignInspirationsPage(subContent));
		}
	}, [corporate, kiosk]);

	useEffect(() => {
		if (showDesignInspirationImage) {
			const toRef = setTimeout(() => {
				setShowDesignInspirationImage(false);
				clearTimeout(toRef);
			}, 2500);
		}
	}, [showDesignInspirationImage, contentDisplay]);

	useEffect(() => {
		if (
			corporate &&
			designInspirationsKiosk?.contentType?.includes("DesignCollectionDesignInspirationsPage") &&
			designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link?.value?.length > 0 &&
			designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link?.value[0]
				?.contentLink !== null &&
			designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link?.value[0]
				?.contentLink !== null
		) {
			dispatch(
				fetchInteriorPage(
					designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link?.value[0]
						?.contentLink?.guidValue
				)
			);
			dispatch(
				fetchExteriorPage(
					designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link?.value[0]
						?.contentLink?.guidValue
				)
			);
		} else if (
			corporate &&
			designInspirationsKiosk?.contentType?.includes("DesignCollectionDesignInspirationsPage") &&
			designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link?.value?.length > 0 &&
			designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link?.value[0]
				?.contentLink !== null
		) {
			dispatch(
				fetchInteriorPage(
					designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link?.value[0]
						?.contentLink?.guidValue
				)
			);
		} else if (
			corporate &&
			designInspirationsKiosk?.contentType?.includes("DesignCollectionDesignInspirationsPage") &&
			designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link?.value?.length > 0 &&
			designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link?.value[0]
				?.contentLink !== null
		) {
			dispatch(
				fetchExteriorPage(
					designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link?.value[0]
						?.contentLink?.guidValue
				)
			);
		}
	}, [designInspirationsKiosk]);

	return (
		<>
			{showDesignInspirationImage ? (
				<CurveAnimation pageName={"Design Inspirations"} />
			) : (
				<div>
					{loading && <Loader />}
					{error}
					{!loading && !error && (
						<div>
							<div className="dk-MainContentWrapper">
								<div
									className="dk-design-inspi-section background-properties RightMenu"
									style={{
										backgroundImage: `url(${assetTrim(
											damName,
											designInspirationsKiosk?.design_inspirations_background_image?.value,
											scale
										)}?scale=xxl)`,
									}}>
									<div className="dk-why-drees-section">
										<h1 className="text-start">
											{
												(designInspirationsKiosk?.contentType?.includes("DesignCollectionDesignInspirationsPage")
													? designInspirationsKiosk
													: corporate
												)?.design_inspirations_heading?.value
											}
										</h1>
										<p className="text-start">
											{
												(designInspirationsKiosk?.contentType?.includes("DesignCollectionDesignInspirationsPage")
													? designInspirationsKiosk
													: corporate
												)?.design_inspirations_description?.value
											}{" "}
										</p>

										<div className="dk-wd-list-animation dk-design-animation-wrapper">
											{designInspirationsKiosk?.contentType?.includes("DesignCollectionDesignInspirationsPage") &&
											designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link?.value
												?.length > 0 &&
											designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link
												?.value[0]?.contentLink !== null ? (
												<div
													className={`animate-box ${
														(designInspirationsKiosk?.contentType?.includes(
															"DesignCollectionDesignInspirationsPage"
														) &&
															designInspirationsKiosk
																?.design_inspirations_visualize_the_possibilities_interior_link?.value?.length >
																0) ||
														(designInspirationsKiosk?.contentType?.includes("DesignInspirationsPage") &&
															designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_link
																?.value !== null)
															? ""
															: "singleInspirationBox"
													}`}>
													<Link
														to={`/${
															VPExterior?.contentType.includes(pageTypes?.CommunityPage) ||
															VPExterior?.contentType.includes(pageTypes?.AmenitiesAndSchools)
																? "community"
																: VPExterior?.contentType.includes(pageTypes?.WhyDreesDesignCollectionPage) ||
																  VPExterior?.contentType.includes(pageTypes?.WhyDreesPage) ||
																  VPExterior?.contentType.includes(
																		pageTypes?.DesignCollectionCustomAndConstructHowWeDoPage
																  ) ||
																  VPExterior?.contentType.includes(pageTypes?.CustomAndConstructPage) ||
																  VPExterior?.contentType.includes(pageTypes?.CustomAndConstructHowWeDoPage) ||
																  VPExterior?.contentType.includes(pageTypes?.DreesmartInnovationsPage) ||
																  VPExterior?.contentType.includes(pageTypes?.ReadOurStoryPage) ||
																  VPExterior?.contentType.includes(pageTypes?.OurStoryPage) ||
																  VPExterior?.contentType.includes(pageTypes?.TestiMonialPage)
																? "whyDrees"
																: VPExterior?.contentType.includes(
																		pageTypes?.DesignCollectionDesignInspirationsPage
																  ) || VPExterior?.contentType.includes(pageTypes?.DesignInspirationsPage)
																? "designInspiration"
																: VPExterior?.contentType.includes(pageTypes?.PlanCommunityPage)
																? "plans"
																: VPExterior?.contentType.includes(pageTypes?.PlanNeighborhoodPage)
																? "planCollection"
																: VPExterior?.contentType.includes(pageTypes?.PlanQMINeighborhoodPage)
																? "planQuickMoveIn"
																: VPExterior?.contentType.includes(pageTypes?.ExplorePage)
																? ""
																: ""
														}`}
														state={
															VPExterior?.contentType.includes(pageTypes?.CustomAndConstructHowWeDoPage) ||
															VPExterior?.contentType.includes(pageTypes?.CustomAndConstructPage)
																? { carousel: "Custom + Construct" }
																: VPExterior?.contentType.includes(pageTypes?.DreesmartInnovationsPage)
																? { carousel: "DreesSmart Innovations" }
																: VPExterior?.contentType.includes(pageTypes?.ReadOurStoryPage) ||
																  VPExterior?.contentType.includes(pageTypes?.OurStoryPage)
																? { carousel: "Our Story" }
																: VPExterior?.contentType.includes(pageTypes?.TestiMonialPage)
																? { carousel: "Testimonials" }
																: VPExterior?.contentType.includes(pageTypes?.PlanQMINeighborhoodPage) ||
																  VPExterior?.contentType.includes(pageTypes?.PlanNeighborhoodPage)
																? { data: neighborhoodItem }
																: VPExterior?.contentType.includes(pageTypes?.ExplorePage)
																? { data: true }
																: ""
														}>
														<div className="des-inno-animationimage background-properties">
															<img
																className="des-inno-animationimage  background-properties"
																src={`${assetTrim(
																	damName,
																	designInspirationsKiosk
																		?.design_inspirations_visualize_the_possibilities_exterior_image?.value,

																	scale
																)}?scale=medium`}
																alt=""
															/>
															<div className="box box1">
																{
																	designInspirationsKiosk
																		?.design_inspirations_visualize_the_possibilities_exterior_label?.value
																}
															</div>
														</div>
													</Link>
												</div>
											) : designInspirationsKiosk?.contentType?.includes(
													"DesignCollectionDesignInspirationsPage"
											  ) &&
											  designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link
													?.value?.length > 0 &&
											  designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link
													?.value[0]?.contentLink === null ? (
												<div
													className={`animate-box ${
														(designInspirationsKiosk?.contentType?.includes(
															"DesignCollectionDesignInspirationsPage"
														) &&
															designInspirationsKiosk
																?.design_inspirations_visualize_the_possibilities_interior_link?.value?.length >
																0) ||
														(designInspirationsKiosk?.contentType?.includes("DesignInspirationsPage") &&
															designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_link
																?.value !== null)
															? ""
															: "singleInspirationBox"
													}`}>
													<div
														className="des-inno-animationimage background-properties"
														onClick={() => {
															setState({
																findYourStylesBtn: true,
															});
															setViewGallery(false);
														}}>
														<img
															className="des-inno-animationimage  background-properties"
															src={`${assetTrim(
																damName,
																designInspirationsKiosk
																	?.design_inspirations_visualize_the_possibilities_exterior_image?.value,
																scale
															)}?scale=medium`}
															alt="exterior img"
														/>
														<div className="box box1">
															{
																designInspirationsKiosk
																	?.design_inspirations_visualize_the_possibilities_exterior_label?.value
															}
														</div>
													</div>
												</div>
											) : (
												designInspirationsKiosk?.contentType.includes("DesignInspirationsPage") && (
													<div
														className={`animate-box ${
															designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_link
																?.value !== null
																? ""
																: "singleInspirationBox"
														}`}>
														<div
															className="des-inno-animationimage background-properties"
															onClick={() => {
																setState({
																	findYourStylesBtn: true,
																});
																setViewGallery(false);
															}}>
															<img
																className="des-inno-animationimage  background-properties"
																src={`${assetTrim(
																	damName,
																	designInspirationsKiosk?.design_inspirations_find_your_style_image?.value,
																	scale
																)}?scale=large`}
																alt=""
															/>
															<div className="box box1">
																{corporate?.design_inspirations_find_your_style_label?.value}
															</div>
														</div>
													</div>
												)
											)}
											<Offcanvas
												show={state.findYourStylesBtn}
												placement="end"
												offcanvasClassName={
													designInspirationsKiosk?.contentType?.includes("DesignCollectionDesignInspirationsPage")
														? "ccw-homesites-wrapper w-100"
														: "ccw-watch-video-wrapper w-100 fs-contem-wrapper"
												}
												onHide={() => setState({ findYourStylesBtn: false })}
												headerClassName="ccw-close-wrapper">
												{designInspirationsKiosk?.contentType?.includes(
													"DesignCollectionDesignInspirationsPage"
												) ? (
													<>
															<iframe
																title="visualize the possibilities"
																width="100%"
																height="99%"
																name="iframe_a"
																src={`
																	${designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link?.value[0]?.href}`}
																allow-same-origin></iframe>
													</>
												) : (
													<div>
														<FindYourStyles
															corporate={corporate}
															findYourStyles={findYourStyles}
															setState={setState}
															viewGallery={viewGallery}
															setViewGallery={setViewGallery}
														/>
													</div>
												)}
											</Offcanvas>

											{designInspirationsKiosk?.contentType?.includes("DesignCollectionDesignInspirationsPage") &&
											designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link?.value
												?.length > 0 &&
											designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link
												?.value[0]?.contentLink !== null ? (
												<div
													className={`animate-box ${
														designInspirationsKiosk?.contentType?.includes(
															"DesignCollectionDesignInspirationsPage"
														) &&
														designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_exterior_link
															?.value?.length > 0
															? ""
															: "singleInspirationBox"
													}`}>
													<Link
														to={`/${
															VPInterior?.contentType.includes(pageTypes?.CommunityPage) ||
															VPInterior?.contentType.includes(pageTypes?.AmenitiesAndSchools)
																? "community"
																: VPInterior?.contentType.includes(pageTypes?.WhyDreesDesignCollectionPage) ||
																  VPInterior?.contentType.includes(pageTypes?.WhyDreesPage) ||
																  VPInterior?.contentType.includes(
																		pageTypes?.DesignCollectionCustomAndConstructHowWeDoPage
																  ) ||
																  VPInterior?.contentType.includes(pageTypes?.CustomAndConstructPage) ||
																  VPInterior?.contentType.includes(pageTypes?.CustomAndConstructHowWeDoPage) ||
																  VPInterior?.contentType.includes(pageTypes?.DreesmartInnovationsPage) ||
																  VPInterior?.contentType.includes(pageTypes?.ReadOurStoryPage) ||
																  VPInterior?.contentType.includes(pageTypes?.OurStoryPage) ||
																  VPInterior?.contentType.includes(pageTypes?.TestiMonialPage)
																? "whyDrees"
																: VPInterior?.contentType.includes(
																		pageTypes?.DesignCollectionDesignInspirationsPage
																  ) || VPInterior?.contentType.includes(pageTypes?.DesignInspirationsPage)
																? "designInspiration"
																: VPInterior?.contentType.includes(pageTypes?.PlanCommunityPage)
																? "plans"
																: VPInterior?.contentType.includes(pageTypes?.PlanNeighborhoodPage)
																? "planCollection"
																: VPInterior?.contentType.includes(pageTypes?.PlanQMINeighborhoodPage)
																? "planQuickMoveIn"
																: VPInterior?.contentType.includes(pageTypes?.ExplorePage)
																? ""
																: ""
														}`}
														state={
															VPInterior?.contentType.includes(pageTypes?.CustomAndConstructHowWeDoPage) ||
															VPInterior?.contentType.includes(pageTypes?.CustomAndConstructPage)
																? { carousel: "Custom + Construct" }
																: VPInterior?.contentType.includes(pageTypes?.DreesmartInnovationsPage)
																? { carousel: "DreesSmart Innovations" }
																: VPInterior?.contentType.includes(pageTypes?.ReadOurStoryPage) ||
																  VPInterior?.contentType.includes(pageTypes?.OurStoryPage)
																? { carousel: "Our Story" }
																: VPInterior?.contentType.includes(pageTypes?.TestiMonialPage)
																? { carousel: "Testimonials" }
																: VPInterior?.contentType.includes(pageTypes?.PlanQMINeighborhoodPage) ||
																  VPInterior?.contentType.includes(pageTypes?.PlanNeighborhoodPage)
																? { data: neighborhoodItem }
																: VPInterior?.contentType.includes(pageTypes?.ExplorePage)
																? { data: true }
																: ""
														}>
														<div className="des-inno-animationimage background-properties">
															<img
																className="des-inno-animationimage  background-properties"
																src={`${assetTrim(
																	damName,
																	designInspirationsKiosk?.contentType?.includes(
																		"DesignCollectionDesignInspirationsPage"
																	)
																		? designInspirationsKiosk
																				?.design_inspirations_visualize_the_possibilities_interior_image?.value
																		: corporate?.design_inspirations_visualize_the_possibilities_image?.value,
																	scale
																)}?scale=medium`}
																alt=""
															/>
															<div className="box box2">
																{designInspirationsKiosk?.contentType?.includes(
																	"DesignCollectionDesignInspirationsPage"
																)
																	? designInspirationsKiosk
																			?.design_inspirations_visualize_the_possibilities_interior_label?.value
																	: corporate?.design_inspirations_visualize_the_possibilities_label?.value}
															</div>
														</div>
													</Link>
												</div>
											) : (
												((designInspirationsKiosk?.contentType?.includes(
													"DesignCollectionDesignInspirationsPage"
												) &&
													designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link
														?.value?.length > 0 &&
													designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link
														?.value[0]?.contentLink === null) ||
													(designInspirationsKiosk?.contentType?.includes("DesignInspirationsPage") &&
														designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_link
															?.value !== null)) && (
													<div
														className={`animate-box ${
															(designInspirationsKiosk?.contentType?.includes(
																"DesignCollectionDesignInspirationsPage"
															) &&
																designInspirationsKiosk
																	?.design_inspirations_visualize_the_possibilities_exterior_link?.value?.length >
																	0) ||
															(designInspirationsKiosk?.contentType?.includes("DesignInspirationsPage") &&
																designInspirationsKiosk?.design_inspirations_find_your_style_link?.value !== null)
																? ""
																: "singleInspirationBox"
														}`}>
														<div
															className="des-inno-animationimage background-properties"
															onClick={() => setState({ visualizeThePossibilitiesBtn: true })}>
															<img
																className="des-inno-animationimage  background-properties"
																src={`${assetTrim(
																	damName,
																	designInspirationsKiosk?.contentType?.includes(
																		"DesignCollectionDesignInspirationsPage"
																	)
																		? designInspirationsKiosk
																				?.design_inspirations_visualize_the_possibilities_interior_image?.value
																		: corporate?.design_inspirations_visualize_the_possibilities_image?.value,
																	scale
																)}?scale=medium`}
																alt=""
															/>
															<div className="box box2">
																{designInspirationsKiosk?.contentType?.includes(
																	"DesignCollectionDesignInspirationsPage"
																)
																	? designInspirationsKiosk
																			?.design_inspirations_visualize_the_possibilities_interior_label?.value
																	: corporate?.design_inspirations_visualize_the_possibilities_label?.value}
															</div>
														</div>
													</div>
												)
											)}
										</div>

										<Offcanvas
											show={state.visualizeThePossibilitiesBtn}
											placement="end"
											offcanvasClassName={
												designInspirationsKiosk?.contentType?.includes("DesignCollectionDesignInspirationsPage")
													? "ccw-homesites-wrapper w-100"
													: "ccw-watch-video-wrapper w-100 fs-contem-wrapper"
											}
											onHide={() => setState({ visualizeThePossibilitiesBtn: false })}
											headerClassName="ccw-close-wrapper">
											{designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link
												?.value[0]?.contentLink === null ? (
													<iframe
														title="visualize the possibilities"
														width="100%"
														height="99%"
														name="iframe_a"
														src={`
																	${designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_interior_link?.value[0]?.href}`}
														allow-same-origin></iframe>
											) : (
													<iframe
														title="visualize the possibilities"
														width="100%"
														height="99%"
														src={
															designInspirationsKiosk?.design_inspirations_visualize_the_possibilities_link?.value
														}></iframe>
											)}
										</Offcanvas>
										{designInspirationsKiosk?.contentType?.includes("DesignInspirationsPage") && (
											<div className="text-center">
												<button
													type="submit"
													className="primary-button cc-design-inno-btn mr-25"
													onClick={() => {
														setState({ videoTourBtn: true });
														setKey("video");
													}}>
													{corporate?.design_inspirations_watch_our_design_studio_video_label?.value}
												</button>
												<Offcanvas
													show={state.videoTourBtn}
													placement="end"
													offcanvasClassName="ccw-watch-video-wrapper w-100"
													onHide={() => setState({ videoTourBtn: false })}
													bodyClassName="drs-tabstyle"
													headerClassName="ccw-close-wrapper video-tab-close">
														<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
															<Tab
																eventKey="video"
																title="Video"
																style={{ textAlign: "center" }}
																mountOnEnter
																unmountOnExit>
																<div className="ccw-video-wrapper ">
																	<h1 className="ccw-heading">Drees Homes Design Center Video</h1>
																	<div className="ccw-video-main-wrapper">
																		<iframe
																			title="watch our design studio"
																			className="ccw-video"
																			src={`https://www.youtube.com/embed/${youtube_videoId(
																				designInspirationsKiosk?.design_inspirations_watch_our_design_studio_video
																					?.value
																			)}?autoplay=1`}
																			allow="autoplay"></iframe>
																	</div>
																</div>
															</Tab>
															<Tab eventKey="tour" title="Tour" style={{ textAlign: "center" }} unmountOnExit>
																<div className="ccw-video-wrapper ">
																	<h1 className="ccw-heading">Explore The Drees Design Center</h1>
																	<div className="ccw-video-main-wrapper">
																		<iframe
																			title="design inspiration tour"
																			className="ccw-video"
																			src={`${designInspirationsKiosk?.design_inspirations_tour_our_design_studio?.value}&play=1`}></iframe>
																	</div>
																</div>
															</Tab>
														</Tabs>
												</Offcanvas>
												<button
													type="submit"
													className="primary-button cc-design-inno-btn mr-25"
													onClick={() => {
														setState({ videoTourBtn: true });
														setKey("tour");
													}}>
													{corporate?.design_inspirations_tour_our_design_studio_label?.value}
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
							<RightNav />
						</div>
					)}
				</div>
			)}
		</>
	);
};
