export const APIBASE = "https://drco01mstrmo010inte.dxcloud.episerver.net/api/episerver/v3.0";
export const kioskApi = "https://kiosk-api-dev.dreeshomes.com/kiosk";

export const siteId = 61;

// main page list
export const WhyDreesPage = "WhyDreesPage";
export const WhyDreesDesignCollectionPage = "WhyDreesDesignCollectionPage";
export const DesignInspirationsPage = "DesignInspirationsPage";
export const DesignCollectionDesignInspirationsPage = "DesignCollectionDesignInspirationsPage";
export const CommunityPage = "CommunityPage";
export const PlanCommunityPage = "PlanCommunityPage";
export const PlanQMINeighborhoodPage = "PlanQMINeighborhoodPage";
export const PlanNeighborhoodPage = "PlanNeighborhoodPage";
export const ExplorePage = "ExplorePage";
export const damName = "dreesdam";
export const scale = "?scale";
export const defaultImage = "https://dhp.dreeshomes.com/cms/images/BX9HbFtg4zl9V6mUPZPC-u";

// customConstruct contentType
export const CustomAndConstructPage = "CustomAndConstructPage";
export const DreesmartInnovationsPage = "DreesmartInnovationsPage";
export const OurStoryPage = "OurStoryPage";
export const TestiMonialPage = "TestiMonialPage";

// price comma seperate
export const commaSeperator = (item) => {
	return item?.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,");
};

export const capitalizeEachWord = (item) => {
	return item
		?.split(" ")
		?.map((word) => {
			return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
		})
		?.join(" ");
};

export const capitalizeFirstLetter = (item) => {
	return item?.charAt(0)?.toUpperCase() + item?.slice(1)?.toLowerCase();
};

export const youtube_videoId = (url) => {
	const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	const match = url?.match(regExp);
	return match && match[7].length == 11 ? match[7] : false;
};

export const assetTrim = (damName, url, scale) => {
	if (url?.includes(damName)) {
		const splitUrl = url?.split(damName);
		if (splitUrl?.[1]?.includes(scale)) {
			const splitUrlScale = splitUrl?.[1]?.slice(1)?.toString()?.split(scale);
			if (splitUrlScale?.length === 2) {
				return splitUrlScale?.[0]?.toString();
			}
		} else {
			if (splitUrl?.length === 2) {
				return splitUrl?.[1]?.slice(1)?.toString();
			}
		}
	} else if (url?.includes(scale)) {
		const splitUrlScale = url?.split(scale);
		if (splitUrlScale?.length === 2) {
			return splitUrlScale?.[0]?.toString();
		}
	} else {
		return url;
	}
};
