import React from "react";

export const LeadForm = ({ handleSubmit, handleChange, fields, lead, plansData, quickMoveInData }) => {
  const { firstName, lastName, email, phoneNumber, zipCode } = fields;
  return (
    <div>
      <form className="dk-signup-form" onSubmit={handleSubmit}>
        <div className="d-flex dk-field-mainwrapper mb-50 text-start">
          <div className="dk-field-wrapper pr-10 ">
            <label htmlFor="first-name" className="form-label">
              First Name*
            </label>
            <input
              type="text"
              className="form-control"
              id="first-name"
              value={firstName}
              name="firstName"
              placeholder="Enter First Name"
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </div>
          <div className="dk-field-wrapper pr-10">
            <label htmlFor="last-name" className="form-label">
              Last Name*
            </label>
            <input
              type="text"
              className="form-control"
              id="last-name"
              value={lastName}
              name="lastName"
              placeholder="Enter Last Name"
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </div>
          <div className="dk-field-wrapper pr-10">
            <label htmlFor="email" className="form-label">
              Email address*
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              name="email"
              placeholder="Enter Email address"
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </div>
          <div className="dk-field-wrapper">
            <label htmlFor="zip" className="form-label">
              Zip Code*
            </label>
            <input
              type="text"
              className="form-control"
              id="Zip"
              placeholder="Enter Zip Code"
              value={zipCode}
              name="zipCode"
              onChange={handleChange}
              required
              autoComplete="off"
              maxLength={5}
            />
          </div>
          <div className="dk-field-wrapper">
            <label htmlFor="ph-number" className="form-label">
              Phone Number
            </label>
            <input
              type="text"
              className="form-control"
              id="ph-number"
              placeholder="Enter Phone Number"
              value={phoneNumber}
              name="phoneNumber"
              onChange={handleChange}
              autoComplete="off"
              maxLength={10}
            />
          </div>
        </div>
        <div className="d-flex text-start dk-lead-wrapper mb-50">
          <button type="submit" className="btn primary-button">
            {lead !== 'signUp' ? "Send it!" : "Submit"}
          </button>
          {(lead !== 'plan' && lead !== 'qmi' && lead !== 'signUp')  && <p className="selected">You have selected {plansData?.length} Plans and {quickMoveInData?.length ? quickMoveInData?.length : 0 } QMI</p>}
        </div>
      </form>
    </div>
  );
};
