import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import rightArrow from "../../assets/images/right-arrow.svg";
import { assetTrim, damName, scale } from "../../constant";
import { fetchViewGalleryPage } from "../../services/designInspiration/designInspirationServices";

function ViewGallery({ setViewGallery, styleName }) {
	const dispatch = useDispatch();
	const [selectedStyleItem, setSelectedStyleItem] = useState(styleName?.assets?.[0]);

	const { kiosk, corporate, viewGalleryPage } = useSelector((state) => {
		return {
			kiosk: state.homeReducer.homepageData.kiosk,
			corporate: state.homeReducer.site,
			viewGalleryPage: state.designInspirationReducer.viewGalleryPageData.categories,
		};
	});
	useEffect(() => {
		if (corporate && !viewGalleryPage) {
			const neighborhoodCode = kiosk?.neighborhoods?.value[0]?.neighborhoodCode;
			dispatch(fetchViewGalleryPage(neighborhoodCode));
		}
	}, [corporate, kiosk, viewGalleryPage]);

	return (
		<>
			<section className="ccw-watch-video-wrapper drs-cviewgalleryWrapper p-0">
				<div className="testi-video-container h-100">
					<div className="d-flex testi-video-wrapper h-100">
						<div className="vg-photo-wrapper">
							<div
								className="background-properties"
								style={{
									backgroundImage: `url(${assetTrim(damName, selectedStyleItem?.asset_value, scale)}?scale=xl)`,
									minHeight: "100%",
								}}>
								{" "}
							</div>
						</div>
						<div className="vg-right-wrapper">
							<div className="text-center">
								<div onClick={() => setViewGallery(false)}>
									<img className="vg-arrow-up" src={rightArrow} alt="Arrow" />
								</div>
								<h1 className="vg-style-heading">
									<p className="title">{styleName?.name}</p>
								</h1>
							</div>
							<ul className="d-flex vg-images-list-wrapper flex-wrap">
								{styleName?.assets?.map((item) => {
									return (
										<li className="vg-image-list" key={item?.asset_value}>
											<div
												onClick={() => setSelectedStyleItem(item)}
												className={`position-relative vg-images-wrapper vg-gallery-one background-properties ${
													item?.asset_value === selectedStyleItem?.asset_value ? "active" : ""
												}`}
												style={{
													backgroundImage: `url(${assetTrim(damName, item?.asset_value, scale)}?scale=large)`,
												}}></div>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ViewGallery;
