import axios from "axios";
import env from "react-dotenv";

// const { REACT_APP_API_BASE_URL } = process.env;

// console.info(env.CONTENT_DELIVERY_API_BASE_URL);

const API = axios.create({
  baseURL: env.CONTENT_DELIVERY_API_BASE_URL,
  timeout: 9999999,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  (config) => {
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    config.headers.Expires = "-1";
    config.headers["Cache-Control"] = "no-cache,no-store,must-revalidate,max-age=-1,private";
    config.headers.Pragma = "no-cache";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { API };
