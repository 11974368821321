/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from "@reduxjs/toolkit";
import { fetchCommunityPage, fetchOur } from "../../services/community/communityServices";

const initialState = {
	loading: false,
	communityPageData: {},
	ourTeamData: [],
	ourTeam: [],
	error: "",
};

const communitySlice = createSlice({
	name: "community",
	initialState,
	reducers: {
		setPageStartId: (prevState, action) => {
			const state = prevState;
			state.pageStartId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCommunityPage.pending, (prevState, action) => {
			const state = prevState;
			state.loading = true;
		});
		builder.addCase(fetchCommunityPage.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.communityPageData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchCommunityPage.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.communityPageData = {};
			state.error = action.error.message;
		});
		builder.addCase(fetchOur.pending, (prevState, action) => {
			const state = prevState;
			state.loading = true;
		});
		builder.addCase(fetchOur.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.ourTeam = [...action.payload];
			state.error = "";
		});
		builder.addCase(fetchOur.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.ourTeam = [];
			state.error = action.error.message;
		});
	},
});

const { actions, reducer: communityReducer } = communitySlice;

export const {
	// export actions here
	setPageStartId,
} = actions;

export default { communityReducer };
