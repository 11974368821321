const getStore = (name) => {
  return JSON.parse(localStorage.getItem(name));
};

const setStoreArray = (name, payLoad, dataId) => {
  let data = JSON.parse(localStorage.getItem(name));
  const { plan_code, neighborhood_code } = dataId;
  if (data?.length > 0) {
    if (
      data?.some(
        (item) => item[plan_code] === payLoad[plan_code] && item[neighborhood_code] === payLoad[neighborhood_code]
      )
    ) {
      localStorage.setItem(name, JSON.stringify(data));
    } else {
      let dataToPush = [...data, payLoad];
      localStorage.setItem(name, JSON.stringify(dataToPush));
    }
  } else {
    localStorage.setItem(name, JSON.stringify([payLoad]));
  }
};
const removeStoreArray = (name, payLoad, dataId) => {
  let data = JSON.parse(localStorage.getItem(name));
  const { plan_code, neighborhood_code } = dataId;
  if (data?.length > 0) {
    const removedData = data?.filter(
      (item) => item[plan_code] !== payLoad[plan_code] || item[neighborhood_code] !== payLoad[neighborhood_code]
    );
    localStorage.setItem(name, JSON.stringify(removedData));
  }
};

export { getStore, setStoreArray, removeStoreArray };
