import { React, useEffect, useState } from "react";
import "./plans.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlansPage } from "../../services/plans/plansServices";
import { useLocation } from "react-router-dom";
import { PlanCollection } from "./PlanCollection";
import { PlansLanding } from "./PlansLanding";
import { CurveAnimation } from "../components/CurveAnimation";

export const Plans = (props) => {
	const { setCustomeClassName } = props;
	const location = useLocation();
	const animation = location.state?.animation;
	const [showPlansImage, setShowPlansImage] = useState(animation);
	const dispatch = useDispatch();
	const data = location.state?.data

	const { corporate, neighbourhoodData, plansCommunityPage, kiosk, subContent } = useSelector((state) => {
		return {
			loading: state.plansReducer.loading,
			error: state.plansReducer.error,
			corporate: state?.homeReducer?.site,
			neighbourhoodData: state.plansReducer.neighborhoodData?.neighbourhoodData,
			plansCommunityPage: state.plansReducer.plansPageData.plansCommunityPage,
			subContent: state.homeReducer.subContent,
			kiosk: state.homeReducer.homepageData.kiosk,
		};
	});

	useEffect(() => {
		if (showPlansImage) {
			const toRef = setTimeout(() => {
				setShowPlansImage(false);
				clearTimeout(toRef);
			}, 2500);
		}
	}, [showPlansImage]);

	useEffect(() => {
		if (corporate && kiosk?.contentLink?.id !== plansCommunityPage?.parentLink?.id) {
			setCustomeClassName("");
			dispatch(fetchPlansPage({ subContent, kiosk }));
		}
	}, [corporate, subContent, kiosk]);

	return (
		<div>
			{showPlansImage ? (
				<CurveAnimation pageName={"Plans"} />
			) : neighbourhoodData?.length === 1 ? (
				neighbourhoodData?.map((item) => {
					return <PlanCollection item={data? data : item} />;
				})
			) : (
				<PlansLanding />
			)}
		</div>
	);
};
