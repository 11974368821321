import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContentLoader } from "@episerver/content-delivery";
import { ExplorePage } from "../../constant";

const contentLoader = new ContentLoader();

export const fetchExplorePromotionsPage = createAsyncThunk(
  "explorePage/fetchExplorePromotionsPage",
  async (subContent) => {
    const explorePage = subContent?.find((item) => {
      return item?.contentType?.includes(ExplorePage);
    });

    const leftImage1 = explorePage?.explore_menu_left_side_image_1_url?.value
      ? await contentLoader.getContent(
          explorePage?.explore_menu_left_side_image_1_url?.value?.id,
          {
            branch: "en",
          }
        )
      : {};

    const leftImage2 = explorePage?.explore_menu_left_side_image_2_url?.value
      ? await contentLoader.getContent(
          explorePage?.explore_menu_left_side_image_2_url?.value?.id,
          {
            branch: "en",
          }
        )
      : {};

    const rightImage1 = explorePage?.explore_menu_right_side_image_1_url?.value
      ? await contentLoader.getContent(
          explorePage?.explore_menu_right_side_image_1_url?.value?.id,
          {
            branch: "en",
          }
        )
      : {};

    const rightImage2 = explorePage?.explore_menu_right_side_image_2_url?.value
      ? await contentLoader.getContent(
          explorePage?.explore_menu_right_side_image_2_url?.value?.id,
          {
            branch: "en",
          }
        )
      : {};

    const rightImage3 = explorePage?.explore_menu_right_side_image_3_url?.value
      ? await contentLoader.getContent(
          explorePage?.explore_menu_right_side_image_3_url?.value?.id,
          {
            branch: "en",
          }
        )
      : {};

    const promoLinks = {
      leftImage1,
      leftImage2,
      rightImage1,
      rightImage2,
      rightImage3,
    };

    return {
      explorePage,
      promoLinks,
    };
  }
);
