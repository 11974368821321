import React, { useEffect, useState } from "react";
import FavDefault from "../../../assets/images/Favorites-icon-default.svg";
import FavIcon from "../../../assets/images/Favorites-icon.svg";
import { getStore, removeStoreArray, setStoreArray } from "../../../services/localStoreService";

export const FavoritesIcon = ({ state, setState, details, setToggle, toggle }) => {
	const [plansData, setPlansData] = useState([]);
	const [forceRender, setForceRender] = useState(false);
	useEffect(() => {
		if (details?.type === "qmi") {
			const plansDatas = getStore("quickMoveIn");
			setPlansData(plansDatas);
		} else {
			const plansDatas = getStore("plans");
			setPlansData(plansDatas);
		}
	}, [forceRender, state.toggle]);

	return (
		<div className="">
			<button className="pv-fav-btn">
				{details?.type === "qmi" ? (
					<img
						src={plansData?.some((ele) => ele?.job_number === details?.job_number) ? FavIcon : FavDefault}
						alt="Favorites icon"
						onClick={() => {
							if (plansData?.some((ele) => ele?.job_number === details?.job_number)) {
								removeStoreArray("quickMoveIn", details, {
									plan_code: "job_number",
									neighborhood_code: "neighborhood_code",
								});
							} else {
								setStoreArray("quickMoveIn", details, {
									plan_code: "job_number",
									neighborhood_code: "neighborhood_code",
								});
							}
							setForceRender(!forceRender);
							setState({ toggle: !state.toggle });
							if (setToggle) {
								setToggle(!toggle);
							}
						}}
					/>
				) : (
					<img
						src={
							plansData?.some(
								(ele) =>
									ele?.plan_code === details?.plan_code && ele?.neighborhood_code === details?.neighborhood_code
							)
								? FavIcon
								: FavDefault
						}
						alt="Favorites icon"
						onClick={() => {
							if (
								plansData?.some(
									(ele) =>
										ele?.plan_code === details?.plan_code && ele?.neighborhood_code === details?.neighborhood_code
								)
							) {
								removeStoreArray("plans", details, {
									plan_code: "plan_code",
									neighborhood_code: "neighborhood_code",
								});
							} else {
								setStoreArray("plans", details, {
									plan_code: "plan_code",
									neighborhood_code: "neighborhood_code",
								});
							}
							setForceRender(!forceRender);
							setState({ toggle: !state.toggle });
							if (setToggle) {
								setToggle(!toggle);
							}
						}}
					/>
				)}
			</button>
		</div>
	);
};
