import { React, useEffect, useState } from "react";
import "./favorite.scss";
import { RightNav } from "../../app/component/RightNav";
import { getStore, removeStoreArray } from "../../services/localStoreService";
import { Offcanvas } from "../components/Offcanvas";
import { Tab, Tabs } from "react-bootstrap";
import { DetailedCard } from "../components/DetailedCard";
import { ThankYouBanner } from "../components/ThankYouBanner";
import { SendPlansAndHomes } from "./SendPlansAndHomes";

export const Favorite = (props) => {
	const { setCustomeClassName } = props;
	const [state, setState] = useState({ sendMyPlanHomeBtn: false });
	const [toggle, setToggle] = useState(false);
	const [plansData, setPlansData] = useState([]);
	const [quickMoveInData, setQuickMoveInData] = useState([]);
	const [favoritesData, setFavoritesData] = useState([]);

	useEffect(() => {
		const plansDatas = getStore("plans");
		const quickMoveInDatas = getStore("quickMoveIn");
		setPlansData(plansDatas);
		setQuickMoveInData(quickMoveInDatas);
		if (plansDatas !== null && quickMoveInDatas !== null) {
			setFavoritesData([...plansDatas, ...quickMoveInDatas]);
		} else if(quickMoveInDatas !== null && plansDatas === null ) {
			setFavoritesData([...quickMoveInDatas])
		} else if(plansDatas !== null && quickMoveInDatas === null) {
			setFavoritesData([...plansDatas])
		} else {
			setFavoritesData([])
		}
	}, [toggle]);

	const [show, setShow] = useState(false);

	useEffect(() => {
		setCustomeClassName("favorites");
	}, []);

	const handleClose = () => {
		setShow(false);
	};
	const handleSignUpSuccess = () => {
		setShow(true);
	};

	return (
		<>
			<div>
				<div className="dk-MainContentWrapper">
					<div className="fav-bottom-container RightMenu">
						<div className="fav-top-wrapper">
							<h2 className="fav-title">FAVORITES</h2>
							<p className="fav-desc">
								You've got great taste! Manage your saved items or email them to yourself.
							</p>
						</div>
						<div className="favorites-wrapper drs-tabstyle">
							<Tabs>
								<Tab eventKey="plans" style={{ textAlign: "center" }} unmountOnExit>
									<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
										<div className="text-end pb-25">
											<button
												type="submit"
												className="btn primary-button fav-button cc-drees-smart-btn "
												disabled={favoritesData?.length < 1}
												onClick={() => {
													setState({ sendMyPlanHomeBtn: true });
												}}>
												Send My Plans & Homes
											</button>
										</div>
										<div className="communities-container fav-container d-flex flex-wrap">
											{favoritesData?.map((item, index) => {
												return (
													<div className="card">
														<DetailedCard
															plan={item}
															setToggle={setToggle}
															toggle={toggle}
															plansData={favoritesData}
															component={"favorites"}
															removeStoreArray={removeStoreArray}
														/>
													</div>
												);
											})}
										</div>
									</div>
								</Tab>
							</Tabs>
						</div>
						<Offcanvas
							show={state.sendMyPlanHomeBtn}
							placement="top"
							offcanvasClassName="view-ele-offcanvas ccw-watch-video-wrapper h-100 signupWrapper"
							onHide={() => setState({ sendMyPlanBtn: false })}
							bodyClassName="ccw-content-wrapper">
							<SendPlansAndHomes
								setStateToggle={setState}
								plansData={plansData}
								quickMoveInData={quickMoveInData}
								handleSignUpSuccess={handleSignUpSuccess}
							/>
						</Offcanvas>
						<ThankYouBanner show={show} handleClose={handleClose} />
					</div>

					<RightNav />
				</div>
			</div>
		</>
	);
};
