import React, { useEffect, useState } from "react";
import FavIcon from "../../assets/images/Favorites-icon.svg";
import FavDefault from "../../assets/images/Favorites-icon-default.svg";
import LocationPin from "../../assets/images/pin.svg";
import { assetTrim, commaSeperator, damName, defaultImage, scale } from "../../constant";
import { Link } from "react-router-dom";
import { PlanView } from "../plans/PlanView";
import { Offcanvas } from "./Offcanvas";

export const DetailedCard = ({
	plan,
	toggle,
	setToggle,
	setStoreArray,
	removeStoreArray,
	data,
	plansDetails,
	plansData,
	component,
	plansQmiPage,
}) => {
	const [state, setState] = useState({ planView: false });
	return (
		<>
			<div className="card-body d-flex flex-column">
				<div className="card-body-top-wrapper">
					<>
						{plan?.type === "qmi" ? (
							<h3 className="card-title d-flex align-items-start justify-content-between">
								<span className="text-start">{plan?.plan_market_name}</span>
								<img
									src={plansData?.some((item) => item.job_number === plan.job_number) ? FavIcon : FavDefault}
									alt="Favorites icon"
									onClick={() => {
										if (plansData?.some((item) => item.job_number === plan.job_number)) {
											removeStoreArray("quickMoveIn", plan, {
												plan_code: "job_number",
												neighborhood_code: "neighborhood_code",
											});
										} else {
											setStoreArray("quickMoveIn", plan, {
												plan_code: "job_number",
												neighborhood_code: "neighborhood_code",
											});
										}
										setToggle(!toggle);
									}}
								/>
							</h3>
						) : (
							<h3 className="card-title d-flex align-items-start justify-content-between">
								<span className="text-start">{plan?.market_name}</span>
								<img
									src={
										plansData?.some(
											(item) =>
												item.plan_code === plan.plan_code && item.neighborhood_code === plan.neighborhood_code
										)
											? FavIcon
											: FavDefault
									}
									alt="Favorites icon"
									onClick={() => {
										if (
											plansData?.some(
												(item) =>
													item.plan_code === plan.plan_code && item.neighborhood_code === plan.neighborhood_code
											)
										) {
											removeStoreArray("plans", plan, {
												plan_code: "plan_code",
												neighborhood_code: "neighborhood_code",
											});
										} else {
											setStoreArray("plans", plan, {
												plan_code: "plan_code",
												neighborhood_code: "neighborhood_code",
											});
										}
										setToggle(!toggle);
									}}
								/>{" "}
							</h3>
						)}
					</>
					<div className="card-text text-start">
						{plan?.type === "qmi" ? (
							<div>
								{plan?.was_price !== 0 ? (
									<span className="card-number" style={{ textDecorationLine: "line-through" }}>
										${commaSeperator(plan?.was_price)}
									</span>
								) : null}
								{plan?.price ? (
									<span className="activePrice">${commaSeperator(plan?.price)}</span>
								) : (
									<span className="activePrice">Price Coming Soon</span>
								)}
							</div>
						) : (
							<div className="favoriteCardPrice">
								{plan?.display_price_low === plan?.display_price_high ? (
									<span>${commaSeperator(plan?.display_price_low)}</span>
								) : (
									<>
										<span>${commaSeperator(plan?.display_price_low)}</span> -{" "}
										<span>${commaSeperator(plan?.display_price_high)}</span>
									</>
								)}
							</div>
						)}
					</div>
					{plan?.type === "qmi" && (
						<p className="pqm-card-location-info text-start">
							<img className="" src={LocationPin} alt="Location icon" />
							<span>
								{" "}
								{plan?.address}, {plan?.city}, {plan?.state_code} {plan?.neighborhood_zip}
							</span>
						</p>
					)}
					<div className="planCardImageWrapper">
						{plan?.assets?.length === 0 ? (
							<img src={defaultImage} className="card-img-top " alt="Imge of Community" />
						) : (
							<img
								src={`${assetTrim(damName, plan?.assets?.[0]?.asset_value, scale)}?scale=medium`}
								className="card-img-top "
								alt="Imge of Community"
							/>
						)}
					</div>
				</div>
				<div className="card-body-mid-wrapper">
					<hr className="fav-separator" />
					<p className="fav-desc-text">
						{plan?.type === "qmi" ? (
							<span>{commaSeperator(plan?.square_feet)} sq ft</span>
						) : plan?.sqft_low === plan?.sqft_high ? (
							<span>{commaSeperator(plan?.sqft_low)} sq ft</span>
						) : (
							<span>
								{commaSeperator(plan?.sqft_low)} - {commaSeperator(plan?.sqft_high)} sq ft
							</span>
						)}
						{plan?.type === "qmi" ? (
							<span>{plan?.stories} Stories</span>
						) : plan?.stories_min === plan?.stories_max ? (
							<span>{plan?.stories_min} Stories</span>
						) : (
							<span>
								{plan?.stories_min} - {plan?.stories_max} Stories
							</span>
						)}
						{plan?.type === "qmi" ? (
							<span>{plan?.bedrooms} Beds</span>
						) : plan?.bedrooms_min === plan?.bedrooms_max ? (
							<span>{plan?.bedrooms_min} Beds</span>
						) : (
							<span>
								{plan?.bedrooms_min} - {plan?.bedrooms_max} Beds
							</span>
						)}{" "}
					</p>
					<p className="fav-desc-text">
						{plan?.type === "qmi" ? (
							<span>{plan?.full_baths} Baths</span>
						) : plan?.full_baths_min === plan?.full_baths_max ? (
							<span>{plan?.full_baths_min} Baths</span>
						) : (
							<span>
								{plan?.full_baths_min} - {plan?.full_baths_max} Baths
							</span>
						)}
						{plan?.type === "qmi" ? (
							<span>{plan?.half_baths} Half Baths</span>
						) : plan?.half_baths_min === plan?.half_baths_max ? (
							<span>{plan?.half_baths_min} Half Baths</span>
						) : (
							<span>
								{plan?.half_baths_min} - {plan?.half_baths_max} Half Baths
							</span>
						)}
						{plan?.type === "qmi" ? (
							<span>{plan?.garage} Car Garage</span>
						) : plan?.garage_spaces_min === plan?.garage_spaces_max ? (
							<span>{plan?.garage_spaces_min} Car Garage</span>
						) : (
							<span>
								{plan?.garage_spaces_min} - {plan?.garage_spaces_max} Car Garage
							</span>
						)}{" "}
					</p>
					<hr className="fav-separator sprfavlast" />
				</div>
				{component === "favorites" ? (
					<div>
						<button
							type="submit"
							className="btn primary-button w-100"
							onClick={() => setState({ planView: true })}>
							{plan?.type === 'plan' ? 'View Plan' : "View Quick Move-In"}
						</button>
						<Offcanvas
							show={state.planView}
							offcanvasClassName="ccw-watch-video-wrapper w-100 planViewWrapper"
							onHide={() => setState({ planView: false })}
							bodyClassName="testimonial-offcanvasbody view-elevation-wrapper">
							<PlanView item={plan} setPlanView={setState} toggle={toggle} setToggle={setToggle} />
						</Offcanvas>
					</div>
				) : (
					<Link
						to="/planView"
						state={{
							plan: plan,
							data: data,
							plansDetails: plansDetails,
							plansQmiPage: plansQmiPage,
							animation: undefined,
						}}
						className="w-100">
						<button type="submit" className="btn primary-button w-100">
							View Plan
						</button>
					</Link>
				)}
			</div>
		</>
	);
};
