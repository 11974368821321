import React, { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import IdleTimer from "react-idle-timer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function SessionTimeOut() {
  const [show, setShow] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const { corporate } = useSelector((state) => {
    return {
      corporate: state?.homeReducer?.site,
    };
  });
  const timer = corporate?.reset_timeframe?.value * 1000 * 60;
  const pop_up_timer = corporate?.pop_up_reset_timeframe?.value * 1000 * 60;

  const onIdle = () => {
    setShow(true);
    sessionTimeoutRef.current = setTimeout(clearData, pop_up_timer ? pop_up_timer : 300000);
  };

  const clearData = () => {
    localStorage.removeItem("plans");
    localStorage.removeItem("formDetails");
    localStorage.removeItem("quickMoveIn");
    localStorage.removeItem("data");
    localStorage.removeItem("plan");
    localStorage.removeItem("qmi");
    localStorage.removeItem('ourteam')
    localStorage.removeItem('image')
    setShow(false);
    clearTimeout(sessionTimeoutRef.current);
    navigate("/");
  };

  const stayActive = () => {
    setShow(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          stayActive();
        }}
        className="Modal-popover modal-Signup d-flex align-items-center justify-content-center">
        <Modal.Header closeButton className="ccw-close-wrapper"></Modal.Header>
        <Modal.Body>
          <p className="text-center mr-t15">
            {corporate?.reset_title?.value ? corporate?.reset_title?.value : "Do you want to continue?"}
          </p>
          <br />
          <div className="text-center">
            <button
              type="submit"
              className="btn primary-button fav-button cc-drees-smart-btn mr-25 "
              onClick={() => {
                setShow(false);
                stayActive();
              }}>
              YES
            </button>{" "}
            <button type="submit" className="btn primary-button fav-button cc-drees-smart-btn" onClick={clearData}>
              NO
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <IdleTimer ref={idleTimerRef} timeout={timer ? timer : 900000} onIdle={onIdle} />
    </div>
  );
}

export default SessionTimeOut;
