import React from "react";
import { commaSeperator } from "../../../constant";

export const PlanViewHeading = ({ details }) => {
	return (
		<div className="d-flex justify-content-between pb-35">
			<div>
				{details.plan_market_name ? (
					<h1 className="pl-subheading mb-0 white-color">{details?.plan_market_name}</h1>
				) : (
					<h1 className="pl-subheading mb-0 white-color pv-heading">{details?.market_name}</h1>
				)}

				{details?.type === "qmi" && (
					<p className="pl-plan-cost address white-color">
						{details?.address}, {details?.city}, {details?.state_code} {details?.neighborhood_zip}
					</p>
				)}
			</div>
			{details?.type === "qmi" ? (
				<p className="pl-plan-cost white-color">
					{details?.was_price !== 0 ? (
						<span className="card-number closePrice" style={{ textDecorationLine: "line-through" }}>
							${commaSeperator(details?.was_price)}
						</span>
					) : null}
					{details?.price !== 0 ? (
						<span className="activePrice">${commaSeperator(details?.price)}</span>
					) : (
						<span className="activePrice">Price Coming Soon</span>
					)}
				</p>
			) : (
				<p className="pl-plan-cost white-color">
					{details?.display_price_low === details?.display_price_high ? (
						<span>${commaSeperator(details?.display_price_low)}</span>
					) : (
						<>
							<span>${commaSeperator(details?.display_price_low)}</span> -{" "}
							<span>${commaSeperator(details?.display_price_high)}</span>
						</>
					)}
				</p>
			)}
		</div>
	);
};
