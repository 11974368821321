import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContentLoader } from "@episerver/content-delivery";

const contentLoader = new ContentLoader();

export const ourStoryPage = createAsyncThunk("ourStory/fetchOurStoryPage", async ({ children, corporate }) => {
	const ourStoryPageKiosk = children?.find((item) => {
		return item?.contentType?.includes("OurStoryPage");
	});
	const readOurStoryCorp = await Promise.all(
		corporate?.read_our_story_content_area?.value?.map(async (item) => {
			const testimonial = await contentLoader.getContent(item?.contentLink?.guidValue, { branch: "en" });
			return testimonial;
		})
	);

	return { ourStoryPageKiosk, readOurStoryCorp };
}); // end
