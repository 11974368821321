import { React, useEffect } from "react";
import { Header } from "../../app/component/Header";
import ViewBackground from "../../assets/images/Floorplan-bg.png";
import { RightNav } from "../../app/component/RightNav";
import "./planView.scss";
import "./viewElevation.scss";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlansDetails, fetchPlansPage, fetchQmiDetails } from "../../services/plans/plansServices";
import { ComparePlanDetail } from "./ComparePlanDetail";
import { useState } from "react";
import { assetTrim, damName } from "../../constant";
import _ from "lodash";
import { ComparePlanListing } from "./ComparePlanListing";
import { Offcanvas } from "../components/Offcanvas";
import { ViewElevations } from "./ViewElevations";
import { SendMeThisPlan } from "./SendMeThisPlan";
import { SendMeThisHome } from "./SendMeThisHome";
import { PlanViewTable } from "./PlanViewTable";
import { ThankYouBanner } from "../components/ThankYouBanner";
import { BackArrow } from "./planViewComponents/BackArrow";
import { FavoritesIcon } from "./planViewComponents/FavoritesIcon";
import { PlanViewAssets } from "./planViewComponents/PlanViewAssets";
import { PlanViewHeading } from "./planViewComponents/PlanViewHeading";
import { PlanViewVideoTour } from "./planViewComponents/PlanViewVideoTour";
import { PlanViewFloorMap } from "./planViewComponents/PlanViewFloorMap";
import Loader from "../../app/component/loader/Loader";

export const PlanView = ({ item, comparePlanDetails, setPlanView, toggle, setToggle }) => {
	const location = useLocation();
	const dispatch = useDispatch();

	const [key, setKey] = useState("video");
	const [state, setState] = useState({
		planVideoTourBtn: false,
		viewElevationsBtn: false,
		comparePlansBtn: false,
		interactiveFloorPlanBtn: false,
		designSectionsBtn: false,
		comparePlansDetailsBtn: false,
		sendPlanBtn: false,
		toggle: false,
	});

	const [videoBtnShow, setVideoBtnShow] = useState(false);
	const [tourBtnShow, setTourBtnShow] = useState(false);
	const [comparePlans, setComparePlans] = useState([]);
	const [planData, setPlanData] = useState([]);
	const [compareData, setCompareData] = useState({});
	const [checkedPlans, setCheckedPlans] = useState(0);
	const [persistData, setPersistData] = useState({});
	const [persistPlan, setPersistPlan] = useState({});
	const [loading, setLoading] = useState(localStorage.getItem('data') ? false : true)

	const { corporate, neighbourhoodData, plansCommunityPage, kiosk, subContent, qmiDetails, plansDetails } =
	useSelector((state) => {
		return {
			corporate: state?.homeReducer?.site,
			subContent: state.homeReducer.subContent,
			plansCommunityPage: state?.plansReducer.plansPageData.plansCommunityPage,
			neighbourhoodData: state.plansReducer.neighborhoodData?.neighbourhoodData,
			plansDetails: state?.plansReducer?.plansDetails,
			kiosk: state.homeReducer.homepageData.kiosk,
			qmiDetails: state?.plansReducer?.qmiDetails,
		};
	});

	const localStorageData = localStorage.getItem('data')

	useEffect(() => {
		if (location.state?.plan) {
			localStorage.setItem("plan", JSON.stringify(location.state?.plan));
		}
	}, [location.state?.plan]);

	useEffect(() => {
		if (localStorage.getItem("plan")) {
			const persistPlan = JSON.parse(localStorage.getItem("plan"));
			setPersistPlan(persistPlan);
		}
	}, [localStorage.getItem("plan")]);

	useEffect(() => {
		if (localStorageData) {
			setLoading(false)
			const persistData = JSON.parse(localStorageData);
			setPersistData(persistData);
		}
	}, [localStorageData]);

	const plan = planData.length > 0 ? planData : location.state?.plan ? location.state?.plan : persistPlan;
	const data = compareData?.code ? compareData : location.state?.data ? location.state?.data : persistData;
	const params = new URLSearchParams(location.search);

	useEffect(()=> {
		if(params?.get("neighborhood_code")){
			localStorage.removeItem('data')
			setPersistData({})
			setLoading(true)
		}
	},[])

	useEffect(() => {
		if (!localStorageData && neighbourhoodData) {
			const neighborhoodCode = params?.get("neighborhood_code")?.toUpperCase()
			
			const data = neighbourhoodData?.filter((item) => {
				return item?.code === neighborhoodCode
			})
			if(data?.length > 0) {
				setLoading(false)
				localStorage.setItem('data', JSON.stringify(data?.[0]))
			}
		}
	}, [neighbourhoodData, localStorageData])


	const plan_code =
		params.get("plan_code") !== null
			? params.get("plan_code").toUpperCase()
			: item
			? item?.plan_code
			: plan
			? plan?.plan_code
			: "";
	const job =
		params.get("job_number") !== null
			? params.get("job_number").toUpperCase()
			: item
			? item?.job_number
			: plan?.job_number;
	const neighborhood_code =
		params.get("neighborhood_code") !== null
			? params.get("neighborhood_code").toUpperCase()
			: item
			? item?.neighborhood_code
			: plan?.neighborhood_code;

	const [checkedPlansDetails, setCheckedPlansDetails] = useState([plan?.plan_code]);
	const [show, setShow] = useState(false);

	const details = qmiDetails?.type ? qmiDetails : comparePlanDetails ? comparePlanDetails : plansDetails;
	const selected = (index) => {
		const checks = document.querySelectorAll(".compare");
		let total = 0;
		for (let i = 0; i < checks.length; i++) {
			if (checks[i].checked) {
				total += 1;
			}
		}
		setCheckedPlans(total);
		if (total > 2) {
			checks[index].checked = false;
		}
		return false;
	};
	const handleSubmit = () => {
		const checked = document.querySelectorAll('input[type="checkbox"]:checked');
		const selected = [...checked].map((c) => c.value);
		setCheckedPlansDetails([plan?.plan_code, ...selected]);
	};
	useEffect(() => {
		if (params.get("plan_code") !== null && params.get("neighborhood_code") !== null) {
			neighbourhoodData?.map((item) => {
				if (item?.code === neighborhood_code) {
					setCompareData(item);
				}
				item.plans.map((ele) => {
					if (ele?.plan_code === plan_code) {
						setPlanData(ele);
					}
				});
			});
		} else if (params.get("job_number") !== null) {
			neighbourhoodData?.map((item) => {
				if (item?.code === qmiDetails?.neighborhood_code) {
					setCompareData(item);
				}
			});
		}
	}, [neighbourhoodData, qmiDetails]);

	useEffect(() => {
		if (corporate) {
			dispatch(fetchPlansDetails({ plan_code, neighborhood_code }));
		}
	}, [corporate]);

	useEffect(() => {
		if (corporate) {
			dispatch(fetchQmiDetails(job));
		}
	}, [corporate]);

	useEffect(() => {
		if (corporate && !plansCommunityPage) {
			dispatch(fetchPlansPage({ subContent, kiosk }));
		}
	}, [corporate, plansCommunityPage]);

	useEffect(() => {
		const video = details?.assets?.some((asset) => {
			if (asset?.content_type_name === "youtube") {
				return asset?.asset_value;
			} else if (asset?.content_type === "youtube") {
				return asset?.asset_value;
			} else {
				return null;
			}
		});
		setVideoBtnShow(video);

		const tour = details?.assets?.some((asset) => {
			if (asset?.content_type_name === "matterport") {
				return asset?.asset_value;
			} else if (asset?.content_type === "matterport") {
				return asset?.asset_value;
			} else {
				return null;
			}
		});
		setTourBtnShow(tour);
	}, [details]);

	useEffect(() => {
		const cpl = data?.plans?.filter((ele) => {
			return ele?.plan_code !== plan?.plan_code;
		});
		setComparePlans(cpl);
	}, [data, plan]);

	const setStateToggle = (button) => {
		setState(button);
	};

	const handleSignUpSuccess = () => {
		setShow(true);
	};

	const handleClose = () => {
		setShow(false);
	};
	return (
		<div>
			{loading && <Loader/>}
			{!comparePlanDetails && !item && <Header />}
			<div className="dk-MainContentWrapper">
				<section className="RightMenu pp-view-wrapper d-flex">
					<div
						className="pv-view-left background-properties"
						style={{ backgroundImage: `url(${ViewBackground})` }}>
						<div className="d-flex justify-content-between pv-view-top-wrapper pb-35">
							{/* back arrow */}
							<BackArrow
								item={item}
								comparePlanDetails={comparePlanDetails}
								plan={plan}
								neighbourhoodData={neighbourhoodData}
								setPlanView={setPlanView}
								data={persistData}
								kiosk={kiosk}
								details={details}
							/>

							{/* fav icon */}
							<FavoritesIcon
								setToggle={setToggle}
								toggle={toggle}
								state={state}
								setState={setState}
								details={details}
							/>
						</div>

						{/* sub heading amd price */}
						<PlanViewHeading details={details} />

						{/* assets */}
						<PlanViewAssets details={details} />

						{/* planView Table */}
						<div className="pb-35">
							<PlanViewTable details={details} />
						</div>

						{/*  button section */}
						<div className="text-center">
							{videoBtnShow && (
								<button
									className="btn pl-explore-btn vpl-plan-btn primary-button"
									onClick={() => {
										setState({ planVideoTourBtn: true });
										setKey("video");
									}}>
									Video
								</button>
							)}

							{tourBtnShow && (
								<button
									className="btn pl-explore-btn vpl-plan-btn primary-button"
									onClick={() => {
										setState({ planVideoTourBtn: true });
										setKey("tour");
									}}>
									Tour
								</button>
							)}
							{details?.type === "qmi" && qmiDetails?.design_choice?.url && (
								<button
									className="btn pl-explore-btn vpl-plan-btn primary-button"
									onClick={() => {
										setState({ designSectionsBtn: true });
									}}>
									Design Selections
								</button>
							)}

							{details?.type !== "qmi" &&
								details?.assets?.filter((item) => item.image_type === "rendering")?.length > 0 && (
									<button
										className="btn pl-explore-btn vpl-plan-btn primary-button outlined-btn"
										onClick={() => setState({ viewElevationsBtn: true })}>
										View Elevations
									</button>
								)}

							{qmiDetails?.type !== "qmi" && !comparePlanDetails && !item && (
								<button
									className="btn pl-explore-btn vpl-plan-btn primary-button outlined-btn"
									onClick={() => setState({ comparePlansBtn: true })}>
									Compare Plans
								</button>
							)}

							<Offcanvas
								show={state.planVideoTourBtn}
								offcanvasClassName="ccw-watch-video-wrapper w-100"
								onHide={() => setState({ planVideoTourBtn: false })}
								bodyClassName="drs-tabstyle"
								headerClassName="ccw-close-wrapper video-tab-close">
								<PlanViewVideoTour
									details={details}
									videoBtnShow={videoBtnShow}
									tourBtnShow={tourBtnShow}
									setKey={setKey}
									Key={key}
								/>
							</Offcanvas>

							<Offcanvas
								show={state.designSectionsBtn}
								offcanvasClassName="view-ele-offcanvas ccw-watch-video-wrapper w-100"
								onHide={() => setState({ designSectionsBtn: false })}
								bodyClassName="testimonial-offcanvasbody view-elevation-wrapper">
								<iframe
									title="design choice"
									width="100%"
									height="90%"
									src={`${qmiDetails?.design_choice?.url}`}></iframe>
							</Offcanvas>

							<Offcanvas
								show={state.viewElevationsBtn}
								offcanvasClassName="view-ele-offcanvas ccw-watch-video-wrapper w-100"
								onHide={() => setState({ viewElevationsBtn: false })}
								bodyClassName="testimonial-offcanvasbody view-elevation-wrapper">
								<ViewElevations
									elevations={details?.assets?.filter((item) => item.image_type === "rendering")}
									marketName={details?.market_name}
								/>
							</Offcanvas>

							<Offcanvas
								show={state.comparePlansBtn}
								closeButton={false}
								offcanvasClassName="compare-wrapper planCompare"
								onHide={() => setState({ comparePlansBtn: false })}>
								<ComparePlanListing
									setState={setState}
									plan={plan}
									handleCompare={handleSubmit}
									comparePlans={comparePlans}
									selected={selected}
									checkedPlansDetails={checkedPlansDetails}
									checkedPlans={checkedPlans}
									setCheckedPlans={setCheckedPlans}
								/>
							</Offcanvas>

							<Offcanvas
								show={state.comparePlansDetailsBtn}
								offcanvasClassName="compare-wrapper w-100"
								closeButton={false}
								onHide={() => {
									setState({ comparePlansDetailsBtn: false });
									setCheckedPlans(0);
								}}>
								<ComparePlanDetail
									setstate={setState}
									state={state}
									checkedPlansDetails={checkedPlansDetails}
									data={data}
									selected={selected}
									handleSubmit={handleSubmit}
									plan={plan}
									checkedPlans={checkedPlans}
									setCheckedPlans={setCheckedPlans}
									comparePlans={comparePlans}
								/>
							</Offcanvas>
						</div>
					</div>
					{/* interactive button and send plan */}
					<div className="pv-view-right d-flex justify-content-between flex-column">
						<div className="text-center pv-right-top">
							{details?.interactive_floor_plans && (
								<button
									className="btn pl-explore-btn vp-plan-btn primary-button btn-dgreen"
									onClick={() => setState({ interactiveFloorPlanBtn: true })}>
									See The Interactive Plan
								</button>
							)}
							<button
								className="btn pl-explore-btn vp-plan-btn primary-button btn-dgreen"
								onClick={() => setState({ sendPlanBtn: true })}>
								{details?.type === "qmi" ? "Send Me This Home" : "Send Me This Plan"}
							</button>
							<Offcanvas
								show={state.interactiveFloorPlanBtn}
								scroll={true}
								offcanvasClassName="ccw-watch-video-wrapper w-100"
								onHide={() => setState({ interactiveFloorPlanBtn: false })}>
								<iframe
									title="interactive floor plan"
									className="drs-iframe-SeeIntractivePlan"
									src={assetTrim(damName, details?.interactive_floor_plan_url?.replace("http", "https"))}></iframe>
							</Offcanvas>
							<Offcanvas
								show={state.sendPlanBtn}
								offcanvasClassName="view-ele-offcanvas ccw-watch-video-wrapper h-100 signupWrapper"
								onHide={() => setState({ sendPlanBtn: false })}
								placement="top"
								bodyClassName="testimonial-offcanvasbody view-elevation-wrapper">
								{job ? (
									<SendMeThisHome setStateToggle={setStateToggle} handleSignUpSuccess={handleSignUpSuccess} />
								) : (
									<SendMeThisPlan setStateToggle={setStateToggle} handleSignUpSuccess={handleSignUpSuccess} />
								)}
							</Offcanvas>
							<ThankYouBanner show={show} handleClose={handleClose} />
						</div>
						{/* floor map */}
						<PlanViewFloorMap details={details} />
					</div>
				</section>
			</div>
			<RightNav />
		</div>
	);
};
