import React from "react";
import { Link } from "react-router-dom";
import LeftArrow from "../../../assets/images/prev-arrow-white.svg";
import { capitalizeEachWord } from "../../../constant";

export const BackArrow = ({ item, comparePlanDetails, plan, setPlanView, neighbourhoodData, data, kiosk, details }) => {
	return (
		<>
			{item ? (
				<div
					className="pv-back-to-landing d-flex align-items-center"
					style={{ cursor: "pointer" }}
					onClick={() => setPlanView(false)}>
					<img className="pv-backarrow" src={LeftArrow} alt="Arrow left icon" />
					Back to Favorites
				</div>
			) : comparePlanDetails ? (
				<div
					className="pv-back-to-landing d-flex align-items-center"
					style={{ cursor: "pointer" }}
					onClick={() => setPlanView(false)}>
					<img className="pv-backarrow" src={LeftArrow} alt="Arrow left icon" />
					Back to Compare Plans
				</div>
			) : details?.type === "qmi" ? (
				<Link
					to="/planQuickMoveIn"
					className="pv-back-to-landing d-flex align-items-center"
					state={{ data: data, plan: plan }}>
					<div>
						<img className="pv-backarrow" src={LeftArrow} alt="Arrow left icon" />
						Back to {capitalizeEachWord(kiosk?.title?.value)} Quick Move-In's
					</div>
				</Link>
			) : neighbourhoodData?.length === 1 ? (
				<Link to="/plans" className="pv-back-to-landing d-flex align-items-center" state={{ data: data }}>
					<div>
						<img className="pv-backarrow" src={LeftArrow} alt="Arrow left icon" />
						Back to {capitalizeEachWord(data?.name)} Plans
					</div>
				</Link>
			) : (
				<Link to="/planCollection" className="pv-back-to-landing d-flex align-items-center" state={{ data: data }}>
					<div>
						<img className="pv-backarrow" src={LeftArrow} alt="Arrow left icon" />
						Back to {capitalizeEachWord(data?.name)} Plans
					</div>
				</Link>
			)}
		</>
	);
};
