import { createSlice } from "@reduxjs/toolkit";
import { ourStoryPage } from "../../services/whyDrees/ourStory/ourStoryServices";

const initialState = {
    loading: false,
    ourStoryPageData: {},
    error: "",
};

const ourStorySlice = createSlice({
    name: "ourStory",
    initialState,
    reducers: {
        setPageStartId: (prevState, action) => {
            const state = prevState;
            state.pageStartId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(ourStoryPage.pending, (prevState, action) => {
            const state = prevState;
            state.loading = true;
        });
        builder.addCase(ourStoryPage.fulfilled, (prevState, action) => {
            const state = prevState;
            state.loading = false;
            state.ourStoryPageData = { ...action.payload };
            state.error = "";
        });
        builder.addCase(ourStoryPage.rejected, (prevState, action) => {
            const state = prevState;
            state.loading = false;
            state.ourStoryPageData = {};
            state.error = action.error.message;
        });
    },
});

const { actions, reducer: ourStoryReducer } = ourStorySlice;

export const {
    // export actions here
    setPageStartId,
} = actions;

export default { ourStoryReducer };
