/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from "@reduxjs/toolkit";
import { fetchWhyDreesPage } from "../../services/whyDrees/whyDreesService";

const initialState = {
    loading: false,
    whyDreesPageData: {},
    error: "",
};

const whyDreesSlice = createSlice({
    name: "whydrees",
    initialState,
    reducers: {
        whyDreesContent: (prevState, action) => {
            const state = prevState;
            state.whyDreesContent = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWhyDreesPage.pending, (prevState, action) => {
            const state = prevState;
            state.loading = true;
        });
        builder.addCase(fetchWhyDreesPage.fulfilled, (prevState, action) => {
            const state = prevState;
            state.loading = false;
            state.whyDreesPageData = { ...action.payload };
            state.error = "";
        });
        builder.addCase(fetchWhyDreesPage.rejected, (prevState, action) => {
            const state = prevState;
            state.loading = false;
            state.whyDreesPageData = {};
            state.error = action.error.message;
        });
    },
});

const { actions, reducer: whyDreesReducer } = whyDreesSlice;

export const {
    // export actions here
    whyDreesContent,
} = actions;

export default { whyDreesReducer };
