/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from '@reduxjs/toolkit';
import { fetchAbout } from '../../services/about/aboutService';


const initialState = {
   loading: false,
   data: [],
   error: ""
  };

  const aboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers:{
       // write actions here
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchAbout.pending, (prevState, action) => {
            const state = prevState
            state.loading = true
          })
          builder.addCase(fetchAbout.fulfilled, (prevState, action) => {
            const state = prevState
            state.loading = false
            state.data = action.payload
            state.error = ''
          })
          builder.addCase(fetchAbout.rejected, (prevState, action) => {
            const state = prevState
            state.loading = false
            state.data = []
            state.error = action.error.message
          })

    }
  })

  const { actions, reducer: aboutReducer } = aboutSlice;

  export const {
       // export actions here
       getdata 
  } = actions

  export default { aboutReducer }