/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from "@reduxjs/toolkit";
import { fetchHomeSite, fetchNearByCommunities, fetchSite } from "../../services/home/homeService";

const initialState = {
	loading: false,
	homepageData: {},
	homeSitesBtn: false,
	animation: false,
	nearByCommunities: [],
	error: "",
};

const homeSlice = createSlice({
	name: "home",
	initialState,
	reducers: {
		setPageStartId: (prevState, action) => {
			const state = prevState;
			state.pageStartId = action.payload;
		},
		subContent: (prevState, action) => {
			const state = prevState;
			state.subContent = action.payload;
		},
		homeSitesBtn: (prevState, action) => {
			const state = prevState;
			state.homeSitesBtn = action.payload;
		},
		animation: (prevState, action) => {
			const state = prevState;
			state.animation = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchHomeSite.pending, (prevState, action) => {
			const state = prevState;
			state.loading = true;
		});
		builder.addCase(fetchHomeSite.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.homepageData = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchHomeSite.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.siteData = {};
			state.error = action.error.message;
		});
		builder.addCase(fetchSite.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.site = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchSite.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.site = {};
			state.error = action.error.message;
		});
		builder.addCase(fetchNearByCommunities.fulfilled, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.nearByCommunities = { ...action.payload };
			state.error = "";
		});
		builder.addCase(fetchNearByCommunities.rejected, (prevState, action) => {
			const state = prevState;
			state.loading = false;
			state.nearByCommunities = [];
			state.error = action.error.message;
		});
	},
});

const { actions, reducer: homeReducer } = homeSlice;

export const {
	// export actions here
	setPageStartId,
	subContent,
	homeSitesBtn,
	animation,
} = actions;

export default { homeReducer };
