import env from "react-dotenv";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { defaultConfig } from "@episerver/content-delivery";
import { ContentLoader } from "@episerver/content-delivery";
import { siteId } from "../../constant";
import { subContent } from "../../features/home/homeSlice";

// const { REACT_APP_API_BASE_URL } = process.env;

defaultConfig.apiUrl = env.CONTENT_DELIVERY_API_BASE_URL;
defaultConfig.selectAllProperties = true;
defaultConfig.expandAllProperties = false;

const contentLoader = new ContentLoader();

// fetching main site
export const fetchSite = createAsyncThunk("site/fetchSite", async (community) => {
  const site = await contentLoader.getContent(siteId, { branch: "en" });
  site?.communities?.value?.find((item) => {
    if (item.communityName.toLowerCase().includes(community?.toLowerCase())) {
      localStorage.setItem("kiosk", item.pageReference);
    }
  });

  return site;
});

export const fetchHomeSite = createAsyncThunk("home/fectchHomeSite", async (KioskId, { dispatch }) => {
  const kiosk = await contentLoader.getContent(KioskId, {
    branch: "en",
  });
  const content = await contentLoader.getChildren(KioskId, { branch: "en" });
  dispatch(subContent(content));
  return {
    kiosk,
  };
}); // end

export const fetchNearByCommunities = createAsyncThunk(
  "nearByCommunities/fetchNearByCommunities",
  async (KioskId) => {
    const nearByKiosk = await Promise.all(
      KioskId?.map(async (id) => {
        const kiosk = await contentLoader.getContent(id, {
          branch: "en",
        });
        return kiosk;
      })
    );
    return {
      nearByKiosk,
    };
  }
);
