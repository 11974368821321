import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { assetTrim, damName, youtube_videoId } from "../../../constant";

export const PlanViewVideoTour = ({ details, videoBtnShow, tourBtnShow, Key, setKey }) => {
	const [assetVideo, setAssetVideo] = useState([]);
	const [assetTour, setAssetTour] = useState([]);

	useEffect(() => {
		const video = details?.assets?.filter((item) => (item?.content_type_name || item?.content_type) === "youtube");
		setAssetVideo(video);
		const tour = details?.assets?.filter(
			(item) => (item?.content_type_name || item?.content_type) === "matterport"
		);
		setAssetTour(tour);
	}, [details]);
	return (
		<div>
			<Tabs activeKey={Key} onSelect={(k) => setKey(k)} className="mb-3">
				{videoBtnShow && (
					<Tab eventKey="video" title="Video" style={{ textAlign: "center" }} unmountOnExit>
						<div className="ccw-video-wrapper ">
							<h1 className="ccw-heading">{details?.market_name} Floorplan Video</h1>
							<div className="ccw-video-main-wrapper">
								<iframe
									title="plan video"
									className="ccw-video"
									src={`https://www.youtube.com/embed/${youtube_videoId(assetVideo?.[0]?.asset_value)}?autoplay=1`}
									allow="autoplay"></iframe>
							</div>
						</div>
					</Tab>
				)}
				{tourBtnShow && (
					<Tab eventKey="tour" title="Tour" style={{ textAlign: "center" }} unmountOnExit>
						<div className="ccw-video-wrapper ">
							<h1 className="ccw-heading">{details?.market_name} Tour</h1>
							<div className="ccw-video-main-wrapper">
								<iframe
									title="matterport"
									className="ccw-video"
									src={`${assetTrim(damName, assetTour?.[0]?.asset_value)}&play=1`}></iframe>
							</div>
						</div>
					</Tab>
				)}
			</Tabs>
		</div>
	);
};
