import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../app/component/loader/Loader";
import { fetchHomeSite } from "../../services/home/homeService";
import { testimonialsPage } from "../../services/whyDrees/testimonials/testimonialsServices";
import { fetchWhyDreesPage } from "../../services/whyDrees/whyDreesService";
import { HomeContent } from "./HomeContent";

export const Home = (props) => {
	const { setCustomeClassName } = props;
	const dispatch = useDispatch();
	const kioskId = localStorage.getItem("kiosk");

	const {
		kiosk = {},
		loading,
		error,
		corporate,
		subContent,
		testimonialsPageDataKiosk,
		children
	} = useSelector((state) => {
		return {
			loading: state.homeReducer.loading,
			error: state.homeReducer.error,
			kiosk: state.homeReducer.homepageData.kiosk,
			corporate: state.homeReducer.site,
			subContent: state.homeReducer.subContent,
			testimonialsPageDataKiosk: state.testimonialsReducer.testimonialsPageData.testimonialsPageKiosk,
			children: state.whyDreesReducer.whyDreesPageData.whyDreesChildren,
		};
	});

	useEffect(() => {
		if (corporate && !kiosk) {
			setCustomeClassName("");
			dispatch(fetchHomeSite(kioskId));
		}
	}, [corporate, kiosk]);

	useEffect(() => {
		if (corporate && subContent) {
			dispatch(fetchWhyDreesPage(subContent));
		}
	}, [corporate, subContent]);

	useEffect(() => {
		if (corporate && !testimonialsPageDataKiosk) {
			dispatch(testimonialsPage({ children, corporate }));
		}
	}, [children, corporate, testimonialsPageDataKiosk, dispatch]);

	return (
		<div>
			{loading && <Loader />}
			{error}

			{!error && <HomeContent corporate={corporate} kiosk={kiosk} />}
		</div>
	);
};
