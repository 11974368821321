import { React, useState } from "react";
import LogoWhite from "../../assets/images/logo-white.svg";
import logo from "../../assets/images/logo-white.svg";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postLead } from "../../services/webToLeadService";
import { LeadForm } from "../../features/components/LeadForm";
import { assetTrim, damName, scale } from "../../constant";
import QRCode from "react-qr-code";
import { fetchHomeSite } from "../../services/home/homeService";

export const SendMeThisPlan = ({ setStateToggle, handleSignUpSuccess }) => {
  const formDetails = JSON.parse(localStorage.getItem("formDetails"));
  const kioskId = localStorage.getItem("kiosk");
  const [firstName, setFirstName] = useState(
    formDetails?.kiosk_user?.first_name || ""
  );
  const [lastName, setLastName] = useState(
    formDetails?.kiosk_user?.last_name || ""
  );
  const [email, setEmail] = useState(formDetails?.kiosk_user?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(
    formDetails?.kiosk_user?.phone || ""
  );
  const [zipCode, setZipCode] = useState(
    formDetails?.kiosk_user?.zip_code || ""
  );
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const { kiosk, plansDetails } = useSelector((state) => {
    return {
      kiosk: state.homeReducer.homepageData.kiosk,
      plansDetails: state?.plansReducer?.plansDetails,
    };
  });

  const fields = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    zipCode: zipCode,
    phoneNumber: phoneNumber,
  };

  const handleSignUpChange = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "phoneNumber") {
      setPhoneNumber(e.target.value);
    } else if (e.target.name === "zipCode") {
      setZipCode(e.target.value);
    }
  };

  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    const formData = {
      kiosk_id: kiosk?.name,
      kiosk_user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        zip_code: zipCode,
        phone: phoneNumber,
      },
      lead_type: "plans",
      interests: {
        plans: [
          {
            neighborhood_code: plansDetails?.neighborhood_code,
            plan_code: plansDetails?.plan_code,
          },
        ],
      },
    };
    localStorage.setItem("formDetails", JSON.stringify(formData));
    const resetForm = () => {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setZipCode("");
    };
    dispatch(
      postLead({ formData, resetForm, setStateToggle, handleSignUpSuccess })
    );
  };
  const planData = JSON.stringify({
    market_name: plansDetails?.market_name,
    plan_code: plansDetails?.plan_code,
    neighborhood_code: plansDetails?.neighborhood_code,
  });

  return (
    <>
      <section className="dk-signup-section">
        <div className="dk-signup-logo-wrapper">
          {kiosk?.contentLink?.id?.toString() !== kioskId ? (
            <Link to="/">
              <img
                className="dk-logo-small"
                src={assetTrim(damName, kiosk?.logo?.value, scale) || LogoWhite}
                alt="Drees Homes Logo"
                onClick={() => {
                  dispatch(fetchHomeSite(kioskId));
                  setStateToggle({ sendMeThisPlanBtn: false });
                }}
              />
            </Link>
          ) : location?.pathname === "/" ? (
            <img
              className="dk-logo-small"
              src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
              alt="Drees Homes Logo"
            />
          ) : (
            <Link to="/">
              <img
                className="dk-logo-small"
                src={assetTrim(damName, kiosk?.logo?.value, scale) || logo}
                alt="Drees Homes Logo"
                onClick={() => setStateToggle({ sendMeThisPlanBtn: false })}
              />
            </Link>
          )}
        </div>
        <div className="pb-2 text-end"></div>
        <div className="dk-form-container">
          <div className="text-center mt-10 ">
            <h1 className="white-color dk-signup-heading">
              Send Me {plansDetails?.market_name} Plan
            </h1>
            <p className="white-color dk-signup-desc">
              Like this plan? Send it to yourself for future reference
            </p>
          </div>
          <LeadForm
            handleChange={handleSignUpChange}
            handleSubmit={handleSignUpSubmit}
            fields={fields}
            lead={plansDetails?.type}
          />
        </div>

        <div className="dk-form-container">
          <div className="text-center mt-10 ">
            <h1 className="white-color dk-signup-headingor"> Or </h1>
            <p className="white-color dk-signup-desc-qr">
              Scan the QR code to fill out your information
            </p>
            <div>
              <QRCode
                value={`${window.location.origin}/login?path=plans&kioskData=${kiosk?.contentLink?.id}&data=${planData}`}
                size={200}
                bgColor="black"
                fgColor="white"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
