import React from "react";
import { commaSeperator } from "../../constant";

export const PlanViewTable = ({ details }) => {
	return (
		<div>
			<table className="w-100 text-center vp-house-desc">
				<tbody>
					<tr>
						<td>Sq Ft</td>
						<td>Stories</td>
						<td>Beds</td>
						<td>Baths</td>
						<td>Half Baths</td>
						<td>Garage</td>
					</tr>
					<tr>
						{details?.type === "qmi" ? (
							<td>{commaSeperator(details?.square_feet)}</td>
						) : details?.sqft_low === details?.sqft_high ? (
							<td>{commaSeperator(details?.sqft_low)}</td>
						) : (
							<td>
								{commaSeperator(details?.sqft_low)} - {commaSeperator(details?.sqft_high)}
							</td>
						)}
						{details?.type === "qmi" ? (
							<td>{details?.stories}</td>
						) : details?.stories_min === details?.stories_max ? (
							<td>{details?.stories_min}</td>
						) : (
							<td>
								{details?.stories_min} - {details?.stories_max}
							</td>
						)}
						{details?.type === "qmi" ? (
							<td>{details?.bedrooms}</td>
						) : details?.bedrooms_min === details?.bedrooms_max ? (
							<td>{details?.bedrooms_min}</td>
						) : (
							<td>
								{details?.bedrooms_min} - {details?.bedrooms_max}
							</td>
						)}
						{details?.type === "qmi" ? (
							<td>{details?.full_baths}</td>
						) : details?.full_baths_min === details?.full_baths_max ? (
							<td>{details?.full_baths_min}</td>
						) : (
							<td>
								{details?.full_baths_min} - {details?.full_baths_max}
							</td>
						)}
						{details?.type === "qmi" ? (
							<td>{details?.half_baths}</td>
						) : details?.half_baths_min === details?.half_baths_max ? (
							<td>{details?.half_baths_min}</td>
						) : (
							<td>
								{details?.half_baths_min} - {details?.half_baths_max}
							</td>
						)}
						{details?.type === "qmi" ? (
							<td>{details?.garage}</td>
						) : details?.garage_spaces_min === details?.garage_spaces_max ? (
							<td>{details?.garage_spaces_min}</td>
						) : (
							<td>
								{details?.garage_spaces_min} - {details?.garage_spaces_max}
							</td>
						)}
					</tr>
				</tbody>
			</table>
		</div>
	);
};
