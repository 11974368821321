import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ExplorePage } from "../../constant";
import { fetchExplorePromotionsPage } from "../../services/explore/exploreService";
import { RightNav } from "./RightNav";

function Promotions() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [explorePage, setExplorePage] = useState({});
  const image = location.state?.image || localStorage.getItem("image");

  useEffect(() => {
    const item = location.state?.image;
    if (item) {
      localStorage.setItem("image", item);
    }
  }, []);

  const { subContent, promoLinks, corporate } = useSelector((state) => {
    return {
      subContent: state.homeReducer.subContent,
      promoLinks: state.exploreReducer.explorePageData.promoLinks,
      corporate: state.homeReducer.site,
    };
  });

  useEffect(() => {
    if (subContent && !promoLinks) {
      dispatch(fetchExplorePromotionsPage(subContent));
    }
  }, [subContent, promoLinks]);

  useEffect(() => {
    const explorePage = subContent?.find((item) => {
      return item?.contentType?.includes(ExplorePage);
    });
    setExplorePage(explorePage);
  }, [subContent]);

  return (
    <>
      <div className="dk-MainContentWrapper">
        <section className="dk-promotions h-100 RightMenu dk-promotionpage-wrapper">
            {image === "image1" &&
            promoLinks?.leftImage1?.sales_and_promotion_main_body?.value ? (
              <iframe
                width="100%"
                height="100%"
                srcDoc={
                  promoLinks?.leftImage1?.sales_and_promotion_main_body?.value
                }
              ></iframe>
            ) : image === "image2" &&
              promoLinks?.leftImage2?.sales_and_promotion_main_body?.value ? (
              <iframe
                width="100%"
                height="100%"
                srcDoc={
                  promoLinks?.leftImage2?.sales_and_promotion_main_body?.value
                }
              ></iframe>
            ) : image === "image3" &&
              promoLinks?.rightImage1?.sales_and_promotion_main_body?.value ? (
              <iframe
                width="100%"
                height="100%"
                srcDoc={
                  promoLinks?.rightImage1?.sales_and_promotion_main_body?.value
                }
              ></iframe>
            ) : image === "image4" &&
              promoLinks?.rightImage2?.sales_and_promotion_main_body?.value ? (
              <iframe
                width="100%"
                height="100%"
                srcDoc={
                  promoLinks?.rightImage2?.sales_and_promotion_main_body?.value
                }
              ></iframe>
            ) : image === "image5" &&
              promoLinks?.rightImage3?.sales_and_promotion_main_body?.value ? (
              <iframe
                width="100%"
                height="100%"
                srcDoc={
                  promoLinks?.rightImage3?.sales_and_promotion_main_body?.value
                }
              ></iframe>
            ) : (
              ""
            )}
        </section>
      </div>
      <RightNav />
    </>
  );
}

export default Promotions;
